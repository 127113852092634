import { List } from "immutable";

import { ClassAccessorI } from "@seneca/class-service-types";

import { Action, ActionWithPayload } from "common/types/redux";

import {
  ClassAccessorReceived,
  ClassReceived,
  ClassSkeletonsReceived
} from "../../service/types";
import actionTypes from "../action-types";
import { OptionalClassType } from "../models";
import { ClassId, UserId } from "../types";

export function receiveClass(classEntry: Partial<ClassReceived>) {
  return {
    type: actionTypes.RECEIVE_CLASS,
    payload: classEntry
  };
}

export function receiveClasses(classes: ClassSkeletonsReceived) {
  return {
    type: actionTypes.RECEIVE_CLASS_SKELETONS,
    payload: classes
  };
}

export function receiveTeacherAccessors(teacherAccessors: ClassAccessorI[]) {
  return {
    type: actionTypes.RECEIVE_TEACHER_ACCESSORS,
    payload: teacherAccessors
  };
}

export type UpdateClassDetailsPayload =
  | OptionalClassType
  | Partial<ClassReceived>
  | ClassAccessorReceived;

export function updateClass(
  updates: UpdateClassDetailsPayload
): ActionWithPayload<UpdateClassDetailsPayload> {
  return {
    type: actionTypes.UPDATE_CLASS_DETAILS,
    payload: updates
  };
}

export type DeleteUsersFromClassPayload = {
  classId: ClassId;
  users: UserId[];
};
export function deleteUsersFromClass(
  classId: ClassId,
  users: UserId[]
): Action<DeleteUsersFromClassPayload> {
  return {
    type: actionTypes.DELETE_USERS_FROM_CLASS,
    payload: {
      classId,
      users
    }
  };
}

export function deleteClass(classId: string) {
  return {
    type: actionTypes.DELETE_CLASS,
    payload: {
      classId
    }
  };
}

export function leaveClass(classId: string) {
  return {
    type: actionTypes.LEAVE_CLASS,
    payload: {
      classId
    }
  };
}

export function toggleClass(classId: string) {
  return {
    type: actionTypes.TOGGLE_CLASS_SELECTED,
    payload: classId
  };
}

export function toggleAllClasses(classIds: List<string>) {
  return {
    type: actionTypes.TOGGLE_ALL_CLASSES,
    payload: classIds
  };
}

export function clearAll() {
  return {
    type: actionTypes.CLEAR_ALL
  };
}

export function selectAllClasses(classIds: List<string>) {
  return {
    type: actionTypes.SELECT_MANY,
    payload: classIds
  };
}
