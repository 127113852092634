import actionTypes from "../action-types";
import { UserType } from "../models/User";

export function receiveUser(user: UserType) {
  return {
    type: actionTypes.RECEIVE_USER,
    payload: user
  };
}

export function receiveUsers(users: UserType[]) {
  return {
    type: actionTypes.RECEIVE_USERS,
    payload: users
  };
}
