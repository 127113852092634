import { makeFetchStateReducer } from "seneca-common/utils/apiStates";

import {
  ACTIONS_NAMESPACE,
  ENTITY_NAME,
  META_IDS_FIELD_NAME
} from "../../../consts";

const metaReducer = makeFetchStateReducer({
  name: ENTITY_NAME,
  path: ACTIONS_NAMESPACE,
  idFieldName: META_IDS_FIELD_NAME
});

export default metaReducer;
