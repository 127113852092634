// These sources can also be passed to links to what is premium page
export const SUBSCRIPTION_JOURNEY_SOURCES = Object.freeze({
  UNSET: "UNSET",
  SESSION: "SESSION",
  END_SESSION_MODAL: "END_SESSION_MODAL",
  SECTION_HOMEPAGE: "SECTION_HOMEPAGE",
  DOWNLOAD_NOTES: "DOWNLOAD_NOTES",
  WRONG_ANSWERS_TRIAL: "WRONG_ANSWERS_TRIAL",
  SMART_LEARNING_RECOMMENDED: "SMART_LEARNING_RECOMMENDED",
  WHAT_IS_PREMIUM_PARENT: "WHAT_IS_PREMIUM_PARENT",
  WHAT_IS_PREMIUM_TEACHER: "WHAT_IS_PREMIUM_TEACHER",
  WHAT_IS_PREMIUM_STUDENT: "WHAT_IS_PREMIUM_STUDENT",
  CLASSROOM: "CLASSROOM",
  CLASSROOM_RECOMMENDED_SECTION: "CLASSROOM_RECOMMENDED_SECTION",
  SETTINGS: "SETTINGS",
  DASHBOARD: "DASHBOARD",
  PREMIUM_AD: "PREMIUM_AD",
  ADD_COURSE: "ADD_COURSE",
  SECTION_HOMEPAGE_ADD_COURSE: "SECTION_HOMEPAGE_ADD_COURSE",
  COURSE_HOMEPAGE_ADD_COURSE: "COURSE_HOMEPAGE_ADD_COURSE",
  SECTION_HOMEPAGE_AMELIA: "SECTION_HOMEPAGE_AMELIA",
  MULTI_SECTION_RESULTS_WRONG_ANSWERS: "MULTI_SECTION_RESULTS_WRONG_ANSWERS",
  COURSE_ASK_AMELIA_PAGE: "COURSE_ASK_AMELIA_PAGE",
  ANONYMOUS_REDIRECT: "ANONYMOUS_REDIRECT",
  SETTINGS_RENEW_PREMIUM: "SETTINGS_RENEW_PREMIUM",
  RETRY_PAYMENT: "RETRY_PAYMENT",
  DASHBOARD_NAVBAR: "DASHBOARD_NAVBAR",
  PARENT_ATTENTION_GRABBER: "PARENT_ATTENTION_GRABBER",
  PARENT_NAVBAR: "PARENT_NAVBAR",
  PARENT_ONBOARDING_GET_TUTORING_CTA: "PARENT_ONBOARDING_GET_TUTORING_CTA",
  PARENT_UPGRADE_BUTTON: "PARENT_UPGRADE_BUTTON",
  PARENT_MONITORING_TABLE: "PARENT_MONITORING_TABLE",
  PARENT_MONTHLY_REPORT_GATING: "PARENT_MONTHLY_REPORT_GATING",
  DASHBOARD_AD: "DASHBOARD_AD",
  REDIRECT_FROM_PREMIUM_JOURNEY: "REDIRECT_FROM_PREMIUM_JOURNEY",
  QUESTIONS_ONLY: "QUESTIONS_ONLY",
  EXAM_QUESTIONS: "EXAM_QUESTIONS",
  CHILD_CONNECTION_INVITE: "CHILD_CONNECTION_INVITE",
  SUBSCRIPTION_EXPIRED: "SUBSCRIPTION_EXPIRED",
  MINI_MOCK_GATING_CREATE_AND_GATE: "MINI_MOCK_GATING_CREATE_AND_GATE",
  EXPLAIN_ANSWER: "EXPLAIN_ANSWER",
  CLASSROOM_NAVBAR: "CLASSROOM_NAVBAR",
  PAST_PAPER_REVIEW_GATING: "PAST_PAPER_REVIEW_GATING",
  PLAY_SPEECH: "PLAY_SPEECH",
  HARDEST_QUESTIONS_GATING: "HARDEST_QUESTIONS_GATING",
  TEACHER_NAVBAR: "TEACHER_NAVBAR",
  TEACHER_NEWS_AND_CPD: "TEACHER_NEWS_AND_CPD",
  TEACHER_EQ_ONLY_ASSIGNMENT: "TEACHER_EQ_ONLY_ASSIGNMENT",
  TEACHER_HELP_CENTRE: "TEACHER_HELP_CENTRE"
});

export const SUBSCRIPTION_JOURNEY_PAGE_NAMES = Object.freeze({
  WHAT_IS_PREMIUM_PAGE: "WHAT_IS_PREMIUM_PAGE",
  PRODUCTS_OVERVIEW: "PRODUCTS_OVERVIEW",
  ADD_GUARANTEE: "ADD_GUARANTEE",
  PAYMENT_DETAILS: "PAYMENT_DETAILS",
  RETRY_PAYMENT: "RETRY_PAYMENT",
  STRONG_AUTHENTICATION: "STRONG_AUTHENTICATION",
  SUBSCRIPTION_EXPIRED: "SUBSCRIPTION_EXPIRED",
  CREATE_SUBSCRIPTION_SUCCESS: "CREATE_SUBSCRIPTION_SUCCESS",
  USER_DETAILS: "USER_DETAILS",
  ANON_CREATE_SUBSCRIPTION_SUCCESS: "ANON_CREATE_SUBSCRIPTION_SUCCESS"
});

export type PaymentMethod = "card" | "applePay" | "googlePay";
