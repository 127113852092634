import { defineMessages, makeMessages } from "features/localisation";

const messages = makeMessages({
  myFirstClassName: {
    id: "teachers.classes.myFirstClassName",
    defaultMessage: "My first class"
  }
});

export { messages };
export default defineMessages(messages);
