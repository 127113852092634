import { IosSubscriptionFlow } from "../../webview-ios/types";

export function linkToUpgrade(baseLink: string) {
  return `${baseLink}/upgrade`;
}

export function linkToPreviewUpgrades(
  baseLink: string,
  subscriptionId: string = ":subscriptionId"
) {
  return `${linkToUpgrade(baseLink)}/preview/${subscriptionId}`;
}

export function linkToUpdateCardDetails(
  baseLink: string,
  subscriptionId: string = ":subscriptionId"
) {
  return `${linkToUpgrade(baseLink)}/card-details/${subscriptionId}`;
}

export function linkToAuthentication(baseLink: string) {
  return `${linkToUpgrade(baseLink)}/authentication`;
}

export function linkToRetryPayment(
  baseLink: string,
  subscriptionId: string = ":subscriptionId"
) {
  return `${linkToUpgrade(baseLink)}/retry-payment/${subscriptionId}`;
}

export function linkToUpgradeSuccess({
  baseLink,
  iosSubscriptionFlow
}: {
  baseLink: string;
  iosSubscriptionFlow?: IosSubscriptionFlow | null;
}) {
  const link = `${linkToUpgrade(baseLink)}/success`;

  if (iosSubscriptionFlow) {
    return `${link}?iosSubscriptionFlow=${iosSubscriptionFlow}`;
  }

  return link;
}

export function linkToUpgradeUnavailable(baseLink: string) {
  return `${linkToUpgrade(baseLink)}/unavailable`;
}

export function linkToUpgradeSubscriptionInfoiOS(baseLink: string) {
  return `${linkToUpgrade(baseLink)}/upgrade-subscription-info-ios`;
}
