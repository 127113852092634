import {
  PATH_TO_USERS_STATE,
  usersReducer
} from "seneca-common/features/users/state";
import ReducerRegistry from "seneca-common/utils/state/reducer-registry";

import "../analytics";

import actionTypes from "./action-types";
import registerDataLayerSelectors from "./analytics/register-data-layer-selectors";
import { PATH_TO_CLASS_STATE } from "./consts";
import classReducer from "./reducers";
import usersReducerWrapper from "./reducers/usersReducerWrapper";

const reducerRegistry = ReducerRegistry.getInstance();
reducerRegistry.initialise(PATH_TO_CLASS_STATE, builder => {
  builder
    .register(PATH_TO_CLASS_STATE, classReducer)
    // @ts-ignore
    .enhance(PATH_TO_USERS_STATE, usersReducer, usersReducerWrapper);
});

registerDataLayerSelectors();

// selectors
export {
  getClass,
  getClassName,
  getIsClassHidden,
  getClassCourseIds,
  getNumberOfCourses,
  hasNoCourses,
  hasNoStudents,
  getClassEnrolleesUserIds,
  getNumberOfStudentsInClass,
  getNumberOfStudentsWithParentsInClass,
  getStudentNumberOfParents,
  getClassNumberOfActiveTeachers,
  getClassNumberOfPendingTeachers,
  getClassNumberOfTeachers,
  getClassSortedTeacherUserIds,
  getClassTeacherUserIds,
  getClassTeacher,
  getClassMyTeacherAccessors,
  getTeacherRole,
  getTeacherIsClassOwner,
  getTeacherIsActive,
  getTeacherIsPending,
  getTeacherIsManaged,
  getIsClassTeacher,
  getAllClassIds,
  getAllClassIdsSortedAlphabeticallyByName,
  getActiveClassIdsSortedAlphabeticallyByName,
  getAllClassesSortedAlphabeticallyByName,
  getAllClassesSortedAlphabeticallyByNameAndIsManaged,
  getAllClassesSortedByCreationDate,
  getAllClassesSortedByLastViewed,
  hasNoClasses,
  getPrimaryCourseId,
  getAllClassesDetails,
  doesClassExist,
  getNumberOfClasses,
  isFirstClass,
  makeGetActiveClassIdsContainingCourse,
  getClassManagedBy,
  getClassParentInformationAvailable,
  isClassManaged,
  makeSortClassIdsAlphabeticallyByName,
  makeGetClassNamesForClassIds,
  getIsClassArchived,
  getArchivedClasses,
  getActiveClasses,
  getSelectedClassIds,
  getNumberOfSelectedClassIds,
  allClassesAreSelected,
  getUserIsClassOwner,
  classIdIsSelected,
  getStudentParentConnections,
  getIsAnyParentsPending,
  getNumberOfStudentsWithUnconnectedParentsInClass,
  getNumberOfStudentsWithUnknownParentsInClass,
  getSchoolSyncExpiryDate,
  getSchoolAccessRevoked,
  getYearGroup,
  makeGetActiveSimilarFilteredClassIds,
  getCourseIdsForClasses,
  getClassesHaveNoCourses,
  getYearGroupsForClasses
} from "./selectors";
export {
  /** Classes **/
  // Fetch all
  allClassSkeletonsAreFetching,
  allClassSkeletonsFetchSucceeded,
  allClassSkeletonsFetchErrored,
  allClassSkeletonsNeedFetching,
  allClassSkeletonsFetchFinished, // Fetch
  classIsFetching,
  classFetchSucceeded,
  classFetchErrored,
  classFinishedFetching,
  classNeedsFetching, // Update
  classIsUpdating,
  classUpdateSucceeded,
  classUpdateErrored, // Delete
  classIsBeingDeleted,
  classDeleteSucceeded,
  classDeleteErrored, // Leave
  classIsBeingLeft,
  classLeaveSucceeded,
  classLeaveErrored,
  /** Manage users **/
  // Delete
  deleteUsersIsPending,
  deleteUsersHasSucceeded,
  deleteUsersHasErrored
} from "./selectors/meta";

// actions
export {
  receiveClass,
  receiveClasses,
  updateClass,
  deleteUsersFromClass,
  deleteClass,
  leaveClass,
  toggleClass,
  toggleAllClasses,
  clearAll
} from "./actions";

// action-types
export const { RECEIVE_CLASS, RECEIVE_CLASS_SKELETONS } = actionTypes;

export {
  fetchAllClassSkeletonsActionFactory,
  fetchClassActionFactory,
  updateClassActionFactory,
  deleteClassActionFactory,
  deleteUsersFromClassActionFactory,
  leaveClassActionFactory
} from "./actions/meta";

// types
export type { ClassId, UserId } from "./types";
export type { Classes } from "./models/classes";
export type { ClassType } from "./models/classes/class";
export type {
  AccessorRoleType,
  AccessorStatusType,
  ClassAccessorType
} from "./models/classes/class/accessor";
export { AccessorRole, AccessorStatus } from "./models/classes/class/accessor";
