import { List, Record } from "immutable";

export type ManageClassesType = {
  selectedClassIds: List<string>;
};

const defaultManageClasses: ManageClassesType = {
  selectedClassIds: List()
};

export default class ManageClasses extends Record(defaultManageClasses) {}
