import { Record } from "immutable";

import {
  defaultStat,
  StatType
} from "seneca-common/features/stats-review/state";

export type UserCourseStatFields = {
  id: string | null;
  userId: string | null;
  courseId: string | null;
};

export type UserCourseStatType = UserCourseStatFields & StatType;
export type OptionalUserCourseStatType = Partial<UserCourseStatType>;

export const defaultUserCourseStat: UserCourseStatType = {
  ...defaultStat,
  id: null,
  userId: null,
  courseId: null
};

export default class UserCourseStat extends Record(defaultUserCourseStat) {}
