import classnames from "classnames";
import { useEffect, useState } from "react";

import "./TextPlaceholder.scss";

export type Props = {
  children?: React.ReactNode;
  loading?: boolean;
  placeholderWidth?: string;
  placeholderHeight?: string;
  className?: string;
  isIcon?: boolean;
  loadingClassName?: string;
  loadingStateDelay?: number;
  displayInlineBlock?: boolean;
  shouldCenter?: boolean;
  marginY?: string;
};

const DEFAULT_LOADING_STATE_DELAY = 350;

function TextPlaceholder({
  loadingStateDelay = DEFAULT_LOADING_STATE_DELAY,
  ...props
}: Props) {
  const [showLoadingState, setShowLoadingState] = useState(
    loadingStateDelay === 0
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (!showLoadingState) {
      timeout = setTimeout(() => setShowLoadingState(true), loadingStateDelay);
    }

    return () => clearTimeout(timeout);
  }, [showLoadingState, loadingStateDelay]);

  const {
    children,
    loading,
    placeholderWidth,
    placeholderHeight,
    className,
    loadingClassName,
    displayInlineBlock,
    isIcon,
    shouldCenter,
    marginY
  } = props;

  return loading ? (
    <span
      style={{
        width: placeholderWidth,
        height: placeholderHeight,
        marginTop: marginY,
        marginBottom: marginY
      }}
      className={classnames("TextPlaceholder", className, {
        "TextPlaceholder--icon": isIcon,
        "TextPlaceholder--loading": showLoadingState,
        [loadingClassName || ""]: showLoadingState,
        "TextPlaceholder--inline-block": displayInlineBlock,
        "TextPlaceholder--shouldCenter": shouldCenter
      })}
    />
  ) : (
    <>{children}</>
  );
}

export default TextPlaceholder;
