import actionTypes from "../../../action-types/pwa";
import PWAStateSlice from "../../../models/state/pwa/PWAStateSlice";

export default function pwaReducer(
  state: PWAStateSlice = new PWAStateSlice(),
  action: Record<string, any>
) {
  switch (action.type) {
    case actionTypes.NEW_VERSION_IS_AVAILABLE: {
      const { canShowWarning, shouldForceUpdate } = state;
      return state.merge({
        newVersionAvailable: true,
        canShowPrompt: !canShowWarning && !shouldForceUpdate
      });
    }

    case actionTypes.APP_VERSION_IS_UPDATING: {
      return state.merge({
        updating: true,
        lastSeenPrompt: action.payload.time
      });
    }

    case actionTypes.SET_LAST_SEEN_PROMPT: {
      return state.merge({
        lastSeenPrompt: action.payload,
        lastSeenReady: true
      });
    }

    case actionTypes.SHOW_PROMPT: {
      return state.merge({
        promptDismissed: false,
        showingPrompt: true
      });
    }

    case actionTypes.SHOW_WARNING: {
      return state.merge({
        promptDismissed: false,
        showingWarning: true
      });
    }

    case actionTypes.DISMISS_PROMPTS: {
      const { time } = action.payload;
      return state.merge({
        promptDismissed: true,
        warningDismissed: true,
        showingPrompt: false,
        showingWarning: false,
        lastSeenPrompt: time
      });
    }

    case actionTypes.SET_UPDATE_PROMPT_VISIBILITY: {
      const promptHidden = action.payload;
      const { canShowPrompt, promptDismissed } = state;

      let showPrompt;

      if (promptHidden) {
        showPrompt = false;
      } else {
        showPrompt = canShowPrompt && !promptDismissed;
      }

      return state.merge({
        promptHidden: promptHidden,
        showingPrompt: showPrompt
      });
    }

    case actionTypes.NEW_PWA_CONFIG_RECEIVED: {
      const { showWarning, forceUpdate, toastVisibilityTimeoutMS } =
        action.payload;

      const toUpdate: Record<string, any> = {
        configReady: true,
        promptDismissed: false,
        warningDismissed: false
      };
      if (toastVisibilityTimeoutMS >= 0)
        toUpdate.toastVisibilityTimeoutMS = toastVisibilityTimeoutMS;

      if (forceUpdate) {
        return state.merge({
          ...toUpdate,
          canShowPrompt: false,
          canShowWarning: false,
          shouldForceUpdate: true
        });
      } else if (showWarning) {
        return state.merge({
          ...toUpdate,
          canShowPrompt: false,
          canShowWarning: true,
          shouldForceUpdate: false
        });
      } else {
        return state.merge({
          ...toUpdate,
          canShowWarning: false,
          shouldForceUpdate: false
        });
      }
    }

    default:
      return state;
  }
}
