import classnames from "classnames";
import { MouseEventHandler, ReactNode } from "react";

import { IconButton } from "@seneca/senekit";

import styles from "./Topbar.module.scss";

type TopbarTone = "default" | "warning" | "error";
type Props = {
  children: ReactNode;
  tone?: TopbarTone;
  onClose?: MouseEventHandler<Element>;
};

export default function Topbar({ children, tone, onClose }: Props) {
  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.warningBackground]: tone === "warning",
        [styles.errorBackground]: tone === "error"
      })}
    >
      {children}
      {!!onClose && (
        <div className={styles.closeButton}>
          <IconButton
            iconName="cross"
            onClick={onClose}
            accessibilityLabel="Close topbar"
            blend
            size="medium"
          />
        </div>
      )}
    </div>
  );
}
