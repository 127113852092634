import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Box, Link, useTheme } from "@seneca/senekit";

import { addValueToLinkState } from "seneca-common/utils/routing";

import { HouseEmoji } from "features/common/emoji";
import { linkToParentDashboard } from "features/parents/links";
import { isSchoolParent } from "features/user/selectors";

import Topbar from "../Topbar";
import styles from "./ParentTopbar.module.scss";

type Props = {
  backToParentPlatformLink?: string;
};

export default function ParentTopbar({ backToParentPlatformLink }: Props) {
  const display = useSelector(isSchoolParent);
  const theme = useTheme();
  const location = useLocation();

  if (!display) {
    return null;
  }

  const parentPlatformLink = backToParentPlatformLink
    ? backToParentPlatformLink
    : linkToParentDashboard();

  const link = addValueToLinkState(parentPlatformLink, {
    from: location.pathname
  });

  return (
    <Topbar>
      <HouseEmoji className={styles.emoji} />
      <Link to={link}>
        <Box color={theme.white}>Back to parent platform</Box>
      </Link>
    </Topbar>
  );
}
