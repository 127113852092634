export default function getDecimalAsPercentage(
  decimal: number,
  dps: number = 0
): string {
  const dpsCorrection = Math.pow(10, dps);
  const valueToRound = decimal * 100 * dpsCorrection;
  const roundedValue = Math.round(valueToRound);
  const percentage = roundedValue / dpsCorrection;
  return `${percentage}%`;
}
