import { List } from "immutable";

import { CHART_TYPES, ChartOptions } from "../models/ChartOptions";
import TimestampPair from "../models/TimestampPair";

export function getUniformTimestamps({
  startTime,
  endTime,
  type,
  resolution
}: ChartOptions): List<TimestampPair> {
  if (resolution <= 0) {
    throw new TypeError("ChartOption: resolution must be a positive integer");
  }

  let timestamp = endTime;

  return List<TimestampPair>().withMutations(timestamps => {
    while (timestamp >= startTime) {
      let startTimestamp: number | null = null;

      if (type === CHART_TYPES.interval) {
        startTimestamp = timestamp - resolution;
      }

      timestamps.unshift(
        new TimestampPair({
          startTime: startTimestamp,
          endTime: timestamp
        })
      );

      timestamp -= resolution;
    }

    return timestamps;
  });
}
