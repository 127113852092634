import {
  AllowedCallTypes,
  makeCrudStateActionFactories
} from "seneca-common/utils/apiStates";

import { ACTIONS_NAMESPACE, ENTITY_NAME, META_IDS_FIELD_NAME } from "../consts";

export const { fetchActionFactory } = makeCrudStateActionFactories({
  name: ENTITY_NAME,
  path: ACTIONS_NAMESPACE,
  idFieldName: META_IDS_FIELD_NAME,
  callTypes: [AllowedCallTypes.FETCH]
});
