import Immutable, { List } from "immutable";
import { createSelector } from "reselect";

import { ChildParentConnectionI } from "@seneca/parents-service-types";
import { getCanSendInvite } from "@seneca/parents-service-utils";

import { composeSelector } from "seneca-common/utils/selectors/compose-selectors";

import { ConnectionKey } from "features/teachers/pages/class-students/components/ClassStudentsPage/components/StudentPageHeader/hooks/useSendClassParentInvites";

import { PATH_TO_CLASS_STATE } from "../consts";
import ClassRecord, { ClassType } from "../models/classes/class";
import ClassAccessor, {
  AccessorRoleType
} from "../models/classes/class/accessor";
import ClassStateSlice from "../models/ClassStateSlice";
import ManageClasses from "../models/manageClasses";
import {
  allClassesAreSelected as getAllClassesAreSelected,
  getClassesSlice,
  getManageClassesSlice
} from "./state";
import * as classes from "./state/classes";
import * as classDetails from "./state/classes/class";
import * as manageClasses from "./state/manageClasses";

export function getClassSliceFromStore(state: any): ClassStateSlice {
  return state.get(PATH_TO_CLASS_STATE);
}
type State = any;

function composedGetClassesSlice(state: State) {
  return getClassesSlice(getClassSliceFromStore(state));
}

const composedGetManageClassesSlice: (state: State) => ManageClasses =
  // @ts-ignore
  composeSelector(getClassSliceFromStore, getManageClassesSlice);

export function getClass(state: State, classId: string) {
  return classes.getClassDetails(composedGetClassesSlice(state), classId);
}

export const getAllClassesDetails = composedGetClassesSlice;

export function doesClassExist(state: State, classId: string): boolean {
  return classes.doesClassExist(composedGetClassesSlice(state), classId);
}

export const getClassName: (
  state: State,
  classId: string
) => string | null | undefined = composeSelector(
  // @ts-ignore
  getClass,
  classDetails.getName
);

export const getIsClassHidden: (state: State, classId: string) => boolean =
  composeSelector(
    // @ts-ignore
    getClass,
    classDetails.isClassHidden
  );

export const getClassEnrollments: (
  state: State,
  classId: string
) => Immutable.Map<string, ClassAccessor> | null | undefined = composeSelector(
  // @ts-ignore
  getClass,
  classDetails.getEnrollments
);

export const getClassMyTeacherAccessors: (
  state: State,
  classId: string
) => Immutable.List<ClassAccessor> =
  // @ts-ignore
  composeSelector(getClass, classDetails.getMyTeacherAccessors);

export const getClassManagedBy: (
  state: State,
  classId: string
) => string | null | undefined = composeSelector(
  // @ts-ignore
  getClass,
  classDetails.getClassManagedBy
);

export const getClassParentInformationAvailable: (
  state: State,
  classId: string
) => boolean | null | undefined = composeSelector(
  // @ts-ignore
  getClass,
  classDetails.getClassParentInformationAvailable
);

export function isClassManaged(state: State, classId: string): boolean {
  return !!getClassManagedBy(state, classId);
}

export const getIsClassArchived: (state: State, classId: string) => boolean =
  // @ts-ignore
  composeSelector(getClass, classDetails.getIsClassArchived);

// @ts-ignore
export const getNumberOfStudentsInClass: (
  arg0: State,
  classId: string
) => number = composeSelector(
  // @ts-ignore
  getClass,
  classDetails.getNumberOfStudents
);

// @ts-ignore
export const getNumberOfStudentsWithParentsInClass: (
  arg0: State,
  classId: string
) => number = composeSelector(
  // @ts-ignore
  getClass,
  classDetails.getNumberOfStudentsWithParents
);

// @ts-ignore
export const getNumberOfStudentsWithUnknownParentsInClass: (
  arg0: State,
  classId: string
) => number = composeSelector(
  // @ts-ignore
  getClass,
  classDetails.getNumberOfStudentsWithUnknownParents
);

export const getNumberOfStudentsWithUnconnectedParentsInClass: (
  arg0: State,
  classId: string
) => number = composeSelector(
  // @ts-ignore
  getClass,
  classDetails.getNumberOfStudentsWithUnconnectedParents
);

export function getStudentNumberOfParents(
  state: State,
  classId: string,
  userId: string
): number | null | undefined {
  const classDetailsState = getClass(state, classId);
  return classDetails.getStudentNumberOfParents(classDetailsState!, userId);
}

export const hasNoStudents = (state: State, classId: string) =>
  getNumberOfStudentsInClass(state, classId) === 0;

export const getClassEnrolleesUserIds: (
  arg0: State,
  classId: string
) => List<string> = composeSelector(
  // @ts-ignore
  getClass,
  classDetails.getEnrolleesUserIds
);

export function getClassParentConnections(
  state: State,
  classId: string
): ChildParentConnectionI[] {
  const classDetailsState = getClass(state, classId);

  const connections =
    classDetailsState?.enrollments.reduce(
      (parents: ChildParentConnectionI[], accessor) => {
        if (accessor.userId && accessor.parentConnections?.size) {
          parents.push(...accessor.parentConnections);
        }
        return parents;
      },
      []
    ) ?? [];

  return connections;
}

export function getInvitableClassParentConnectionKeys(
  state: State,
  classId: string
): ConnectionKey[] {
  const connections = getClassParentConnections(state, classId);

  return connections
    .filter(connection =>
      getCanSendInvite({
        type: "connect",
        invites: connection.invites
      })
    )
    .map(({ userId, parentUserId }) => ({
      childUserId: userId,
      parentUserId
    }));
}

export function getStudentParentConnections(
  state: State,
  classId: string,
  userId: string
): List<ChildParentConnectionI> {
  const classDetailsState = getClass(state, classId);
  return (
    classDetailsState?.enrollments.find(accessor => accessor.userId === userId)
      ?.parentConnections || List()
  );
}

export function getIsAnyParentsPending(state: State, classId: string): any {
  const classDetailsState = getClass(state, classId);
  return classDetailsState?.enrollments.some(
    accessor => accessor.parentConnections?.first()?.status === "pending"
  );
}

export function getValidPendingConnectionKeysForParentInvites(
  state: State,
  classId: string,
  userIds: string[]
) {
  const classDetailsState = getClass(state, classId);

  const connectionKeys = classDetailsState?.enrollments.reduce(
    (keys: ConnectionKey[], accessor) => {
      if (!userIds.some(userId => userId === accessor.userId)) return keys;

      const connectionsKeysToInvite = accessor.parentConnections
        ?.filter(
          connection =>
            userIds.includes(connection.userId) &&
            getCanSendInvite({
              type: "connect",
              invites: connection.invites
            })
        )
        .map(({ userId, parentUserId }) => ({
          childUserId: userId,
          parentUserId
        }));

      if (connectionsKeysToInvite) {
        keys.push(...connectionsKeysToInvite);
      }

      return keys;
    },
    []
  );

  return connectionKeys;
}

export function getExistsParentsToInviteForClass(
  state: State,
  classId: string
) {
  const klass = getClass(state, classId);

  const existsParentsToInviteForClass = !!klass?.enrollments.some(accessor => {
    const { parentConnections } = accessor;
    if (!parentConnections?.size) return false;

    return parentConnections.some(connection =>
      getCanSendInvite({
        type: "connect",
        invites: connection.invites
      })
    );
  });

  return existsParentsToInviteForClass;
}

// @ts-ignore
export const getClassSortedTeacherUserIds: (
  arg0: State,
  classId: string
) => List<string> = composeSelector(
  // @ts-ignore
  getClass,
  classDetails.getSortedTeacherUserIds
);

export const getClassTeacherUserIds: (
  arg0: State,
  classId: string
) => List<string> = composeSelector(
  // @ts-ignore
  getClass,
  classDetails.getTeacherUserIds
);

export const getClassNumberOfActiveTeachers: (
  arg0: State,
  classId: string
) => number = composeSelector(
  // @ts-ignore
  getClass,
  classDetails.getNumberOfActiveTeachers
);

export const getClassNumberOfPendingTeachers: (
  arg0: State,
  classId: string
) => number = composeSelector(
  // @ts-ignore
  getClass,
  classDetails.getNumberOfPendingTeachers
);

// @ts-ignore
export const getClassNumberOfTeachers: (
  arg0: State,
  classId: string
) => number = composeSelector(
  // @ts-ignore
  getClass,
  classDetails.getNumberOfTeachers
);

export const getSchoolSyncExpiryDate: (
  arg0: State,
  classId: string
) => string | undefined = composeSelector(
  // @ts-ignore
  getClass,
  classDetails.getSchoolSyncExpiryDate
);

export const getSchoolAccessRevoked: (
  arg0: State,
  classId: string
) => boolean | undefined = composeSelector(
  // @ts-ignore
  getClass,
  classDetails.getSchoolAccessRevoked
);

export const getTimeLastSynced: (
  arg0: State,
  classId: string
) => string | undefined = composeSelector(
  // @ts-ignore
  getClass,
  classDetails.getTimeLastSynced
);

export const getYearGroup: (
  state: State,
  classId: string
) => number | undefined = composeSelector(getClass, classDetails.getYearGroup);

export function getClassTeacher(
  state: State,
  classId: string,
  userId: string
): ClassAccessor | null | undefined {
  const classDetailsState = getClass(state, classId);
  // @ts-ignore
  return classDetails.getTeacher(classDetailsState, userId);
}

export function getTeacherIsClassOwner(
  state: State,
  classId: string,
  userId: string
): boolean {
  const classDetailsState = getClass(state, classId);
  return classDetails.getTeacherIsClassOwner(classDetailsState!, userId);
}

export function getTeacherRole(
  state: State,
  classId: string,
  userId: string
): AccessorRoleType | null | undefined {
  const classDetailsState = getClass(state, classId);
  return classDetails.getTeacherRole(classDetailsState!, userId);
}

export function getTeacherIsActive(
  state: State,
  classId: string,
  userId: string
): boolean {
  const classDetailsState = getClass(state, classId);
  return classDetails.getTeacherIsActive(classDetailsState!, userId);
}

export function getTeacherIsPending(
  state: State,
  classId: string,
  userId: string
): boolean {
  const classDetailsState = getClass(state, classId);
  return classDetails.getTeacherIsPending(classDetailsState!, userId);
}

export function getTeacherIsManaged(
  state: State,
  classId: string,
  userId: string
): boolean {
  const classDetailsState = getClass(state, classId);
  return classDetails.getTeacherIsManaged(classDetailsState!, userId);
}

export function getIsClassTeacher(
  state: State,
  classId: string,
  userId: string
): boolean {
  const classDetailsState = getClass(state, classId);
  return classDetails.getIsTeacher(classDetailsState!, userId);
}

export const getClassCourseIds: (arg0: State, classId: string) => List<string> =
  // @ts-ignore
  composeSelector(getClass, classDetails.getCourseIds);

export const getUserIsClassOwner: (arg0: State, classId: string) => boolean =
  // @ts-ignore
  createSelector(getClass, classDetails.getUserIsClassOwner);

export const getNumberOfCourses = (state: State, classId: string) =>
  getClassCourseIds(state, classId).size;

export const hasNoCourses = (state: State, classId: string) =>
  getNumberOfCourses(state, classId) === 0;

export const getPrimaryCourseId = (
  state: State,
  classId: string
): string | null | undefined => {
  const courseIds = getClassCourseIds(state, classId);
  return courseIds ? courseIds.get(0) : null;
};

export const getCourseIdsForClasses = (
  state: State,
  classIds: string[]
): List<string> => {
  const classesCourseIds = getAllClassesSortedAlphabeticallyByName(state)
    .filter(klass => classIds.includes(klass.id!))
    .flatMap(classDetails.getCourseIds);

  return List(new Set(classesCourseIds));
};

export const getClassesHaveNoCourses = (state: State, classIds: string[]) => {
  return getCourseIdsForClasses(state, classIds).size === 0;
};

export const getYearGroupsForClasses = (
  state: State,
  classIds: string[]
): List<number> => {
  const classesYearGroups = getAllClassesSortedAlphabeticallyByName(state)
    .filter(klass => classIds.includes(klass.id!) && klass.yearGroup)
    .map(klass => klass.yearGroup!);

  return List(new Set(classesYearGroups));
};

// @ts-ignore
export const getAllClassIds: (arg0: State) => List<string> = createSelector(
  composedGetClassesSlice,
  classes.getAllClassIds
);

export const getAllClassesSortedAlphabeticallyByName: (
  arg0: State
) => List<ClassType> = createSelector(
  composedGetClassesSlice,
  classes.getAllClassesSortedAlphabeticallyByName
);

export const getAllClassesSortedByCreationDate: (
  arg0: State
) => List<ClassType> = createSelector(
  composedGetClassesSlice,
  classes.getAllClassesSortedByCreationDate
);

export const getAllClassesSortedByLastViewed: (
  state: State
) => List<ClassType> = createSelector(
  composedGetClassesSlice,
  classes.getAllClassesSortedByLastViewed
);

export const getAllClassesSortedAlphabeticallyByNameAndIsManaged: (
  arg0: State
) => List<ClassType> = createSelector(
  composedGetClassesSlice,
  classes.getAllClassesSortedAlphabeticallyByNameAndIsManaged
);

// @ts-ignore
export const getAllClassIdsSortedAlphabeticallyByName: (
  arg0: State
) => List<string> = createSelector(
  composedGetClassesSlice,
  classes.getAllClassIdsSortedAlphabeticallyByName
);

// @ts-ignore
export const getActiveClassIdsSortedAlphabeticallyByName: (
  arg0: State
) => List<string> = createSelector(
  composedGetClassesSlice,
  classes.getActiveClassIdsSortedAlphabeticallyByName
);

export const hasNoClasses = (state: State): boolean => {
  return !getAllClassIds(state).size;
};

export const getNumberOfClasses = (state: State): number =>
  getAllClassIds(state).size;

export const isFirstClass = (state: State): boolean =>
  getNumberOfClasses(state) === 1;

export const makeGetActiveClassIdsContainingCourse = (courseId: string) =>
  createSelector(composedGetClassesSlice, classesState => {
    return classes.getActiveClassIdsContainingCourse(classesState, courseId);
  });

export const makeGetActiveSimilarFilteredClassIds = (
  courseId: string | undefined,
  yearGroups: (number | undefined)[]
) =>
  createSelector(composedGetClassesSlice, classesState => {
    return classes.getSimilarFilteredActiveClassIds(
      classesState,
      courseId,
      yearGroups
    );
  });
export const makeGetClassNamesForClassIds = (classIds: string[]) =>
  createSelector(composedGetClassesSlice, classesState => {
    return classes.getClassNamesForClassIds(classesState, classIds);
  });

export const makeSortClassIdsAlphabeticallyByName = (classIds: string[]) =>
  createSelector(composedGetClassesSlice, classesState => {
    const classesToSort = classesState.filter(classRecord =>
      classIds.includes(classRecord.id!)
    );
    return classes.getAllClassIdsSortedAlphabeticallyByName(classesToSort);
  });

export const getActiveClasses: (arg0: State) => List<ClassRecord> =
  createSelector(composedGetClassesSlice, classesState => {
    return classes.getActiveClasses(classesState);
  });

export const getArchivedClasses: (arg0: State) => List<ClassRecord> =
  createSelector(composedGetClassesSlice, classesState => {
    return classes.getArchivedClasses(classesState);
  });

//manage classes

export const getSelectedClassIds: (arg0: State) => List<string> =
  composeSelector(
    composedGetManageClassesSlice,
    manageClasses.getSelectedClassIds
  );

export const getNumberOfSelectedClassIds = (state: State) =>
  getSelectedClassIds(state).size;

export const allClassesAreSelected: (arg0: State) => boolean = composeSelector(
  getClassSliceFromStore,
  getAllClassesAreSelected
);

export const classIdIsSelected = (state: State, classId: string) => {
  const manageClassesState = composedGetManageClassesSlice(state);
  return manageClasses.classIdIsSelected(manageClassesState, classId);
};
