import { List, Map, Record } from "immutable";

import ClassAccessor from "./accessor";

export type ClassType = {
  id: string | null;
  name: string | null;
  ownerId: string | null;
  courseIds: List<string>;
  numStudents: number | null;
  numTeachers: number | null;
  numConnectedParents: number | null;
  numPendingParents: number | null;
  enrollments: Map<string, ClassAccessor>;
  teachers: Map<string, ClassAccessor>;
  myTeacherAccessors: List<ClassAccessor>;
  enrolleeUserIds: List<string>;
  managedBy?: string | null;
  archived?: boolean | null;
  parentInformationAvailable?: boolean | null;
  schoolSyncExpiryDate?: string;
  schoolAccessRevoked?: boolean;
  yearGroup?: number;
  hidden?: boolean;
  timeLastSynced?: string;
};

export type OptionalClassType = Partial<ClassType>;

const defaultClass: ClassType = {
  id: null,
  name: null,
  ownerId: null,
  courseIds: List(),
  numTeachers: null,
  numStudents: null,
  numConnectedParents: null,
  numPendingParents: null,
  enrollments: Map<string, ClassAccessor>(),
  teachers: Map<string, ClassAccessor>(),
  myTeacherAccessors: List(),
  enrolleeUserIds: List(),
  managedBy: null,
  archived: null,
  parentInformationAvailable: null,
  schoolSyncExpiryDate: undefined,
  schoolAccessRevoked: undefined,
  yearGroup: undefined,
  hidden: undefined,
  timeLastSynced: undefined
};

export default class ClassRecord extends Record(defaultClass) {}
