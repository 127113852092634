import { Location } from "history";

import { useGetUrlSearchParams } from "seneca-common/utils/hooks/routing";

export const TEACHER_INVITE_REFERRER = "teacher-invite";

export const QUERY_PARAMS = {
  email: "email",
  referrer: "referrer",
  classId: "classId"
};

export default function useInviteQueryParamsValid(location: Location): boolean {
  const query = useGetUrlSearchParams(location);
  const classId = query.get(QUERY_PARAMS.classId);
  const email = query.get(QUERY_PARAMS.email);
  const referrer = query.get(QUERY_PARAMS.referrer);
  return !!(email && classId && referrer === TEACHER_INVITE_REFERRER);
}
