import { Record } from "immutable";

export type PeriodType = "week" | "month" | "year";

export type StatsDateRangesGroupType = {
  date: string;
  totalStudyTime: number;
};

export type StatsGroupedByDateRangesType = {
  [key in PeriodType]: StatsDateRangesGroupType[];
};

export const defaultStat: StatsGroupedByDateRangesType = {
  week: [],
  month: [],
  year: []
};

export default class StatsGroupedByDateRanges extends Record(defaultStat) {}
