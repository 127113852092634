import { State } from "common/types/redux";

import { allClassSkeletonsFetchSucceeded } from "./meta";

export default function withAllClassesFetch(selector: (arg0: State) => any) {
  return function (state: State) {
    const fetched = allClassSkeletonsFetchSucceeded(state);

    if (!fetched) {
      return null;
    }

    return selector(state);
  };
}
