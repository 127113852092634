import { Map, Record } from "immutable";

export type UserCourseStatsReviewType = {
  statsGroupedByDateRanges: Map<string, any>;
  stats: Map<string, any>;
  meta: Map<string, any>;
};

export type OptionalUserCourseStatsReviewType =
  Partial<UserCourseStatsReviewType>;

const defaultValues: UserCourseStatsReviewType = {
  statsGroupedByDateRanges: Map<string, any>(),
  stats: Map<string, any>(),
  meta: Map<string, any>()
};

export default class UserCourseStatsReviewState extends Record(defaultValues) {}
