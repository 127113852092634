import User from "../../../models/User";

export function getUserId(user: User) {
  return user.id;
}

export function getUserName(user: User) {
  return user.name;
}

export function getEmail(user?: User): string {
  return user?.email || "<Unknown>";
}

export function getGivenName(user: User) {
  return user?.givenName;
}

export function getFamilyName(user?: User) {
  return user?.familyName || "";
}

export function getUserNameOrEmail(user: User) {
  return getGivenName(user) && getFamilyName(user)
    ? getUserName(user)
    : getEmail(user);
}

export function getFirstNameOrEmail(user?: User): string {
  if (!user) return "<Unknown>";
  return getGivenName(user) || getEmail(user);
}

export function getUserNameWithEmailOrEmail(user: User): string {
  const email = getEmail(user);
  const name = getUserName(user);
  return name ? `${name} (${email})` : email;
}

export function isAnonymousUser(user: User) {
  return user.anonymousUser;
}

export function getUserManagedBy(user: User) {
  return user.managedBy;
}

export function isUserManaged(user: User): boolean {
  return !!getUserManagedBy(user);
}

export function getLastVisitTime(user: User) {
  return user.lastVisitTime;
}
