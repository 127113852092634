import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { PartialLocation } from "common/types/routing";

export default function useRedirectOnCondition(
  condition: boolean | any,
  redirectLocation: (string | PartialLocation) | null | undefined
) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const redirectIsObject =
      typeof redirectLocation === "object" && redirectLocation !== null;

    const redirectPath = redirectIsObject
      ? redirectLocation.pathname
      : redirectLocation;
    const redirectState = redirectIsObject ? redirectLocation.state : undefined;

    if (
      condition &&
      redirectLocation &&
      redirectPath &&
      pathname !== redirectPath
    ) {
      navigate(redirectLocation, { replace: true, state: redirectState });
    }
  }, [pathname, navigate, condition, redirectLocation]);
}
