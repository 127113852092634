import {
  didError,
  didSucceed,
  getDeleteSlice,
  getFetchAllSlice,
  getFetchSlice,
  getUpdateSlice,
  hasStarted
} from "seneca-common/utils/apiStates";

import { getClassSliceFromStore } from "./";
import {
  getClassesMetaSlice,
  getClassSkeletonsMetaSlice,
  getManageUsersMetaSlice
} from "./state";

type State = any;

function composedGetClassSkeletonsMetaSlice(state: State) {
  return getClassSkeletonsMetaSlice(getClassSliceFromStore(state));
}

function getFetchAllClassSkeletonsSlice(state: State) {
  return getFetchAllSlice(composedGetClassSkeletonsMetaSlice(state));
}

export function allClassSkeletonsAreFetching(state: State): boolean {
  return hasStarted(getFetchAllClassSkeletonsSlice(state));
}

export function allClassSkeletonsFetchSucceeded(state: State): boolean {
  return didSucceed(getFetchAllClassSkeletonsSlice(state));
}

export function allClassSkeletonsFetchErrored(state: State): boolean {
  return didError(getFetchAllClassSkeletonsSlice(state));
}

export function allClassSkeletonsNeedFetching(state: State): boolean {
  return !(
    allClassSkeletonsAreFetching(state) ||
    allClassSkeletonsFetchSucceeded(state)
  );
}

export function allClassSkeletonsFetchFinished(state: State): boolean {
  return (
    allClassSkeletonsFetchErrored(state) ||
    allClassSkeletonsFetchSucceeded(state)
  );
}

// Fetch
function composedGetClassMetaSlice(state: State) {
  return getClassesMetaSlice(getClassSliceFromStore(state));
}

function getFetchClassSlice(state: State) {
  return getFetchSlice(composedGetClassMetaSlice(state));
}

export function classIsFetching(state: State, classId: string): boolean {
  return hasStarted(getFetchClassSlice(state), classId);
}

export function classFetchSucceeded(state: State, classId: string): boolean {
  return didSucceed(getFetchClassSlice(state), classId);
}

export function classFetchErrored(state: State, classId: string): boolean {
  return didError(getFetchClassSlice(state), classId);
}

export function classFinishedFetching(state: State, classId: string): boolean {
  return (
    classFetchSucceeded(state, classId) || classFetchErrored(state, classId)
  );
}

export function classNeedsFetching(state: State, classId: string): boolean {
  return !(
    classFetchSucceeded(state, classId) || classIsFetching(state, classId)
  );
}

/** MANAGE USERS **/
function composedGetManageUsersMetaSlice(state: State) {
  return getManageUsersMetaSlice(getClassSliceFromStore(state));
}

// Delete - delete users
function getDeleteManageUsersSlice(state: State) {
  return getDeleteSlice(composedGetManageUsersMetaSlice(state));
}

export function deleteUsersIsPending(state: State, requestId: string): boolean {
  return hasStarted(getDeleteManageUsersSlice(state), requestId);
}

export function deleteUsersHasSucceeded(
  state: State,
  requestId: string
): boolean {
  return didSucceed(getDeleteManageUsersSlice(state), requestId);
}

export function deleteUsersHasErrored(
  state: State,
  requestId: string
): boolean {
  return didError(getDeleteManageUsersSlice(state), requestId);
}

// Update
export function getClassesUpdateSlice(state: State) {
  return getUpdateSlice(composedGetClassMetaSlice(state));
}

export function classIsUpdating(state: State, classId: string): boolean {
  return hasStarted(getClassesUpdateSlice(state), classId);
}

export function classUpdateSucceeded(state: State, classId: string): boolean {
  return didSucceed(getClassesUpdateSlice(state), classId);
}

export function classUpdateErrored(state: State, classId: string): boolean {
  return didError(getClassesUpdateSlice(state), classId);
}

// Delete
export function getClassDeleteSlice(state: State) {
  return getDeleteSlice(composedGetClassMetaSlice(state));
}

export function classIsBeingDeleted(state: State, classId: string): boolean {
  return hasStarted(getClassDeleteSlice(state), classId);
}

export function classDeleteSucceeded(state: State, classId: string): boolean {
  return didSucceed(getClassDeleteSlice(state), classId);
}

export function classDeleteErrored(state: State, classId: string): boolean {
  return didError(getClassDeleteSlice(state), classId);
}

// Leave
export function getClassLeaveSlice(state: State) {
  return getDeleteSlice(composedGetClassMetaSlice(state));
}

export const classIsBeingLeft = classIsBeingDeleted;
export const classLeaveSucceeded = classDeleteSucceeded;
export const classLeaveErrored = classDeleteErrored;
