import { List, Map } from "immutable";

import {
  makeGetStatsMap,
  Timestamp
} from "seneca-common/features/stats-review/state";

import { makeId } from "../../../utils";

type UserId = string;
type CourseId = string;
type StatId = string;

export function makeGetUserCoursesStats(
  userId: UserId,
  courseIds: List<CourseId>,
  startTime: Timestamp | null | undefined,
  endTime: Timestamp
) {
  const courseIdToStatIdMap: Map<CourseId, StatId> = courseIds.reduce(
    (map, courseId) =>
      map.set(courseId, makeId(userId, courseId, startTime, endTime)),
    Map<CourseId, StatId>()
  );

  return makeGetStatsMap(courseIdToStatIdMap);
}
