import { List } from "immutable";

import { Timestamp } from "seneca-common/features/stats-review/state";

export default class InvalidIntervalStatTimestamps extends Error {
  startTimes: List<Timestamp>;
  endTimes: List<Timestamp>;

  constructor(startTimes: List<Timestamp>, endTimes: List<Timestamp>) {
    super("startTimes and endTimes must be lists of the same size");
    this.startTimes = startTimes;
    this.endTimes = endTimes;
  }
}
