import { Dispatch, GetState } from "common/types/redux";

import { getClient } from "services";

import {
  fetchActionFactory,
  makeIds,
  receiveUserCourseStats
} from "seneca-common/features/stats-review/features/user-course-stats";
import { CourseStatsFromServerType } from "seneca-common/features/stats-review/service";
import { awaitCondition } from "seneca-common/utils/state/await-condition";

import {
  classStatsFetchFinished,
  classStatsNeedFetching
} from "../selectors/classStats";
import { classFetchSucceeded, getClassEnrolleesUserIds } from "../state";
import { ClassCourseStatsResponse } from "./types";

export const fetchClassStatsAction = fetchActionFactory({
  requestFunction: ({ contextId, userIds, courseId, startTime, endTime }) =>
    getClient("statsService").fetchWindowedMultiUserCourseStats({
      userIds,
      courseId,
      contextId,
      startTime,
      endTime
    }),
  storeAction: receiveWindowedCourseStatsForClass
});

function receiveWindowedCourseStatsForClass(
  classStatsResponse: ClassCourseStatsResponse
) {
  const {
    courseId,
    startTime = null,
    endTime,
    stats: receivedStats
  } = classStatsResponse;

  // @ts-ignore
  const courseStats: CourseStatsFromServerType = Object.keys(
    receivedStats
    // @ts-ignore
  ).reduce((acc, userId) => {
    const stat = {
      ...receivedStats[userId],
      userId,
      courseId,
      startTime,
      endTime
    };

    return [...acc, stat];
  }, []);

  return receiveUserCourseStats({ userCourseStats: courseStats });
}

export function fetchClassStats(
  classId: string,
  courseId: string,
  startTime: number | null | undefined,
  endTime: number
) {
  return function (dispatch: Dispatch, getState: GetState) {
    const state = getState();
    const userIds = getClassEnrolleesUserIds(state, classId);
    const statIds = makeIds(userIds, courseId, startTime, endTime);

    return dispatch(
      fetchClassStatsAction(
        {
          contextId: classId,
          userIds: userIds.toArray(),
          courseId,
          startTime,
          endTime
        },
        statIds.toArray()
      )
    );
  };
}

export function fetchClassStatsIfNeeded(
  classId: string,
  courseId: string,
  startTime: number | null | undefined,
  endTime: number
) {
  return function (dispatch: Dispatch, getState: GetState) {
    const state = getState();

    if (classStatsNeedFetching(state, classId, courseId, startTime, endTime)) {
      return dispatch(fetchClassStats(classId, courseId, startTime, endTime));
    } else {
      return dispatch(
        awaitCondition(
          (state: any) =>
            classFetchSucceeded(state, classId) &&
            classStatsFetchFinished(
              state,
              classId,
              courseId,
              startTime,
              endTime
            )
        )
      );
    }
  };
}
