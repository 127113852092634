import { useCallback, useEffect, useRef } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom-v5-compat";

import { PartialLocation } from "common/types/routing";

/**
 Makes a callback to route to a link, where you bake in any parameters
 i.e.
 const linkToCourse = (courseId) => `/some/path/${courseId}`

 const navigateToCourse = useSimpleNavigationCallback(linkToCourse('someCourseId'))

 // No params needed
 navigateToCourse()

 If you need to pass params at the call site (i.e. navigateToCourse('someCourseId')) see useNavigationCallback
 */

type SimpleLink = () => void;

export default function useSimpleNavigationCallback(
  link: string | PartialLocation,
  navigationOptions?: NavigateOptions
): SimpleLink {
  const navigate = useNavigate();

  const linkRef = useRef(link);

  useEffect(() => {
    linkRef.current = link;
  }, [link]);

  return useCallback(() => {
    if (typeof linkRef.current === "string") {
      navigate(linkRef.current, navigationOptions);
    } else {
      const { state, ...locationParams } = linkRef.current;
      navigate(locationParams, { state, ...navigationOptions });
    }
  }, [navigate, navigationOptions]);
}
