const allTime = "allTime";
const interval = "interval";

export const CHART_TYPES = {
  allTime,
  interval
};

export type ChartOptions = {
  type: keyof typeof CHART_TYPES;
  startTime: number;
  endTime: number;
  resolution: number;
};
