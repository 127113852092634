import { List, Map } from "immutable";
import { createSelector } from "reselect";

import { makeGetManyAllTimeSumUsersCourseStatGroups } from "seneca-common/features/stats-review/features/user-course-stats";
import {
  STAT_GROUPS,
  Timestamp
} from "seneca-common/features/stats-review/state";
import sortAscendingOrder from "seneca-common/utils/functions/sorting/sortAscendingOrder";

import { ChartData, ChartDataPoint } from "../models/ChartData";
import { ChartOptions } from "../models/ChartOptions";
import { getUniformTimestamps } from "../utils/chartOptions";

export function makeGetUsersCourseSumLineChartData(
  userIds: List<string>,
  courseId: string,
  statGroup: STAT_GROUPS,
  chartOptions: ChartOptions
) {
  const timestampPairs = getUniformTimestamps(chartOptions);

  const endTimes = timestampPairs.map(pair => pair.endTime);
  const getManyAllTimeSumUserCourseStatGroups =
    makeGetManyAllTimeSumUsersCourseStatGroups(
      userIds,
      courseId,
      // @ts-ignore
      endTimes,
      statGroup
    );

  return createSelector(
    getManyAllTimeSumUserCourseStatGroups,
    mapStatsToChartData
  );
}

function mapStatsToChartData(stats: Map<Timestamp, number>): ChartData {
  return List<ChartDataPoint>().withMutations(chartData =>
    stats
      .reduce(
        (chartData, stat, endTime) =>
          chartData.push(
            new ChartDataPoint({
              x: endTime,
              y: stat
            })
          ),
        chartData
      )
      .sort((a: any, b: any) => sortAscendingOrder(a.x, b.x))
  );
}
