import { List, Map } from "immutable";
import { createSelector, defaultMemoize } from "reselect";

import { STAT_GROUPS } from "../consts";
import StatDoesNotExistError from "../errors/StatDoesNotExistError";
import Stat from "../models/Stat";
import { getStatGroup as getGroupFromStat } from "./stat";

type State = Map<string, Stat>;
type StatId = string;
type StatsGroupingId = any;
type UserId = string;

export function getStatGroup(
  stats: State,
  statId: StatId,
  statGroup: STAT_GROUPS
): number {
  const stat = getStat(stats, statId);
  if (!stat) throw new StatDoesNotExistError(statId);
  return getGroupFromStat(stat, statGroup);
}

function getStat(stats: State, statId: StatId): Stat | null | undefined {
  return stats.get(statId);
}

function getUserGroupedStats(
  statsGroupedByDateRanges: State,
  userId: UserId
): Stat | null | undefined {
  return statsGroupedByDateRanges.get(userId);
}

export function makeGetStatsMap(statsIdsMap: Map<StatsGroupingId, StatId>) {
  return defaultMemoize((stats: State) =>
    statsIdsMap.map(statId => getStat(stats, statId))
  );
}

export function makeGetUserCoursesStatsGroupedByDateRanges(userId: UserId) {
  return defaultMemoize((stats: State) => getUserGroupedStats(stats, userId));
}

export const makeGetStatGroupSum =
  makeGetStatGroupCombinedSelector(calculateSum);

export const makeGetStatGroupAverage =
  makeGetStatGroupCombinedSelector(calculateAverage);

export const makeGetStatsSum = makeGetStatsCombinedSelector(calculateSum);

export const makeGetStatsAverage =
  makeGetStatsCombinedSelector(calculateAverage);

export const makeGetManyStatGroupSum =
  makeGetManyStatGroupsCombinedSelector(calculateSum);

export const makeGetManyStatGroupAverage =
  makeGetManyStatGroupsCombinedSelector(calculateAverage);

function makeGetStatGroupCombinedSelector(
  combiner: (arg0: Map<StatId, number>) => number
) {
  return (statIds: List<StatId>, statGroup: STAT_GROUPS) => {
    const getStatGroups = makeGetStatGroups(statIds, statGroup);
    return createSelector(getStatGroups, combiner);
  };
}

function makeGetStatsCombinedSelector(
  combiner: (arg0: Map<StatId, number>) => number
) {
  return (statIds: List<StatId>) => {
    const statGroupSelectors = (Object.keys(STAT_GROUPS) as STAT_GROUPS[]).map(
      statGroup =>
        makeGetStatGroupCombinedSelector(combiner)(statIds, statGroup)
    );

    return createSelector(
      statGroupSelectors,
      (
        sessionsCompleted,
        studyDuration,
        averageScore,
        sessionModulesCorrect,
        sessionModulesStudied
      ) =>
        new Stat({
          sessionsCompleted,
          studyDuration,
          averageScore,
          sessionModulesCorrect,
          sessionModulesStudied
        })
    );
  };
}

function makeGetManyStatGroupsCombinedSelector(
  combiner: (arg0: Map<StatId, number>) => number
) {
  return (
    statIdsMap: Map<StatsGroupingId, List<StatId>>,
    statGroup: STAT_GROUPS
  ) => {
    const getManyStatGroups = makeGetManyStatGroupsByGrouping(
      statIdsMap,
      statGroup
    );
    return createSelector(
      getManyStatGroups,
      (statGroupsMap: Map<StatsGroupingId, Map<StatId, number>>) =>
        statGroupsMap.map(combiner)
    );
  };
}

export function makeGetManyStatGroups(
  statIdsMap: Map<StatsGroupingId, StatId>,
  statGroup: STAT_GROUPS
) {
  const getStatGroupsMap = statIdsMap.map(
    statId => (state: any) => getStatGroup(state, statId, statGroup)
  );
  return defaultMemoize((stats: State) =>
    getStatGroupsMap.map(getStatGroup => getStatGroup(stats))
  );
}

function makeGetManyStatGroupsByGrouping(
  statIdsMap: Map<StatsGroupingId, List<StatId>>,
  statGroup: STAT_GROUPS
) {
  const getStatGroupsMap = statIdsMap.map(statIds =>
    makeGetStatGroups(statIds, statGroup)
  );
  return defaultMemoize((stats: State) =>
    getStatGroupsMap.map(getStatGroups => getStatGroups(stats))
  );
}

function makeGetStatGroups(statIds: List<StatId>, statGroup: STAT_GROUPS) {
  const getStats = makeGetStats(statIds);
  return createSelector(getStats, stats => getGroupFromStats(stats, statGroup));
}

function getStats(stats: State, statIds: List<StatId>): State {
  // @ts-ignore
  return Map().withMutations(requiredStats =>
    statIds.reduce(
      (requiredStats, statId) =>
        stats.has(statId)
          ? requiredStats.set(statId, stats.get(statId))
          : requiredStats,
      requiredStats
    )
  );
}

function makeGetStats(statIds: List<StatId>) {
  return defaultMemoize((stats: State) => getStats(stats, statIds));
}

function getGroupFromStats(stats: State, statGroup: STAT_GROUPS) {
  return stats.map((stat: any) => getGroupFromStat(stat, statGroup));
}

function calculateSum(values: Map<StatId, number>): number {
  return values.reduce((sum, value) => sum + value, 0);
}

function calculateAverage(values: Map<StatId, number>): number {
  return values.size > 0 ? calculateSum(values) / values.size : 0;
}
