import { UserCredential } from "firebase/auth";

import { Dispatch, GetState } from "common/types";

import {
  fetchSignInMethodsForEmail,
  login as loginFirebase
} from "services/firebase/auth";
import { clearProviderFromGlobalDataLayer } from "services/firebase/auth/analytics/events";
import {
  IncorrectPasswordError,
  InvalidSignInMethod,
  NetworkRequestFailedError
} from "services/firebase/auth/errors";
import { getFirstSSOProvider } from "services/firebase/auth/ssoProvider";

import { fetchMySchoolInfoAndPushValuesToDataLayer } from "seneca-common/features/schools/service/utils";
import { fetchSubscriptionsIfNotFetched } from "seneca-common/features/subscriptions/state/service";
import {
  getRegion,
  signInError,
  signInStart,
  signInSuccess
} from "seneca-common/features/user/state";
import {
  analyticsLogSignIn,
  analyticsReferredUserSignIn
} from "seneca-common/utils/senecaAnalytics";

import { setRegionPreference } from "../../actions/preferences";
import { getFirebaseEmail } from "../../utils/email";
import { setUserAsSignedUpBefore } from "../../utils/signedUpBefore";
import { fetchUserInfo } from "../userInfo";
import { reconcileData } from "./utils";

export function signInWithEmailAndPassword(
  email: string,
  password: string,
  referralCode?: string | null | undefined
) {
  return async function (dispatch: Dispatch, getState: GetState) {
    try {
      dispatch(signInStart());

      const firebaseEmail = await getFirebaseEmail(email);
      const region = getRegion(getState()); // region needed before sign in to compare with saved region
      let userCreds: UserCredential;

      try {
        userCreds = await loginFirebase(firebaseEmail, password);
      } catch (err: any) {
        const signInMethods = await fetchSignInMethodsForEmail(email);

        if (
          err instanceof IncorrectPasswordError &&
          emailSignInUnavailable(signInMethods)
        ) {
          const signInProvider = getFirstSSOProvider(signInMethods);
          throw new InvalidSignInMethod(signInProvider);
        } else {
          throw err;
        }
      }

      try {
        await Promise.all([
          dispatch(fetchUserInfo()),
          fetchMySchoolInfoAndPushValuesToDataLayer()
        ]);
      } catch (err: any) {}

      await dispatch(reconcileData());

      setUserAsSignedUpBefore();
      analyticsLogSignIn(getState());

      // region ======
      const persistedRegion = getRegion(getState());

      if (!persistedRegion && region) {
        dispatch(setRegionPreference(region));
      }

      // =============

      referralCode && analyticsReferredUserSignIn(referralCode); // probably still relied upon

      dispatch(signInSuccess(userCreds.user.uid));
      await fetchAdditionalUserInfoOnSignIn(dispatch); // added to fix theme as subscription didn't get refetched on sign in. could do this with a `useEffect(_, [signedIn]) instead
    } catch (error: any) {
      dispatch(signInError());

      clearProviderFromGlobalDataLayer();

      if (error.code === "auth/network-request-failed") {
        throw new NetworkRequestFailedError();
      }

      throw error;
    }
  };
}

export async function fetchAdditionalUserInfoOnSignIn(dispatch: Dispatch) {
  return dispatch(fetchSubscriptionsIfNotFetched());
}

export function emailSignInUnavailable(signInMethods: string[]) {
  return signInMethods.length > 0 && !signInMethods.includes("password");
}
