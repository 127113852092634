import { useCallback } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom-v5-compat";

import "common/types";

import { PartialLocation } from "common/types/routing";

type Link<ArgsT extends Array<any>> = (
  ...args: ArgsT
) => string | PartialLocation;

type ReturnedCallback<ArgsT extends Array<any>> = (...args: ArgsT) => void;

/**
 Makes a callback to route to a link, following the same API as the link.
 i.e.
 const linkToCourse = (courseId) => `/some/path/${courseId}`

 const navigateToCourse = useNavigationCallback(linkToCourse)

 navigateToCourse("someCourseId")
 */

export default function useNavigationCallback<ArgsT extends Array<any> = any[]>(
  link: Link<ArgsT>,
  navigationOptions?: NavigateOptions
): ReturnedCallback<ArgsT> {
  const navigate = useNavigate();

  return useCallback(
    (...args: ArgsT) => {
      const path = link(...args);

      if (typeof path === "string") {
        navigate(path, navigationOptions);
      } else {
        const { state, ...locationParams } = path;
        navigate(locationParams, { state, ...navigationOptions });
      }
    },
    [link, navigate, navigationOptions]
  );
}
