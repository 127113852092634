const actionTypes = {
  RECEIVE_CLASS: "seneca/class/RECEIVE_CLASS",
  RECEIVE_CLASS_SKELETONS: "seneca/class/RECEIVE_CLASS_SKELETONS",
  UPDATE_CLASS_DETAILS: "seneca/class/UPDATE_CLASS_DETAILS",
  DELETE_USERS_FROM_CLASS: "seneca/class/DELETE_USERS_FROM_CLASS",
  UPDATE_CLASS_NAME: "seneca/class/UPDATE_CLASS_NAME",
  DELETE_CLASS: "seneca/class/DELETE_CLASS",
  LEAVE_CLASS: "seneca/class/LEAVE_CLASS",
  TOGGLE_CLASS_SELECTED: "seneca/manageClasses/TOGGLE_CLASS_SELECTED",
  TOGGLE_ALL_CLASSES: "seneca/manageClasses/TOGGLE_ALL_CLASSES",
  CLEAR_ALL: "seneca/manageClasses/CLEAR_ALL",
  SELECT_MANY: "seneca/manageClasses/SELECT_MANY",
  RECEIVE_TEACHER_ACCESSORS: "seneca/manageClasses/RECEIVE_TEACHER_ACCESSORS"
};

export default actionTypes;
