import { Map } from "immutable";

import { StatsGroupedByDateRangesType } from "seneca-common/features/stats-review/state/models/StatsGroupedByDateRanges";

import UserCourseStat from "../../models/UserCourseStat";
import UserCourseStatsReviewState from "../../models/UserCourseStatsReviewState";

export function getStats(
  state: UserCourseStatsReviewState
): Map<string, UserCourseStat> {
  return state.stats;
}

export function getStatsGroupedByDateRanges(
  state: UserCourseStatsReviewState
): Map<string, StatsGroupedByDateRangesType> {
  return state.statsGroupedByDateRanges;
}

export function getMeta(
  state: UserCourseStatsReviewState
): Map<string, UserCourseStat> {
  return state.meta;
}
