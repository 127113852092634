import { Map, Record } from "immutable";

import { Classes } from "./classes";
import ClassRecord from "./classes/class";
import ManageClasses from "./manageClasses";
import MetaSlice from "./meta";

export type ClassStateSliceType = {
  classes: Classes;
  meta: MetaSlice;
  manageClasses: ManageClasses;
};

const defaultClassStateSlice: ClassStateSliceType = {
  classes: Map<string, ClassRecord>(),
  meta: new MetaSlice(),
  manageClasses: new ManageClasses()
};

export default class ClassStateSlice extends Record(defaultClassStateSlice) {}
