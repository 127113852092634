import { State } from "common/types/redux";

import {
  allClassSkeletonsFetchSucceeded,
  getAllClassIds,
  getClassManagedBy,
  getClassName,
  getNumberOfCourses,
  getNumberOfStudentsInClass
} from "../state";

export type ClassAnalyticsPayload = {
  classId: string;
  className?: string;
  numStudentsInClass?: number;
  numCoursesInClass?: number;
  numClasses?: number;
  isFirstClass?: boolean;
  classManagedBy?: string;
  totalNumOfAssignmentsSet?: number;
  numUpcomingClassAssignments?: number;
};

export default function generateClassAnalyticsPayload(
  state: State,
  classId: string
): ClassAnalyticsPayload {
  if (!allClassSkeletonsFetchSucceeded(state)) {
    return {
      classId
    };
  }

  const numClasses = getAllClassIds(state).size;
  const isFirstClass = numClasses === 1;
  const className = getClassName(state, classId)!;
  const numStudentsInClass = getNumberOfStudentsInClass(state, classId);
  const numCoursesInClass = getNumberOfCourses(state, classId);
  const classManagedBy = getClassManagedBy(state, classId)!;

  if (isFirstClass) {
    return {
      classId,
      isFirstClass,
      classManagedBy,
      className,
      numStudentsInClass,
      numCoursesInClass
    };
  }

  return {
    classId,
    className,
    numStudentsInClass,
    numCoursesInClass,
    numClasses,
    isFirstClass,
    classManagedBy
  };
}
