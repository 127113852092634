import { PartialLocation } from "common/types/routing";

import useRedirectOnCondition from "./useRedirectOnCondition";

type ConditionalRedirect = {
  condition: boolean | null | undefined;
  link: string | PartialLocation;
};

export default function useFirstRedirectOnCondition(
  redirects: ConditionalRedirect[]
): boolean {
  const redirect = redirects.find(({ condition }) => condition);

  const shouldRedirect = !!redirect;
  const link = redirect && redirect.link;

  useRedirectOnCondition(shouldRedirect, link);

  return shouldRedirect;
}
