import { GoogleAuthProvider, OAuthProvider } from "firebase/auth";

import isGoStudentLearningApp from "features/goStudentLearning/utils/isGoStudentLearningApp";
import { isPlurallApp } from "features/plurall";

import { getFirebaseAuth } from "../../firebase/auth";
import { SSO_PROMPT_SELECT_ACCOUNT } from "./consts";
import { linkWithPopup, unlink } from "./firebase";
import { Providers, ProviderSSO } from "./types";

export const googleProvider = new GoogleAuthProvider();
export const microsoftProvider = new OAuthProvider("microsoft.com");
export const plurallProvider = new OAuthProvider("oidc.plurall").addScope(
  "services"
);

export const goStudentProvider = new OAuthProvider("oidc.gostudent");
const GO_STUDENT_PROVIDER_SCOPES = [
  "openid",
  "email",
  "offline_access",
  "profile",
  "extra_info",
  "phone"
];
GO_STUDENT_PROVIDER_SCOPES.forEach(scope => goStudentProvider.addScope(scope));

export const studienkreisProvider = new OAuthProvider(
  "oidc.studienkreis"
).addScope("userinfo");

export const oidcPlaygroundProvider = new OAuthProvider("oidc.oidc-playground");

const providers: Providers = Object.freeze({
  google: {
    displayName: "Google",
    OAuthProviderInstance: googleProvider,
    OAuthProvider: GoogleAuthProvider,
    id: "google.com"
  },
  microsoft: {
    displayName: "Microsoft",
    OAuthProviderInstance: microsoftProvider,
    OAuthProvider: OAuthProvider,
    id: "microsoft.com"
  },
  ...(isPlurallApp() && {
    plurall: {
      displayName: "Plurall",
      OAuthProviderInstance: plurallProvider,
      OAuthProvider: OAuthProvider,
      id: plurallProvider.providerId
    }
  }),
  ...(isGoStudentLearningApp() && {
    gostudent: {
      displayName: "GoStudent Learning",
      OAuthProviderInstance: goStudentProvider,
      OAuthProvider: OAuthProvider,
      id: goStudentProvider.providerId
    },
    studienkreis: {
      displayName: "Studienkreis",
      OAuthProviderInstance: studienkreisProvider,
      OAuthProvider: OAuthProvider,
      id: studienkreisProvider.providerId
    }
  })
});

export function getAllProviders() {
  return providers;
}

export function getOAuthProviderInstance(
  providerName: ProviderSSO
): OAuthProvider {
  const provider = providers[providerName];

  if (!provider) {
    throw new Error(`Unrecognised provider name: ${providerName}`);
  }

  return provider.OAuthProviderInstance;
}

export function getOAuthProvider(
  providerName: ProviderSSO
): typeof OAuthProvider {
  const provider = providers[providerName];

  if (!provider) {
    throw new Error(`Unrecognised provider name: ${providerName}`);
  }

  return provider.OAuthProvider;
}

export function getProviderId(providerName: ProviderSSO) {
  const provider = providers[providerName];

  if (!provider) {
    throw new Error(`Unrecognised provider name: ${providerName}`);
  }

  return provider.id;
}

export function getFirstSSOProvider(signInMethods: string[]) {
  return getProviderDisplayName(signInMethods[0]);
}

export function getProviderName(providerId: string): ProviderSSO {
  const entry = Object.entries(providers).find(
    ([name, provider]) => provider.id === providerId
  );

  if (!entry) {
    throw new Error(`Unrecognised provider id: ${providerId}`);
  }

  const [name] = entry;

  return name as ProviderSSO;
}

export function getProviderDisplayName(providerId: string) {
  const provider = Object.values(providers).find(
    provider => provider.id === providerId
  );

  if (!provider) {
    throw new Error(`Unrecognised provider id: ${providerId}`);
  }

  return provider.displayName;
}

export async function linkProvider(providerName: ProviderSSO) {
  const provider = getOAuthProviderInstance(providerName);

  provider.setCustomParameters({
    prompt: SSO_PROMPT_SELECT_ACCOUNT
  });

  return linkWithPopup(getFirebaseAuth().currentUser!, provider);
}

export async function unlinkProvider(providerName: ProviderSSO) {
  const providerId = getProviderId(providerName);
  return unlink(getFirebaseAuth().currentUser!, providerId);
}

export function getUserLinkedProviders(): any[] {
  return getFirebaseAuth().currentUser
    ? getFirebaseAuth().currentUser!.providerData
    : ["password"];
}

export function hasUserPasswordProvider() {
  const providers = getUserLinkedProviders();
  const hasProvider = providers.find(
    ({ providerId }) => providerId === "password"
  );
  return !!hasProvider;
}

export function hasUserProvider(providerId: string) {
  const providers = getUserLinkedProviders();

  return !!providers.find(({ providerId: id }) => id === providerId);
}

export function isOnlyLoginProvider(linkedProviders: any[]) {
  const getEmailProvider = linkedProviders.find(
    ({ providerId }) => providerId === "password"
  );

  return !getEmailProvider && linkedProviders.length === 1;
}

export function getUserDefaultProviders() {
  const providers = getAllProviders();
  return Object.values(providers).map((value: any) => ({
    providerId: value.id
  }));
}

export function shouldUpdatePrimaryProvider(primaryEmail: string) {
  const userProviders = getUserLinkedProviders();
  return userProviders.every(provider => provider.email !== primaryEmail);
}

export function getNewPrimaryProvider() {
  const userProviders = getUserLinkedProviders();
  return userProviders.find(provider => provider);
}
