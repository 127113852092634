import { List } from "immutable";

import { ClassAccessorViewObject } from "@seneca/class-service-types";

import { Dispatch, GetState } from "common/types";

import { getClient } from "services";
import errorHandler from "services/classService/errorHandler";

import { analyticsLogAddCoursesToClass } from "seneca-common/features/class/analytics";
import { isSignedIn } from "seneca-common/features/user/state";
import { awaitCondition } from "seneca-common/utils/state/await-condition";

import { CourseAddedLocation } from "../analytics/events";
import {
  allClassSkeletonsFetchFinished,
  allClassSkeletonsNeedFetching,
  classFinishedFetching,
  classNeedsFetching,
  deleteClassActionFactory,
  fetchAllClassSkeletonsActionFactory,
  fetchClassActionFactory,
  getClassCourseIds,
  leaveClassActionFactory,
  receiveClass,
  receiveClasses,
  updateClassActionFactory,
  updateClass as updateClassLocally
} from "../state";
import { ClassReceived } from "./types";

const getClassServiceClient = () => getClient("classService");

const fetchAllClassSkeletons = ({ schoolId }: { schoolId?: string } = {}) =>
  fetchAllClassSkeletonsActionFactory({
    requestFunction: schoolId =>
      getClassServiceClient().fetchAllClassSkeletons({ schoolId }),
    storeAction: receiveClasses
  })(schoolId, schoolId);

export const fetchClass = (classId: string) =>
  fetchClassActionFactory({
    requestFunction: classId => getClassServiceClient().fetchClass(classId),
    storeAction: receiveClass,
    errorHandler
  })(classId, classId);

const updateClassAction = updateClassActionFactory({
  requestFunction: (classRecord, classId) =>
    getClassServiceClient().updateClass(classRecord, classId),
  errorHandler
});

const deleteClassAction = deleteClassActionFactory({
  requestFunction: classId => getClassServiceClient().deleteClass(classId)
});

const leaveClassAction = leaveClassActionFactory({
  requestFunction: classId => getClassServiceClient().leaveClass(classId)
});

export const fetchClassIfNotFetched =
  (classId: string) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();

    if (isSignedIn(state)) {
      if (classNeedsFetching(state, classId)) {
        return dispatch(fetchClass(classId));
      } else {
        return dispatch(awaitCondition(s => classFinishedFetching(s, classId)));
      }
    }
  };

export const fetchAllClassSkeletonsIfNotFetched =
  ({ schoolId }: { schoolId?: string } = {}) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();

    if (isSignedIn(state)) {
      if (allClassSkeletonsNeedFetching(state)) {
        return dispatch(fetchAllClassSkeletons({ schoolId }));
      } else {
        return dispatch(awaitCondition(allClassSkeletonsFetchFinished));
      }
    }
  };

export const createClass = async (className: string) => {
  const result = await getClient("classService").createClass({
    name: className
  });

  return result!;
};

export const createClassWithCourses = async (
  className: string,
  courseIds: string[]
) => {
  const result = await getClient("classService").createClass({
    name: className,
    courseIds
  });

  return result!;
};

export const createAndReceiveClass =
  (className: string) => async (dispatch: Dispatch, getState: GetState) => {
    const newClass = await createClass(className);
    dispatch(receiveClass(newClass));
    return newClass;
  };

export const createAndReceiveClassWithCourses =
  (className: string, courseIds: string[]) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const newClass = await createClassWithCourses(className, courseIds);
    dispatch(receiveClass(newClass));
    return newClass;
  };

export const updateClassDetails =
  (updates: Record<string, string>, classId: string) =>
  async (dispatch: Dispatch) => {
    const output = await dispatch(
      updateClassAction([updates, classId], classId)
    );

    if (!(output instanceof Error)) {
      dispatch(updateClassLocally({ ...updates, classId }));
    }

    return output;
  };

export const updateAndReceiveClass =
  (
    updates: Record<string, unknown>,
    classId: string,
    onSuccess?: (...args: Array<any>) => any
  ) =>
  async (dispatch: Dispatch): Promise<ClassReceived> => {
    const updatedClass: ClassReceived = await dispatch(
      updateClassAction([updates, classId], classId)
    );

    if (!(updatedClass instanceof Error)) {
      dispatch(receiveClass(updatedClass));
      onSuccess && onSuccess();
    }

    return updatedClass;
  };

export const deleteClassDetails =
  (id: string) => async (dispatch: Dispatch) => {
    const output = await dispatch(deleteClassAction(id, id));
    return output;
  };

export const leaveClass = (classId: string) => async (dispatch: Dispatch) => {
  return await dispatch(leaveClassAction(classId, classId));
};

export const removeCourse =
  (
    classId: string,
    courseIdToRemove: string,
    onSuccess?: (...args: Array<any>) => any
  ) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const courseIds = getClassCourseIds(state, classId);
    const newCourseIds = courseIds
      .filter((courseId: string) => courseId !== courseIdToRemove)
      .toArray();
    return dispatch(
      updateAndReceiveClass(
        {
          courseIds: newCourseIds
        },
        classId,
        onSuccess
      )
    );
  };

export const addCourses =
  (
    classId: string,
    courseIdsToAdd: List<string>,
    locationAdded: CourseAddedLocation | undefined,
    onSuccessCallback?: () => void
  ) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const existingCourseIds = getClassCourseIds(state, classId);
    const newCourseIds = courseIdsToAdd
      .concat(existingCourseIds)
      .toSet()
      .toArray();

    const onSuccess = () => {
      if (onSuccessCallback) onSuccessCallback();
      dispatch(
        analyticsLogAddCoursesToClass(
          classId,
          newCourseIds,
          courseIdsToAdd.size,
          locationAdded
        )
      );
    };

    return dispatch(
      updateAndReceiveClass(
        {
          courseIds: newCourseIds
        },
        classId,
        onSuccess
      )
    );
  };

export async function archiveClasses(classIds: string[]) {
  return await getClassServiceClient().archiveClasses({
    classIds,
    archive: true
  });
}

export async function unarchiveClasses(classIds: string[]) {
  return await getClassServiceClient().archiveClasses({
    classIds,
    archive: false
  });
}

export async function hideClasses({
  classIds,
  hide
}: {
  classIds: string[];
  hide: boolean;
}) {
  return (await getClassServiceClient().hideClasses({
    classIds,
    hide
  })) as { items: ClassAccessorViewObject[] };
}
