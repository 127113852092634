import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  allClassSkeletonsFetchFinished,
  hasNoClasses as getHasNoClasses
} from "seneca-common/features/class/state";
import { useNavigationCallback } from "seneca-common/utils/hooks/routing";

import { useLocalisedMessage } from "features/localisation";
import { ROUTES } from "routing/routes";

import { createClassWithCourses } from "../actions";
import { messages } from "./translations";

type ReturnedProps = {
  loading: boolean;
  hasNoClasses: boolean;
  creatingClass: boolean;
  createMyFirstClass?: () => Promise<void>;
};

export default function useGetTeacherTopbarProps(
  courseId?: string
): ReturnedProps {
  const [creatingClass, setCreatingClass] = useState(false);
  const dispatch = useDispatch();

  const className = useLocalisedMessage(messages.myFirstClassName);

  const hasNoClasses = useSelector(getHasNoClasses);
  const loading = !useSelector(allClassSkeletonsFetchFinished);

  const navigateToNewClassOverview = useNavigationCallback(classId =>
    ROUTES.TEACHER.CLASS.OVERVIEW.buildPath({ classId })
  );

  if (courseId) {
    // @ts-ignore
    async function createMyFirstClass() {
      setCreatingClass(true);
      const newClass: any = await dispatch(
        createClassWithCourses(className, [courseId!])
      );
      navigateToNewClassOverview(newClass.classId);
    }

    return {
      loading,
      creatingClass,
      hasNoClasses,
      createMyFirstClass
    };
  }

  return {
    loading,
    creatingClass,
    hasNoClasses
  };
}
