import { List, Record } from "immutable";

import { ChildParentConnectionI } from "@seneca/parents-service-types";

export const AccessorRole = Object.freeze({
  owner: "OWNER",
  editor: "EDITOR",
  enrolee: "ENROLEE"
});
export const AccessorStatus = Object.freeze({
  active: "ACTIVE",
  pending: "PENDING"
});

export type AccessorRoleType = (typeof AccessorRole)[keyof typeof AccessorRole];
export type AccessorStatusType =
  (typeof AccessorStatus)[keyof typeof AccessorStatus];

export type ClassAccessorType = {
  userId: string | null;
  timeCreated: string | null;
  role: AccessorRoleType | null;
  status: AccessorStatusType | null;
  managedBy?: string | null;
  numParents?: number | null;
  parentConnections?: List<ChildParentConnectionI> | null;
  lastViewed?: string | null;
  hidden?: boolean | null;
};

const defaultClassAccessor: ClassAccessorType = {
  userId: null,
  timeCreated: null,
  role: null,
  status: null,
  managedBy: null,
  numParents: null,
  parentConnections: null,
  lastViewed: null,
  hidden: null
};

export default class ClassAccessor extends Record(defaultClassAccessor) {}
