import {
  linkToRetryPayment,
  linkToUpgrade,
  linkToUpgradeSubscriptionInfoiOS
} from "features/subscriptions/features/upgrades/routes/links";

import { linkToStudentWhatIsPremiumPage } from "../links";

export function linkToStudentUpgradeSubscription() {
  return linkToUpgrade(linkToStudentWhatIsPremiumPage());
}

export function linkToStudentUpgradeSubscriptionInfoiOS() {
  return linkToUpgradeSubscriptionInfoiOS(linkToStudentWhatIsPremiumPage());
}

export function linkToRetryUpgradePayment(
  subscriptionId: string = ":subscriptionId"
) {
  return linkToRetryPayment(linkToStudentWhatIsPremiumPage(), subscriptionId);
}

export { linkToStudentWhatIsPremiumPage };
