import { List, Map } from "immutable";

import {
  AccessorRole,
  AccessorRoleType,
  AccessorStatus,
  ClassAccessor,
  ClassType
} from "../../../../models";

export function getName(classRecord: ClassType): string | null {
  return classRecord?.name ?? null;
}

export function getEnrollments(
  classRecord: ClassType
): Map<string, ClassAccessor> {
  return classRecord.enrollments;
}

export function getEnrolleesUserIds(classRecord: ClassType): List<string> {
  return classRecord?.enrolleeUserIds ?? [];
}

export function getNumberOfStudents(classRecord: ClassType) {
  return classRecord?.numStudents ?? null;
}

export function getNumberOfStudentsWithParents(classRecord: ClassType): number {
  return getEnrollments(classRecord).filter(enrolment => enrolment.numParents)
    .size;
}

export function getNumberOfStudentsWithUnknownParents(
  classRecord: ClassType
): number {
  return getEnrollments(classRecord).filter(
    enrolment => enrolment.parentConnections?.size === 0
  ).size;
}

export function getNumberOfStudentsWithUnconnectedParents(
  classRecord: ClassType
): number {
  return getEnrollments(classRecord).filter(
    enrolment =>
      enrolment.numParents === null &&
      !!enrolment.parentConnections &&
      enrolment.parentConnections?.size > 0
  ).size;
}

export function getStudentNumberOfParents(
  classRecord: ClassType,
  userId: string
) {
  return getEnrollments(classRecord).find(
    enrolment => enrolment.userId === userId
  )?.numParents;
}

export function getTeachers(
  classRecord: ClassType
): Map<string, ClassAccessor> {
  return classRecord.teachers;
}

export function getSortedTeacherUserIds(classRecord: ClassType) {
  return classRecord.teachers
    .toList()
    .sort((a: any, b: any) => (a.status === AccessorStatus.active ? -1 : 0))
    .sort((a: any, b: any) => (a.role === AccessorRole.owner ? -1 : 0))
    .map(teacher => teacher.userId);
}

export function getTeacherUserIds(classRecord: ClassType): List<string> {
  return List(getTeachers(classRecord).keys());
}

export function getActiveTeachers(classRecord: ClassType): List<string> {
  return getTeacherUserIds(classRecord).filter((userId: string) =>
    getTeacherIsActive(classRecord, userId)
  );
}

export function getPendingTeachers(classRecord: ClassType): List<string> {
  return getTeacherUserIds(classRecord).filter((userId: string) =>
    getTeacherIsPending(classRecord, userId)
  );
}

export function getNumberOfTeachers(classRecord: ClassType) {
  return classRecord.numTeachers;
}

export function getNumberOfActiveTeachers(classRecord: ClassType): number {
  return getActiveTeachers(classRecord).size;
}

export function getNumberOfPendingTeachers(classRecord: ClassType): number {
  return getPendingTeachers(classRecord).size;
}

export function getTeacher(
  classRecord: ClassType,
  userId: string
): ClassAccessor | null | undefined {
  return getTeachers(classRecord).get(userId);
}

export function getTeacherRole(
  classRecord: ClassType,
  userId: string
): AccessorRoleType | null | undefined {
  const teacher = getTeacher(classRecord, userId);
  return teacher ? teacherRole(teacher) : null;
}

export function getTeacherIsClassOwner(
  classRecord: ClassType,
  userId: string
): boolean {
  const teacher = getTeacher(classRecord, userId);
  return !!teacher && teacherIsClassOwner(teacher);
}

export function getTeacherIsActive(
  classRecord: ClassType,
  userId: string
): boolean {
  const teacher = getTeacher(classRecord, userId);
  return !!teacher && teacherIsActive(teacher);
}

export function getTeacherIsPending(
  classRecord: ClassType,
  userId: string
): boolean {
  const teacher = getTeacher(classRecord, userId);
  return !!teacher && teacherIsPending(teacher);
}

export function getTeacherIsManaged(
  classRecord: ClassType,
  userId: string
): boolean {
  const teacher = getTeacher(classRecord, userId);
  return !!teacher && teacherIsManaged(teacher);
}

export function getIsTeacher(classRecord: ClassType, userId: string): boolean {
  const teacher = getTeacher(classRecord, userId);
  return !!teacher;
}

export function teacherRole(teacher: ClassAccessor) {
  return teacher.role;
}

export function teacherIsClassOwner(teacher: ClassAccessor) {
  return teacher.role === AccessorRole.owner;
}

export function teacherIsActive(teacher: ClassAccessor) {
  return teacher.status === AccessorStatus.active;
}

export function teacherIsPending(teacher: ClassAccessor) {
  return teacher.status === AccessorStatus.pending;
}

export function teacherIsManaged(teacher: ClassAccessor) {
  return !!teacher.managedBy;
}

export function getClassCourseIds(classRecord: ClassType) {
  return classRecord.courseIds;
}

export function getClassId(classRecord: ClassType) {
  return classRecord.id!;
}

export function getClassManagedBy(classRecord: ClassType) {
  return classRecord?.managedBy;
}

export function isClassHidden(classRecord: ClassType) {
  return !!classRecord?.hidden;
}

export function getClassParentInformationAvailable(classRecord: ClassType) {
  return classRecord.parentInformationAvailable;
}

export function classIsManaged(classRecord: ClassType) {
  return !!classRecord.managedBy;
}

export function getCourseIds(classRecord: ClassType): List<string> {
  return classRecord?.courseIds;
}

export function classHasCourse(
  classRecord: ClassType,
  courseId: string
): boolean {
  return classRecord.courseIds.includes(courseId);
}

export function getIsClassArchived(classRecord: ClassType): boolean {
  return classRecord ? !!classRecord.archived : false;
}

export function getMyTeacherAccessors(classRecord: ClassType) {
  return classRecord.myTeacherAccessors;
}

export function getMyTeacherAccessorTimeCreated(classRecord: ClassType) {
  const accessor = classRecord.myTeacherAccessors.get(0)!;
  return accessor.timeCreated!;
}

export function getMyTeacherAccessorLastViewed(classRecord: ClassType) {
  const accessor = classRecord.myTeacherAccessors.get(0);
  return accessor?.lastViewed ?? accessor?.timeCreated ?? "";
}

export function getUserIsClassOwner(classRecord: ClassType): boolean {
  const myTeacherAccessors = getMyTeacherAccessors(classRecord);
  return myTeacherAccessors.some(accessor => teacherIsClassOwner(accessor));
}

export function getSchoolSyncExpiryDate(classRecord: ClassType) {
  return classRecord?.schoolSyncExpiryDate;
}

export function getSchoolAccessRevoked(classRecord: ClassType) {
  return classRecord?.schoolAccessRevoked;
}

export function getYearGroup(classRecord: ClassType | undefined) {
  return classRecord?.yearGroup;
}

export function getTimeLastSynced(classRecord: ClassType) {
  return classRecord?.timeLastSynced;
}
