import { matchPath } from "react-router-dom-v5-compat";

import { Link } from "common/types/routing";

import { ROUTES } from "routing/routes";

export function isInTeacherPlatform(pathname: Link) {
  const stringpath =
    typeof pathname === "string" ? pathname : pathname.pathname;

  if (!stringpath) {
    return false;
  }

  return matchPath(`${ROUTES.TEACHER.buildPath({})}/*`, stringpath);
}
