import { List } from "immutable";

import { composeSelector } from "seneca-common/utils/selectors/compose-selectors";

import { Classes } from "../../models/classes";
import ClassStateSlice from "../../models/ClassStateSlice";
import ManageClasses from "../../models/manageClasses";
import ClassMetaStateSlice from "../../models/meta";
import * as classes from "./classes";
import * as manageClasses from "./manageClasses";
import * as meta from "./meta";

export function getClassesSlice(state: ClassStateSlice): Classes {
  return state.classes;
}

export function getManageClassesSlice(
  state: ClassMetaStateSlice
): ManageClasses {
  // @ts-ignore
  return state.manageClasses;
}

function getMetaSlice(state: ClassStateSlice): ClassMetaStateSlice {
  return state.meta;
}

export function getClassesMetaSlice(state: ClassStateSlice) {
  return meta.getClassesMetaSlice(getMetaSlice(state));
}

export function getManageUsersMetaSlice(state: ClassStateSlice) {
  return meta.getManageUsersMetaSlice(getMetaSlice(state));
}

export function getClassSkeletonsMetaSlice(state: ClassStateSlice) {
  return meta.getClassSkeletonsMetaSlice(getMetaSlice(state));
}

export const getSelectedClassIds = composeSelector(
  getManageClassesSlice,
  manageClasses.getSelectedClassIds
);

export const getActiveClasses = composeSelector(
  getClassesSlice,
  classes.getActiveClasses
);

export const getArchivedClasses = composeSelector(
  getClassesSlice,
  classes.getArchivedClasses
);

export function allClassesAreSelected(
  state: ClassStateSlice,
  classIds: List<string>
) {
  // @ts-ignore
  const selectedClassIds = getSelectedClassIds(state);
  return classIds.every(classId => selectedClassIds.includes(classId));
}
