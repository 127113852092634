import { List } from "immutable";

import getDecimalAsPercentage from "seneca-common/utils/functions/formatting/percentages/getDecimalAsPercentage";

export const PATH_TO_USER_COURSE_STATS_REVIEW_STATE = "userCourseStatsReview";
export const ENTITY_NAME = "userCourseStats";
export const ACTIONS_NAMESPACE = `seneca/statsReview/${ENTITY_NAME}/`;
export const META_IDS_FIELD_NAME = "statIds";

export type StatGroupAccessorType = {
  statGroupType: string;
  formatFunc?: (val: number) => number | string;
};

export const STAT_GROUPS_TO_EXPORT_TO_CSV = List<StatGroupAccessorType>([
  {
    statGroupType: "studyDuration",
    formatFunc: (val: number) => Number((val / 60).toFixed(1))
  },
  {
    statGroupType: "averageScore",
    formatFunc: getDecimalAsPercentage
  },
  {
    statGroupType: "sessionsCompleted"
  },
  {
    statGroupType: "sessionModulesCorrect"
  }
]);
