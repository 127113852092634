import { Map } from "immutable";

import { Action } from "common/types/redux";

import actionTypes from "../action-types";
import { receiveUser as receiveUserAction } from "../actions";
import User from "../models/User";
import { UsersState } from "../models/UsersState";
import userReducer from "./user";

export default function usersReducer(
  users: UsersState = Map<string, User>(),
  action: Action<any>
): UsersState {
  switch (action.type) {
    case actionTypes.RECEIVE_USER:
      return receiveUser(users, action);

    case actionTypes.RECEIVE_USERS:
      return receiveUsers(users, action);

    default:
      return users;
  }
}

function receiveUser(users: UsersState, action: Action<any>): UsersState {
  const receivedUser = action.payload;
  return users.update(receivedUser.id, (user: any) =>
    userReducer(user, action)
  );
}

function receiveUsers(users: UsersState, action: Action<any>): UsersState {
  const receivedUsers = action.payload;
  return users.withMutations((users: any) => {
    receivedUsers.forEach((user: any) => {
      receiveUser(users, receiveUserAction(user));
    });
  });
}
