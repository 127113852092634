import { useEffect, useRef } from "react";

export default function useEffectWhenPropBecomesTrue(
  effect: () => void,
  prop: any
) {
  const boolean = Boolean(prop);
  const prevBoolean = useRef(boolean);

  const effectDone = useRef(false);

  useEffect(() => {
    if (!effectDone.current) {
      if (prevBoolean.current === false && boolean === true) {
        effect();
        effectDone.current = true;
      }
    }

    prevBoolean.current = boolean;
  }, [effect, boolean]);
}
