import { Dispatch } from "common/types/redux";

import { getClient } from "services";

import { receiveClass } from "../state";
import { ClassReceived } from "./types";

const getClassServiceClient = () => getClient("classService");

type InviteRequest<T> = (classId: string) => Promise<T>;

export const fetchInvite: InviteRequest<{
  classId: string;
  className: string;
  inviterEmail: string;
}> = classId => getClassServiceClient().fetchInvite(classId) as any;

export const rejectInvite: InviteRequest<void> = classId =>
  getClassServiceClient().rejectInvite(classId);

export const acceptInvite: InviteRequest<ClassReceived> = classId =>
  getClassServiceClient().acceptInvite(classId) as any;

// @ts-ignore
export const transferClassOwnership: InviteRequest<ClassReceived> = (
  classId: string,
  userId: string
) => getClassServiceClient().transferClassOwnership(classId, userId);

// @ts-ignore
export const deleteTeacher: InviteRequest<ClassReceived | undefined> = (
  classId: string,
  userId: string
) => getClassServiceClient().batchDeleteTeachers(classId, [userId]);

export function inviteTeacher(classId: string, email: string) {
  return async (dispatch: Dispatch) => {
    try {
      const output = await getClassServiceClient().inviteTeacher(
        classId,
        email
      );

      dispatch(receiveClass(output as any));

      return output;
    } catch (err: any) {
      return err;
    }
  };
}
