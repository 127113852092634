import { combineReducers } from "redux-immutable";

import UserCourseStatsReviewState from "../models/UserCourseStatsReviewState";
import metaReducer from "./state/meta";
import statsReducer, { statsGroupedByDateRangesReducer } from "./state/stats";

export default combineReducers(
  {
    statsGroupedByDateRanges: statsGroupedByDateRangesReducer,
    stats: statsReducer,
    meta: metaReducer
  } as any,
  () => new UserCourseStatsReviewState() as any
);
