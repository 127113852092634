import ReducerRegistry from "seneca-common/utils/state/reducer-registry";

import { PATH_TO_USERS_STATE } from "./consts";
import usersReducer from "./reducers";
import * as usersSelectors from "./selectors/state";

const reducerRegistry = ReducerRegistry.getInstance();
reducerRegistry.initialise(PATH_TO_USERS_STATE, builder => {
  builder.register(PATH_TO_USERS_STATE, usersReducer);
});

// reducer
export { usersReducer, PATH_TO_USERS_STATE };

// models
export type { UsersState } from "./models/UsersState";
export { default as User } from "./models/User";
export type { UserType } from "./models/User";

// actions
export { receiveUsers, receiveUser } from "./actions";

// selectors
export {
  makeGetUsersNames,
  makeGetUsersNamesOrEmails,
  makeGetUsersDetails,
  getUsersStateSlice as getUsers,
  getUser,
  getUserNameOrEmail,
  getUserName,
  getUserEmail,
  getLastVisitTime,
  isUserAnonymous,
  getUserNameAsObject
} from "./selectors";
export { usersSelectors };
