import classnames from "classnames";
import * as React from "react";

import aHundredUrl from "common/assets/images/emojis/100.png";
import backpack from "common/assets/images/emojis/backpack.png";
import bicepUrl from "common/assets/images/emojis/biceps.png";
import books from "common/assets/images/emojis/books.png";
import brainUrl from "common/assets/images/emojis/brain.png";
import calendarUrl from "common/assets/images/emojis/calendar.png";
import chart from "common/assets/images/emojis/chart.png";
import clockUrl from "common/assets/images/emojis/clock.png";
import fastForwardUrl from "common/assets/images/emojis/fastforward.png";
import fireUrl from "common/assets/images/emojis/fire.png";
import ghostUrl from "common/assets/images/emojis/ghost.png";
import globe from "common/assets/images/emojis/globe.png";
import hourglassUrl from "common/assets/images/emojis/hourglass.png";
import house from "common/assets/images/emojis/house.png";
import lightningUrl from "common/assets/images/emojis/lightning.png";
import magnifyingGlass from "common/assets/images/emojis/magnifyingGlass.png";
import moneyUrl from "common/assets/images/emojis/money.png";
import rocket from "common/assets/images/emojis/rocket.png";
import starUrl from "common/assets/images/emojis/star.png";
import stopwatchUrl from "common/assets/images/emojis/stopwatch.png";
import sun from "common/assets/images/emojis/sun.png";
import tickUrl from "common/assets/images/emojis/tick.png";
import trophy from "common/assets/images/emojis/trophy.png";
import wave from "common/assets/images/emojis/wave.png";

import styles from "./Emoji.module.scss";

/**
 * Using images rather than unicode emojis is not the nicest, but
 * it has two key benifits:
 * 1) The images are exactly the across all devices
 *     a) Emojis look shit on windows
 *     b) Some emojis might not exist on some platforms, in which case you'd be left with a blank box
 * 2) Centering emojis is an absolute nightmare. Images are not.
 */

export type EmojiProps = {
  className?: string;
  alt?: string;
};

export function AHundredEmoji(props: EmojiProps) {
  return <Emoji {...props} url={aHundredUrl} label="A hundred emoji" />;
}

export function BicepEmoji(props: EmojiProps) {
  return <Emoji {...props} url={bicepUrl} label="Bicep emoji" />;
}

export function BrainEmoji(props: EmojiProps) {
  return <Emoji {...props} url={brainUrl} label="Brain emoji" />;
}

export function CalendarEmoji(props: EmojiProps) {
  return <Emoji {...props} url={calendarUrl} label="Calendar emoji" />;
}

export function ClockEmoji(props: EmojiProps) {
  return <Emoji {...props} url={clockUrl} label="Clock emoji" />;
}

export function FastForwardEmoji(props: EmojiProps) {
  return <Emoji {...props} url={fastForwardUrl} label="Fast forward emoji" />;
}

export function FireEmoji(props: EmojiProps) {
  return <Emoji {...props} url={fireUrl} label="Fire emoji" />;
}

export function GhostEmoji(props: EmojiProps) {
  return <Emoji {...props} url={ghostUrl} label="Ghost emoji" />;
}

export function HourglassEmoji(props: EmojiProps) {
  return <Emoji {...props} url={hourglassUrl} label="Hourglass emoji" />;
}

export function LightningEmoji(props: EmojiProps) {
  return <Emoji {...props} url={lightningUrl} label="Lightning emoji" />;
}

export function MoneyEmoji(props: EmojiProps) {
  return <Emoji {...props} url={moneyUrl} label="Money emoji" />;
}

export function StarEmoji(props: EmojiProps) {
  return <Emoji {...props} url={starUrl} label="Star emoji" />;
}

export function StopwatchEmoji(props: EmojiProps) {
  return <Emoji {...props} url={stopwatchUrl} label="Stopwatch emoji" />;
}

export function TickEmoji(props: EmojiProps) {
  return <Emoji {...props} url={tickUrl} label="Tick emoji" />;
}

export function MagnifyingGlassEmoji(props: EmojiProps) {
  return (
    <Emoji {...props} url={magnifyingGlass} label="Magnifying glass emoji" />
  );
}

export function BackpackEmoji(props: EmojiProps) {
  return <Emoji {...props} url={backpack} label="Backpack emoji" />;
}

export function BooksEmoji(props: EmojiProps) {
  return <Emoji {...props} url={books} label="Books emoji" />;
}

export function GlobeEmoji(props: EmojiProps) {
  return <Emoji {...props} url={globe} label="Globe emoji" />;
}

export function HouseEmoji(props: EmojiProps) {
  return <Emoji {...props} url={house} label="House emoji" />;
}

export function RocketEmoji(props: EmojiProps) {
  return <Emoji {...props} url={rocket} label="Rocket emoji" />;
}

export function SunEmoji(props: EmojiProps) {
  return <Emoji {...props} url={sun} label="Sun emoji" />;
}

export function ChartEmoji(props: EmojiProps) {
  return <Emoji {...props} url={chart} label="Chart emoji" />;
}

export function TrophyEmoji(props: EmojiProps) {
  return <Emoji {...props} url={trophy} label="Trophy emoji" />;
}

export function WaveEmoji(props: EmojiProps) {
  return <Emoji {...props} url={wave} label="Wave emoji" />;
}

type BaseEmojiProps = {
  url: string;
  label: string;
  className?: string;
  alt?: string;
};

export default function Emoji({ url, className, alt }: BaseEmojiProps) {
  return (
    <img
      src={url}
      alt={alt ? alt : ""}
      className={classnames(styles.emoji, className)}
    />
  );
}
