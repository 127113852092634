import { Dispatch, GetState } from "common/types";

import { getClient } from "services";
import authenticationServiceErrorTranslator from "services/authenticationService/errorTranslator";
import {
  reauthenticateWithPassword as reauthenticateFirebaseWithPassword,
  reauthenticateWithPopUp
} from "services/firebase/auth";
import firebaseErrorTranslator from "services/firebase/auth/errorTranslator";
import { ProviderSSO } from "services/firebase/auth/types";

import {
  getUserEmail,
  updateUserPersonalInformation as updateUserPersonalInformationLocally
} from "seneca-common/features/user/state";

import { getFirebaseEmail } from "../utils/email";

export function changeEmail(newEmail: string, password: string) {
  return async function (dispatch: Dispatch, getState: GetState) {
    try {
      const currentEmail = getUserEmail(getState());
      const firebaseEmail = await getFirebaseEmail(currentEmail!);
      await reauthenticateFirebaseWithPassword(firebaseEmail, password);
    } catch (err: any) {
      throw new Error(firebaseErrorTranslator(err));
    }

    try {
      await getClient("authenticationService").updateMyEmail(newEmail);
      dispatch(
        updateUserPersonalInformationLocally({
          email: newEmail
        })
      );
      await reauthenticateFirebaseWithPassword(newEmail, password);
    } catch (err: any) {
      throw new Error(authenticationServiceErrorTranslator(err));
    }
  };
}

export function changeDefaultAuthProvider(
  newEmail: string,
  newProvider: ProviderSSO
) {
  return async function (dispatch: Dispatch, getState: GetState) {
    try {
      await getClient("authenticationService").updateMyEmail(newEmail);
      dispatch(
        updateUserPersonalInformationLocally({
          email: newEmail
        })
      );
      await reauthenticateWithPopUp(newProvider);
    } catch (err: any) {
      throw new Error(authenticationServiceErrorTranslator(err));
    }
  };
}
