import { Dispatch, GetState } from "common/types/redux";

import {
  pushEnrichedEventToDataLayerWithCleanUp,
  pushEventToDataLayer
} from "seneca-common/features/analytics";
import {
  analyticsCategories,
  logGTMTrackingEvent,
  withLogAndSwallowError
} from "seneca-common/utils/senecaAnalytics";

import generateClassAnalyticsPayload from "./payload";

const namespace = "seneca/class";

export const analyticsLogCreateClass = withLogAndSwallowError(logCreateClass);

function logCreateClass(classId: string) {
  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    logGTMTrackingEvent(
      {
        category: analyticsCategories.CLASS,
        event: `${namespace}/CREATE_CLASS`,
        payload: generateClassAnalyticsPayload(state, classId)
      },
      state
    );
  };
}

export type CourseAddedLocation =
  | "class-add-courses"
  | "class-overview-promoted"
  | "class-courses-promoted"
  | "assignment-journey";

export const analyticsLogAddCoursesToClass =
  withLogAndSwallowError(logAddCoursesToClass);

function logAddCoursesToClass(
  classId: string,
  courseIds: string[],
  numberOfCoursesAdded: number,
  locationAdded: CourseAddedLocation | undefined
) {
  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    logGTMTrackingEvent(
      {
        category: analyticsCategories.CLASS,
        event: `${namespace}/ADD_COURSE_TO_CLASS`,
        payload: {
          courseIds,
          numberOfCoursesAdded,
          locationAdded,
          ...generateClassAnalyticsPayload(state, classId)
        }
      },
      state
    );
  };
}

export const analyticsLogUpdateClassSettings = withLogAndSwallowError(
  logUpdateClassSettings
);

function logUpdateClassSettings(classId: string) {
  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    logGTMTrackingEvent(
      {
        category: analyticsCategories.CLASS,
        event: `${namespace}/UPDATE_CLASS_SETTINGS`,
        payload: generateClassAnalyticsPayload(state, classId)
      },
      state
    );
  };
}

export const analyticsLogOpenInviteTeacherModal = withLogAndSwallowError(
  logOpenInviteTeacherModal
);

function logOpenInviteTeacherModal() {
  return (dispatch: Dispatch, getState: GetState) => {
    logGTMTrackingEvent(
      {
        category: analyticsCategories.CLASS,
        event: `${namespace}/OPEN_INVITE_TEACHER_MODAL`
      },
      getState()
    );
  };
}

export const teacherNamespace = "seneca/teacher";

export const analyticsLogDeleteClass = () => {
  pushEventToDataLayer({
    event: `${teacherNamespace}/deleteClass`
  });
};

export const analyticsLogDeleteStudents = withLogAndSwallowError(
  pushDeleteStudentsEventToDataLayer
);

function pushDeleteStudentsEventToDataLayer(numberOfStudentsDeleted: number) {
  pushEnrichedEventToDataLayerWithCleanUp({
    event: `${teacherNamespace}/deleteStudents`,
    payload: {
      numberOfStudentsDeleted
    }
  });
}

export const analyticsLogGradebookAssignmentNameClicked =
  withLogAndSwallowError(pushAssignmentNameClickedEventToDataLayer);

function pushAssignmentNameClickedEventToDataLayer() {
  pushEventToDataLayer({
    event: `${namespace}/gradebook_assignmentClick`
  });
}
