import { getAppName } from "features/common/utils/functions/app";

import {
  AuthDomainConfigRequiredError,
  EmailAlreadyExistsError,
  GoStudentSubscriptionInvalid,
  InaccessibleEmailError,
  IncorrectPasswordError,
  InvalidEmailError,
  InvalidPasswordError,
  InvalidResetPasswordMethod,
  InvalidSignInMethod,
  LimitExceededError,
  NetworkRequestFailedError,
  OperationNotSupportedInThisEnvironmentError,
  PlurallAccountExistsWithDifferentCredential,
  PopupBlocked,
  PopupClosedByUser,
  PopupRequestCancelled,
  UnauthorizedDomainError,
  UserDeniedPermission,
  UserNotFoundError
} from "./errors";

export const DEFAULT_MESSAGE = "An unknown error has occurred.";

export enum errorCodes {
  EMAIL_ALREADY_IN_USE = "EMAIL_ALREADY_IN_USE"
}

export default function errorTranslator(error: Error): string {
  if (error instanceof EmailAlreadyExistsError) {
    return errorCodes.EMAIL_ALREADY_IN_USE;
  }

  if (error instanceof IncorrectPasswordError) {
    return "Oops, wrong password! Have another go.";
  }

  if (error instanceof InvalidPasswordError) {
    return "Your password does not meet the requirements. Please try again.";
  }

  if (error instanceof UserNotFoundError) {
    return "We've not seen that email before, please sign up!";
  }

  if (error instanceof LimitExceededError) {
    return "Too many incorrect password attempts, please try again in a second!";
  }

  if (error instanceof NetworkRequestFailedError) {
    return "Looks like there is no internet connection.";
  }

  if (error instanceof InvalidEmailError) {
    return "Provided email is invalid. Try again.";
  }

  if (error instanceof InvalidSignInMethod) {
    if (error.provider)
      return `Looks like your account was created using a different method. Try logging in with ${error.provider}.`;
    else
      return "Looks like your account was created using a different method. Try another way of logging in.";
  }

  if (error instanceof InvalidResetPasswordMethod) {
    return `Looks like your account was created with ${error.provider}. No need to reset your password. Try logging in instead.`;
  }

  if (error instanceof InaccessibleEmailError) {
    return "To reset your email, please contact customer service.";
  }

  if (error instanceof PopupBlocked) {
    return "The popup is blocked by your browser. Please unblock pop ups.";
  }

  if (error instanceof PopupClosedByUser) {
    return "The popup has been closed before finalizing the connection, please try again.";
  }

  if (error instanceof PopupRequestCancelled) {
    return "Multiple popups triggered. Please close unused pop ups.";
  }

  if (error instanceof UserDeniedPermission) {
    const appName = getAppName();
    return `User denied access. Please grant permission to ${appName} to continue.`;
  }

  if (error instanceof AuthDomainConfigRequiredError) {
    return "Oops.. Looks like is something wrong with authentication domain configuration.";
  }

  if (error instanceof OperationNotSupportedInThisEnvironmentError) {
    return "The operation is not supported in the environment your application is running on";
  }

  if (error instanceof UnauthorizedDomainError) {
    return "The app domain is not authorized.";
  }

  if (error instanceof PlurallAccountExistsWithDifferentCredential) {
    return "Looks like an account with this email already exists, please contact Plurall support for any help.";
  }

  if (error instanceof GoStudentSubscriptionInvalid) {
    return "Your current plan does not allow you to access this resource.";
  }

  return DEFAULT_MESSAGE;
}

export function shouldLogError(error: Error): boolean {
  const expectedError = EXPECTED_ERRORS.some(
    errorType => error instanceof errorType
  );
  return !expectedError;
}

const EXPECTED_ERRORS = [
  EmailAlreadyExistsError,
  IncorrectPasswordError,
  UserNotFoundError,
  LimitExceededError,
  InvalidEmailError,
  InvalidSignInMethod,
  InvalidResetPasswordMethod,
  PopupClosedByUser,
  PopupRequestCancelled,
  UserDeniedPermission,
  PlurallAccountExistsWithDifferentCredential
];
