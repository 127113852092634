import { Dispatch } from "common/types";

import { getClient } from "services";
import errorHandler from "services/classService/errorHandler";

import { analyticsLogDeleteStudents } from "../analytics";
import {
  ClassId,
  deleteUsersFromClassActionFactory,
  receiveClass,
  UserId
} from "../state";

const getClassServiceClient = () => getClient("classService");

const deleteUsersFromClassAction = deleteUsersFromClassActionFactory({
  requestFunction: (classId, userIds) =>
    getClassServiceClient().batchDeleteStudents(classId, userIds),
  errorHandler
});

type DeleteUsersFromClassArgs = {
  classId: ClassId;
  userIds: UserId[];
  requestId: string;
  onDeleteSuccess?: () => void;
};

export function deleteUsersFromClass({
  classId,
  userIds,
  requestId,
  onDeleteSuccess
}: DeleteUsersFromClassArgs) {
  return async function (dispatch: Dispatch) {
    const response = await dispatch(
      deleteUsersFromClassAction([classId, userIds], requestId)
    );

    if (!(response instanceof Error)) {
      onDeleteSuccess && onDeleteSuccess();
      analyticsLogDeleteStudents(userIds.length);
      dispatch(receiveClass(response));
    }

    return response;
  };
}
