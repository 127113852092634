import { Record } from "immutable";

export type UserType = {
  id: string | null;
  name: string | null;
  email: string | null;
  givenName: string | null;
  familyName: string | null;
  anonymousUser?: boolean | null;
  managedBy?: string | null;
  lastVisitTime?: string | null;
};

export type OptionalUserType = Partial<UserType>;

const defautValues: UserType = {
  id: null,
  name: null,
  email: null,
  givenName: null,
  familyName: null,
  anonymousUser: null,
  managedBy: null,
  lastVisitTime: null
};

export default class User extends Record(defautValues) {}
