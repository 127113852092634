import {
  Stat,
  STAT_GROUPS,
  StatType
} from "seneca-common/features/stats-review/state";
import ReducerRegistry from "seneca-common/utils/state/reducer-registry";

import { PATH_TO_USER_COURSE_STATS_REVIEW_STATE } from "./consts";
import userCourseStatsReviewReducer from "./reducers";
import * as userCourseStatSelectors from "./selectors/state/stats/user-course-stat";

const reducerRegistry = ReducerRegistry.getInstance();
reducerRegistry.initialise(PATH_TO_USER_COURSE_STATS_REVIEW_STATE, builder => {
  builder.register(
    PATH_TO_USER_COURSE_STATS_REVIEW_STATE,
    userCourseStatsReviewReducer
  );
});

export { Stat, STAT_GROUPS };
export type { StatType };

// reducers
export { userCourseStatsReviewReducer };

// actions
export { receiveUserCourseStats } from "./actions";
export { fetchActionFactory } from "./actions/meta";

// models
export { default as UserCourseStat } from "./models/UserCourseStat";
export type { UserCourseStatType } from "./models/UserCourseStat";

// selectors
export {
  // stats
  // Get stat group for a user
  getAllTimeUserCourseStatGroup,
  getIntervalUserCourseStatGroup, // Gets all stat groups for many users
  makeGetUsersCourseStats,
  makeGetUserCoursesStats, // Gets stat group for many users
  makeGetUserCoursesGroupedStats,
  makeGetAllTimeUsersCourseStatGroups,
  makeGetIntervalUsersCourseStatGroups, // Gets stat group for user for many timestamps
  makeGetManyAllTimeUserCourseStatGroups,
  makeGetManyIntervalUserCourseStatGroups, // Gets sum/averages of stat group for many users
  makeGetAllTimeSumUsersCourseStatGroups,
  makeGetIntervalSumUsersCourseStatGroups,
  makeGetAllTimeAverageUsersCourseStatGroups,
  makeGetIntervalAverageUsersCourseStatGroups, // Gets sum/averages of all stat groups for many users
  makeGetAllTimeSumUsersCourseStats,
  makeGetIntervalSumUsersCourseStats,
  makeGetAverageUsersCourseStats, // Gets sum/averages of stat group for many users and many timestamps
  makeGetManyAllTimeSumUsersCourseStatGroups,
  makeGetManyIntervalSumUsersCourseStatGroups,
  makeGetManyAllTimeAverageUsersCourseStatGroups,
  makeGetManyIntervalAverageUsersCourseStatGroups, // meta
  hasStatFetchStarted,
  didStatFetchSucceed,
  didStatFetchError,
  getStatFetchErrorMessage,
  getStatFetchLastFetchedTime,
  hasIntervalStatFetchStarted,
  didIntervalStatFetchSucceed,
  didIntervalStatFetchError,
  hasIntervalStatFetchFinished,
  getIntervalStatFetchErrorMessage,
  getIntervalStatFetchLastFetchedTime,
  doesIntervalStatNeedFetching,
  haveStatsFetchStarted,
  didStatsFetchSucceed,
  didStatsFetchError,
  haveIntervalStatsFetchStarted,
  didIntervalStatsFetchSucceed,
  didIntervalStatsFetchError
} from "./selectors";
export { userCourseStatSelectors };

// utils
export { makeId, makeIds } from "./utils";
