import { List, Map } from "immutable";

import {
  makeGetManyStatGroupAverage,
  makeGetManyStatGroups,
  makeGetManyStatGroupSum,
  makeGetStatGroupAverage,
  makeGetStatGroupSum,
  makeGetStatsAverage,
  makeGetStatsMap,
  makeGetStatsSum,
  Stat,
  STAT_GROUPS,
  Timestamp
} from "seneca-common/features/stats-review/state";

import InvalidIntervalStatTimestamps from "../../../errors/InvalidIntervalStatTimestamps";
import UserCourseStat from "../../../models/UserCourseStat";
import { makeId, makeIds } from "../../../utils";

type State = Map<string, UserCourseStat>;
type UserId = string;
type StatId = string;
type StatValue = number;

// Gets all stat groups for many users
export const {
  allTimeSelector: makeGetAllTimeUsersCourseStats,
  intervalSelector: makeGetIntervalUsersCourseStats
} = composeAllGroupsUsersSelectorCreators();

// Gets stat group for many users
export const {
  allTimeSelector: makeGetAllTimeUsersCourseStatGroups,
  intervalSelector: makeGetIntervalUsersCourseStatGroups
} = composeUsersSelectorCreators();

// Gets sum/averages of stat group for many users
export const {
  allTimeSumSelector: makeGetAllTimeSumUsersCourseStatGroups,
  intervalSumSelector: makeGetIntervalSumUsersCourseStatGroups,
  allTimeAverageSelector: makeGetAllTimeAverageUsersCourseStatGroups,
  intervalAverageSelector: makeGetIntervalAverageUsersCourseStatGroups
} = composeCombinedUsersSelectorCreators(
  // @ts-ignore
  makeGetStatGroupSum,
  makeGetStatGroupAverage
);

// Gets sum/averages of all stat group for many users
export const {
  allTimeSumSelector: makeGetAllTimeSumUsersCourseStats,
  intervalSumSelector: makeGetIntervalSumUsersCourseStats,
  allTimeAverageSelector: makeGetAllTimeAverageUsersCourseStats,
  intervalAverageSelector: makeGetIntervalAverageUsersCourseStats
} = composeAllGroupsCombinedUsersSelectorCreators(
  // @ts-ignore
  makeGetStatsSum,
  makeGetStatsAverage
);

// Gets sum/averages of stat group for many users and many timestamps
export const {
  allTimeSumSelector: makeGetManyAllTimeSumUsersCourseStatGroups,
  intervalSumSelector: makeGetManyIntervalSumUsersCourseStatGroups,
  allTimeAverageSelector: makeGetManyAllTimeAverageUsersCourseStatGroups,
  intervalAverageSelector: makeGetManyIntervalAverageUsersCourseStatGroups
} = composeManyCombinedUsersSelectorCreators(
  // @ts-ignore
  makeGetManyStatGroupSum,
  makeGetManyStatGroupAverage
);

function composeAllGroupsUsersSelectorCreators() {
  return {
    allTimeSelector:
      // @ts-ignore
      composeAllTimeAllGroupsUsersSelectorCreator(makeGetStatsMap),
    intervalSelector:
      // @ts-ignore
      composeIntervalAllGroupsUsersSelectorCreator(makeGetStatsMap)
  };
}

function composeAllTimeAllGroupsUsersSelectorCreator(
  selectorCreator: (
    arg0: Map<UserId, StatId>
  ) => (arg0: State) => Map<UserId, UserCourseStat>
) {
  return (userIds: List<UserId>, courseId: string, endTime: Timestamp) => {
    const userIdsToStatIdsMap = userIds.reduce(
      (map, userId) =>
        map.set(userId, makeId(userId, courseId, undefined, endTime)),
      Map<UserId, StatId>()
    );
    return selectorCreator(userIdsToStatIdsMap);
  };
}

function composeIntervalAllGroupsUsersSelectorCreator(
  selectorCreator: (
    arg0: Map<UserId, StatId>
  ) => (arg0: State) => Map<UserId, UserCourseStat>
) {
  return (
    userIds: List<UserId>,
    courseId: string,
    startTime: Timestamp,
    endTime: Timestamp
  ) => {
    const userIdsToStatIdsMap = userIds.reduce(
      (map, userId) =>
        map.set(userId, makeId(userId, courseId, startTime, endTime)),
      Map<UserId, StatId>()
    );
    return selectorCreator(userIdsToStatIdsMap);
  };
}

function composeUsersSelectorCreators() {
  return {
    // @ts-ignore
    allTimeSelector: composeAllTimeUsersSelectorCreator(makeGetManyStatGroups),
    // @ts-ignore
    intervalSelector: composeIntervalUsersSelectorCreator(makeGetManyStatGroups)
  };
}

function composeAllTimeUsersSelectorCreator(
  selectorCreator: (
    arg0: Map<UserId, StatId>,
    arg1: STAT_GROUPS
  ) => (arg0: State) => Map<UserId, StatValue>
) {
  return (
    userIds: List<UserId>,
    courseId: string,
    endTime: Timestamp,
    statGroup: STAT_GROUPS
  ) => {
    const userIdsToStatIdsMap = userIds.reduce(
      (map, userId) =>
        map.set(userId, makeId(userId, courseId, undefined, endTime)),
      Map<UserId, StatId>()
    );
    return selectorCreator(userIdsToStatIdsMap, statGroup);
  };
}

function composeIntervalUsersSelectorCreator(
  selectorCreator: (
    arg0: Map<UserId, StatId>,
    arg1: STAT_GROUPS
  ) => (arg0: State) => Map<UserId, StatValue>
) {
  return (
    userIds: List<UserId>,
    courseId: string,
    startTime: Timestamp,
    endTime: Timestamp,
    statGroup: STAT_GROUPS
  ) => {
    const userIdsToStatIdsMap = userIds.reduce(
      (map, userId) =>
        map.set(userId, makeId(userId, courseId, startTime, endTime)),
      Map<UserId, StatId>()
    );
    return selectorCreator(userIdsToStatIdsMap, statGroup);
  };
}

function composeCombinedUsersSelectorCreators(
  sumSelectorCreator: (
    arg0: List<StatId>,
    arg1: STAT_GROUPS
  ) => (arg0: State) => StatValue,
  averageSelectorCreator: (
    arg0: List<StatId>,
    arg1: STAT_GROUPS
  ) => (arg0: State) => StatValue
) {
  return {
    allTimeSumSelector: composeCombinedUsersSelectorCreator(sumSelectorCreator),
    intervalSumSelector:
      composeIntervalCombinedUsersSelectorCreator(sumSelectorCreator),
    allTimeAverageSelector: composeCombinedUsersSelectorCreator(
      averageSelectorCreator
    ),
    intervalAverageSelector: composeIntervalCombinedUsersSelectorCreator(
      averageSelectorCreator
    )
  };
}

function composeCombinedUsersSelectorCreator(
  selectorCreator: (
    arg0: List<StatId>,
    arg1: STAT_GROUPS
  ) => (arg0: State) => StatValue
) {
  return (
    userIds: List<UserId>,
    courseId: string,
    endTime: Timestamp,
    statGroup: STAT_GROUPS
  ) =>
    selectorCreator(makeIds(userIds, courseId, undefined, endTime), statGroup);
}

function composeIntervalCombinedUsersSelectorCreator(
  selectorCreator: (
    arg0: List<StatId>,
    arg1: STAT_GROUPS
  ) => (arg0: State) => StatValue
) {
  return (
    userIds: List<UserId>,
    courseId: string,
    startTime: Timestamp,
    endTime: Timestamp,
    statGroup: STAT_GROUPS
  ) =>
    selectorCreator(makeIds(userIds, courseId, startTime, endTime), statGroup);
}

function composeAllGroupsCombinedUsersSelectorCreators(
  sumSelectorCreator: (arg0: List<StatId>) => (arg0: State) => Stat,
  averageSelectorCreator: (arg0: List<StatId>) => (arg0: State) => Stat
) {
  return {
    allTimeSumSelector:
      composeAllTimeAllGroupsCombinedUsersSelectorCreator(sumSelectorCreator),
    allTimeAverageSelector: composeAllTimeAllGroupsCombinedUsersSelectorCreator(
      averageSelectorCreator
    ),
    intervalSumSelector:
      composeIntervalAllGroupsCombinedUsersSelectorCreator(sumSelectorCreator),
    intervalAverageSelector:
      composeIntervalAllGroupsCombinedUsersSelectorCreator(
        averageSelectorCreator
      )
  };
}

function composeAllTimeAllGroupsCombinedUsersSelectorCreator(
  selectorCreator: (arg0: List<StatId>) => (arg0: State) => Stat
) {
  return (userIds: List<UserId>, courseId: string, endTime: Timestamp) => {
    const statIds = userIds.reduce(
      (statIds, userId) =>
        statIds.push(makeId(userId, courseId, undefined, endTime)),
      List<StatId>()
    );
    return selectorCreator(statIds);
  };
}

function composeIntervalAllGroupsCombinedUsersSelectorCreator(
  selectorCreator: (arg0: List<StatId>) => (arg0: State) => Stat
) {
  return (
    userIds: List<UserId>,
    courseId: string,
    startTime: Timestamp,
    endTime: Timestamp
  ) => {
    const statIds = userIds.reduce(
      (statIds, userId) =>
        statIds.push(makeId(userId, courseId, startTime, endTime)),
      List<StatId>()
    );
    return selectorCreator(statIds);
  };
}

function composeManyCombinedUsersSelectorCreators(
  sumSelectorCreator: (
    arg0: Map<Timestamp, List<StatId>>,
    arg1: STAT_GROUPS
  ) => (arg0: State) => Map<Timestamp, StatValue>,
  averageSelectorCreator: (
    arg0: Map<Timestamp, List<StatId>>,
    arg1: STAT_GROUPS
  ) => (arg0: State) => Map<Timestamp, StatValue>
) {
  return {
    allTimeSumSelector:
      composeManyAllTimeCombinedUsersSelectorCreator(sumSelectorCreator),
    intervalSumSelector:
      composeManyIntervalCombinedUsersSelectorCreator(sumSelectorCreator),
    allTimeAverageSelector: composeManyAllTimeCombinedUsersSelectorCreator(
      averageSelectorCreator
    ),
    intervalAverageSelector: composeManyIntervalCombinedUsersSelectorCreator(
      averageSelectorCreator
    )
  };
}

function composeManyAllTimeCombinedUsersSelectorCreator(
  selectorCreator: (
    arg0: Map<Timestamp, List<StatId>>,
    arg1: STAT_GROUPS
  ) => (arg0: State) => Map<Timestamp, StatValue>
) {
  return (
    userIds: List<UserId>,
    courseId: string,
    endTimes: List<Timestamp>,
    statGroup: STAT_GROUPS
  ) => {
    const timestampsToStatIdsMap = endTimes.reduce(
      (map, endTime) =>
        map.set(endTime, makeIds(userIds, courseId, undefined, endTime)),
      Map<Timestamp, List<StatId>>()
    );
    return selectorCreator(timestampsToStatIdsMap, statGroup);
  };
}

function composeManyIntervalCombinedUsersSelectorCreator(
  selectorCreator: (
    arg0: Map<Timestamp, List<StatId>>,
    arg1: STAT_GROUPS
  ) => (arg0: State) => Map<Timestamp, StatValue>
) {
  return (
    userIds: List<UserId>,
    courseId: string,
    startTimes: List<Timestamp>,
    endTimes: List<Timestamp>,
    statGroup: STAT_GROUPS
  ) => {
    if (startTimes.size !== endTimes.size) {
      throw new InvalidIntervalStatTimestamps(startTimes, endTimes);
    }

    const timestampsToStatIdsMap = endTimes.reduce(
      (map, endTime, index) =>
        map.set(
          endTime,
          makeIds(userIds, courseId, startTimes.get(index), endTime)
        ),
      Map<Timestamp, List<StatId>>()
    );
    return selectorCreator(timestampsToStatIdsMap, statGroup);
  };
}
