import { combineReducers } from "redux-immutable";

import { ClassMetaStateSlice } from "../../../models";
import classesMetaReducer from "./classes";
import classSkeletonsMetaReducer from "./classSkeletons";
import manageUsersMetaReducer from "./manageUsers";

export default combineReducers(
  {
    classes: classesMetaReducer,
    classSkeletons: classSkeletonsMetaReducer,
    manageUsers: manageUsersMetaReducer
  },
  () => new ClassMetaStateSlice() as any
);
