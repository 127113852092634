import { Dispatch, GetState } from "common/types";

import actionTypes from "../action-types/sidebarUI";
import { getSidebarOpen } from "../selectors";

export function toggleSidebar() {
  return (dispatch: Dispatch, getState: GetState) => {
    if (getSidebarOpen(getState())) {
      dispatch(closeSidebar());
    } else {
      dispatch(openSidebar());
    }
  };
}

export function closeSidebarIfOpen() {
  return (dispatch: Dispatch, getState: GetState) => {
    if (getSidebarOpen(getState())) {
      dispatch(closeSidebar());
    }
  };
}

export function openSidebar() {
  return {
    type: actionTypes.OPEN_SIDEBAR
  };
}

export function closeSidebar() {
  return {
    type: actionTypes.CLOSE_SIDEBAR
  };
}

export function expandAssignment(assignmentId: string) {
  return {
    type: actionTypes.EXPAND_ASSIGNMENT,
    payload: {
      assignmentId
    }
  };
}

export function collapseAssignment() {
  return {
    type: actionTypes.COLLAPSE_ASSIGNMENT
  };
}

export function expandSection(sectionId: string) {
  return {
    type: actionTypes.EXPAND_SECTION,
    payload: {
      sectionId
    }
  };
}

export function expandSections(sectionIds: string[]) {
  return {
    type: actionTypes.EXPAND_SECTIONS,
    payload: {
      sectionIds
    }
  };
}

export function collapseSection(sectionId: string) {
  return {
    type: actionTypes.COLLAPSE_SECTION,
    payload: {
      sectionId
    }
  };
}

export function setActiveTab(id: number) {
  return {
    type: actionTypes.SET_ACTIVE_TAB,
    payload: {
      id
    }
  };
}
