import { combineReducers } from "redux-immutable";

import { ClassStateSlice } from "../models";
import classesReducer from "./state/classes";
import manageClassesReducer from "./state/manageClasses";
import classesMetaReducer from "./state/meta";

export default combineReducers(
  {
    classes: classesReducer,
    manageClasses: manageClassesReducer,
    meta: classesMetaReducer
  },
  () => new ClassStateSlice() as any
);
