import { Map } from "immutable";

import { ActionWithPayload as Action } from "common/types/redux";

import {
  ClassAccessorReceived,
  ClassSkeletonsReceived
} from "../../../../service/types";
import actionTypes from "../../../action-types";
import {
  receiveClass as receiveClassAction,
  updateClass as updateClassAction
} from "../../../actions";
import { Classes, ClassRecord } from "../../../models";
import { ClassId } from "../../../types";
import classReducer from "./class";

export default function classesReducer(
  classes: Classes = Map<string, ClassRecord>(),
  action: Action<
    any & {
      classId: ClassId;
    }
  >
) {
  const { classId } = action.payload || {};

  switch (action.type) {
    case actionTypes.RECEIVE_CLASS_SKELETONS:
      return receiveClasses(classes, action);

    case actionTypes.RECEIVE_CLASS:
      return callClassReducer(classes, action, classId);

    case actionTypes.UPDATE_CLASS_DETAILS:
      return callClassReducer(classes, action, classId);

    case actionTypes.DELETE_USERS_FROM_CLASS:
      return callClassReducer(classes, action, classId);

    case actionTypes.UPDATE_CLASS_NAME:
      return callClassReducer(classes, action, classId);

    case actionTypes.RECEIVE_TEACHER_ACCESSORS:
      return receiveAccessors(classes, action);

    case actionTypes.LEAVE_CLASS:
    case actionTypes.DELETE_CLASS:
      return classes.delete(classId);

    default:
      return classes;
  }
}

function callClassReducer(
  classes: Classes,
  action: Action<any>,
  classId: ClassId
): Classes {
  return classes.update(classId, classRecord =>
    classReducer(classRecord, action)
  );
}

function receiveClasses(
  classes: Classes,
  action: Action<ClassSkeletonsReceived>
): Classes {
  const receivedClasses: ClassSkeletonsReceived = action.payload;

  return classes.withMutations(classes => {
    receivedClasses.forEach(skeletonReceived => {
      const { classId, ...restOfClass } = skeletonReceived;

      if (classes.get(classId)) {
        callClassReducer(classes, updateClassAction(restOfClass), classId);
      } else {
        callClassReducer(
          classes,
          receiveClassAction(skeletonReceived),
          classId
        );
      }
    });
  });
}

function receiveAccessors(
  classes: Classes,
  action: Action<ClassAccessorReceived[]>
): Classes {
  const receivedClasses = action.payload;

  return classes.withMutations(classes => {
    receivedClasses.forEach(receivedAccessor => {
      const { classId, ...accessor } = receivedAccessor;
      if (classes.get(classId)) {
        callClassReducer(classes, updateClassAction(accessor), classId);
      }
    });
  });
}
