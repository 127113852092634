import { Map, Record } from "immutable";

import {
  CrudBuilderState,
  makeCrudBuilderInitialState
} from "seneca-common/utils/apiStates";

import {
  classesCrudStateConfig,
  classSkeletonsCrudStateConfig,
  manageClassUsersCrudStateConfig
} from "../../consts";

export type ClassMetaStateSliceType = {
  classes: CrudBuilderState | Map<any, any>;
  classSkeletons: CrudBuilderState | Map<any, any>;
  manageUsers: CrudBuilderState | Map<any, any>;
};

const classMetaInitialState = makeCrudBuilderInitialState(
  classesCrudStateConfig
);
const classSkeletonsMetaInitialState = makeCrudBuilderInitialState(
  classSkeletonsCrudStateConfig
);
const manageUserMetaInitialState = makeCrudBuilderInitialState(
  manageClassUsersCrudStateConfig
);

const defaultClassMetaStateSlice: ClassMetaStateSliceType = {
  classes: classMetaInitialState,
  classSkeletons: classSkeletonsMetaInitialState,
  manageUsers: manageUserMetaInitialState
};

export default class ClassMetaStateSlice extends Record(
  defaultClassMetaStateSlice
) {}
