import { List, Map } from "immutable";

import { ActionWithPayload as Action } from "common/types/redux";

import {
  ClassAccessorReceived,
  ClassReceived
} from "../../../../../service/types";
import actionTypes from "../../../../action-types";
import { DeleteUsersFromClassPayload } from "../../../../actions";
import { ClassAccessor, ClassRecord } from "../../../../models";

export default function classReducer(
  classRecord: ClassRecord = new ClassRecord(),
  action: Action<any>
) {
  switch (action.type) {
    case actionTypes.RECEIVE_CLASS:
      return receiveClass(classRecord, action);

    case actionTypes.UPDATE_CLASS_DETAILS:
      return updateClass(classRecord, action);

    case actionTypes.DELETE_USERS_FROM_CLASS:
      return deleteUsersFromClass(classRecord, action);

    default:
      return classRecord;
  }
}

function receiveClass(
  classRecord: ClassRecord,
  action: Action<Partial<ClassReceived>>
): ClassRecord {
  const {
    userId,
    classId,
    courseIds,
    enrollments,
    teachers,
    myTeacherAccessors,
    ...restOfClass
  } = action.payload;

  return new ClassRecord({
    ...restOfClass,
    id: classId,
    ownerId: userId,
    courseIds: List(courseIds?.filter(courseId => !!courseId) || []),
    enrollments: enrollments ? convertAccessorsToMap(enrollments) : undefined,
    enrolleeUserIds: enrollments
      ? List(enrollments.map((e: any) => e.userId))
      : undefined,
    teachers: teachers ? convertAccessorsToMap(teachers) : undefined,
    myTeacherAccessors: myTeacherAccessors
      ? List(
          myTeacherAccessors.map(accessor => new ClassAccessor(accessor as any))
        )
      : undefined,
    hidden: myTeacherAccessors
      ? myTeacherAccessors.find((accessor: any) => accessor.hidden)?.hidden
      : undefined
  });
}

function updateClass(
  classRecord: ClassRecord,
  action: Action<Partial<ClassReceived & { hidden?: boolean }>>
) {
  // @ts-ignore
  const {
    enrollments,
    teachers,
    name,
    ownerId,
    numTeachers,
    numStudents,
    managedBy,
    archived,
    hidden,
    courseIds
  } = action.payload;
  const updates: any = {};

  if (enrollments) {
    updates.enrollments = convertAccessorsToMap(enrollments);
    updates.enrolleeUserIds = List(enrollments.map((e: any) => e.userId));
  }

  if (teachers) {
    updates.teachers = convertAccessorsToMap(teachers);
  }

  if (name) updates.name = name;
  if (ownerId) updates.ownerId = ownerId;
  if (numTeachers) updates.numTeachers = numTeachers;
  if (numStudents) updates.numStudents = numStudents;
  if (managedBy) updates.managedBy = managedBy;
  if (archived) updates.archived = archived;
  if (action.payload.hasOwnProperty("archived")) updates.archived = archived;
  if (action.payload.hasOwnProperty("hidden")) updates.hidden = hidden;
  if (courseIds) updates.courseIds = classRecord.courseIds.concat(courseIds);

  return classRecord.merge(updates);
}

function convertAccessorsToMap(accessors: ClassAccessorReceived[]) {
  return accessors.reduce(
    (map, val) =>
      map.set(
        val.userId,
        new ClassAccessor({
          ...val,
          parentConnections: val.parentConnections
            ? List.of(...val.parentConnections)
            : null
        })
      ),
    Map<string, ClassAccessor>()
  );
}

function deleteUsersFromClass(
  classDetails: ClassRecord,
  action: Action<DeleteUsersFromClassPayload>
): ClassRecord {
  const { users } = action.payload;
  const usersToDeleteList = List(users);
  const newEnrollments = classDetails
    .get("enrollments")
    .filter((_, userId) => !usersToDeleteList.includes(userId));

  const newEnrolleeUserIds = classDetails.enrolleeUserIds.filter(
    oldUserId => !usersToDeleteList.includes(oldUserId)
  );

  return classDetails.merge({
    enrollments: newEnrollments,
    numStudents: newEnrollments.size,
    enrolleeUserIds: newEnrolleeUserIds
  });
}
