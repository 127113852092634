import { List, Map } from "immutable";
import { createSelector, defaultMemoize } from "reselect";

import { composeSelector } from "seneca-common/utils/selectors/compose-selectors";

import User from "../../models/User";
import { UsersState } from "../../models/UsersState";
import * as userSelectors from "./user";

export type UserName = {
  givenName: string;
  familyName: string | null | undefined;
};

function makeGetSomeUsers(userIds: List<string>) {
  return defaultMemoize((users: any) => getSomeUsers(users, userIds));
}

function getSomeUsers(
  users: UsersState,
  userIds: List<string>
): Map<string, User> {
  return userIds.reduce(
    (reduction, userId) => reduction.set(userId, getUser(users, userId)!),
    Map<string, User>()
  );
}

export function getUser(users: UsersState, userId: string) {
  return users.get(userId);
}

// @ts-ignore
export const getUserName: (users: UsersState, userId: string) => string =
  // @ts-ignore
  composeSelector(getUser, userSelectors.getUserName);

export const getUserEmail: (users: UsersState, userId: string) => string =
  // @ts-ignore
  composeSelector(getUser, userSelectors.getEmail);

// @ts-ignore
export const getUserNameOrEmail: (users: UsersState, userId: string) => string =
  // @ts-ignore
  composeSelector(getUser, userSelectors.getUserNameOrEmail);

// @ts-ignore
export const getLastVisitTime: (users: UsersState, userId: string) => string =
  // @ts-ignore
  composeSelector(getUser, userSelectors.getLastVisitTime);

export const getUserNameWithEmailOrEmail: (
  users: UsersState,
  userId: string
) => string = composeSelector(
  // @ts-ignore
  getUser,
  userSelectors.getUserNameWithEmailOrEmail
);

export function makeGetUsersNames(
  userIds: List<string>
): (arg0: UsersState) => Map<string, string> {
  const getUsers = makeGetSomeUsers(userIds);
  // @ts-ignore
  return createSelector(getUsers, getUsersNames);
}

export function makeGetUsersNamesOrEmails(
  userIds: List<string>
): (arg0: UsersState) => Map<string, UserName> {
  const getUsers = makeGetSomeUsers(userIds);
  return createSelector(getUsers, getUsersNamesOrEmail);
}

export function makeGetUsersDetails(
  userIds: List<string>
): (arg0: UsersState) => Map<string, UserName> {
  const getUsers = makeGetSomeUsers(userIds);
  return createSelector(getUsers, getUsersDetails);
}

function getUsersNames(users: UsersState) {
  return users.map(userSelectors.getUserName);
}

export function getUserNameAsObject(user?: User): UserName {
  return {
    givenName: userSelectors.getFirstNameOrEmail(user),
    familyName: userSelectors.getFamilyName(user)
  };
}

export function getUsersNamesOrEmail(users: UsersState): Map<string, UserName> {
  return users.map(getUserNameAsObject);
}

function getUserDetailsAsObject(user: User) {
  return {
    userId: userSelectors.getUserId(user),
    givenName: userSelectors.getFirstNameOrEmail(user),
    familyName: userSelectors.getFamilyName(user) || null,
    name: userSelectors.getUserName(user),
    email: userSelectors.getEmail(user),
    lastVisitTime: userSelectors.getLastVisitTime(user)
  };
}

function getUsersDetails(users: UsersState): Map<string, UserName> {
  return users.map(getUserDetailsAsObject);
}

export const isUserAnonymous: (
  users: UsersState,
  userId: string
) => boolean | undefined | null =
  // @ts-ignore
  composeSelector(getUser, userSelectors.isAnonymousUser);

export const getUserManagedBy: (
  users: UsersState,
  userId: string
) => string | undefined | null =
  // @ts-ignore
  composeSelector(getUser, userSelectors.getUserManagedBy);
