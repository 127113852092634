import { Action } from "common/types/redux";

import actionTypes from "../action-types";
import User, { UserType } from "../models/User";

export default function userReducer(
  user: User = new User(),
  action: Action<UserType>
): User {
  switch (action.type) {
    case actionTypes.RECEIVE_USER:
      return new User(action.payload);

    default:
      return user;
  }
}
