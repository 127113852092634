import { ActionWithPayload as Action, Reducer } from "common/types/redux";

import {
  receiveUser as receiveUserAction,
  User,
  UsersState
} from "seneca-common/features/users/state";

import actionTypes from "../action-types";

const usersReducerWrapper =
  (usersReducer: Reducer<UsersState>) =>
  (users: UsersState, action: Action<any>) => {
    switch (action.type) {
      case actionTypes.RECEIVE_CLASS:
        return receiveClass(usersReducer, users, action);

      default:
        return usersReducer(users, action);
    }
  };

function receiveClass(
  usersReducer: Reducer<UsersState>,
  users: UsersState,
  action: Action<any>
): UsersState {
  const { enrollments = [], teachers = [] } = action.payload;
  return users.withMutations((users: any) => {
    [...teachers, ...enrollments].forEach((enrollment: any) => {
      usersReducer(
        users,
        receiveUserAction(
          new User({
            id: enrollment.userId,
            name: enrollment.name,
            email: enrollment.email,
            givenName: enrollment.givenName,
            familyName: enrollment.familyName,
            anonymousUser: enrollment.meta.anonymousUser,
            lastVisitTime: enrollment.lastVisitTime
          })
        )
      );
    });
  });
}

export default usersReducerWrapper;
