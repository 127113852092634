import { List, Record } from "immutable";

export type SignInStateType = {
  signedIn: boolean;
  signInTime: string | null | undefined;
  signedInAsGuest: boolean;
  signInAsGuestTime: string | null | undefined;
  signingIn: boolean;
  signingOut: boolean;
  error: boolean;
  hasSignedInBefore: boolean;
  isFirstSession: boolean;
  refreshHistory: List<{ refreshedAt: string; expiration: string }>;
  refreshCredentialsError: boolean;
};

export type OptionalSignInStateType = Partial<SignInStateType>;

const defaultValues: SignInStateType = {
  signedIn: false,
  signInTime: null,
  signedInAsGuest: false,
  signInAsGuestTime: null,
  signingIn: false,
  signingOut: false,
  error: false,
  hasSignedInBefore: false,
  isFirstSession: false,
  refreshHistory: List(),
  refreshCredentialsError: false
};

export default class SignInState extends Record(defaultValues) {}
