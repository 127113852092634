import { TransactionContext } from "@sentry/types";
import { matchPath } from "react-router-dom";

import { routesConfig } from "./routesConfig";
import { shouldSampleLandingPage } from "./sampleTopLandingPages";

const samplingPerFeature = {
  classroom: 0.0001,
  dashboard: 0.0003,
  teacher: 0.02,
  parent: 0.015
};

function shouldSampleByFeature(context: TransactionContext, random: number) {
  const currentPath = context.name;

  if (currentPath.match("/classroom")) {
    return samplingPerFeature.classroom > random;
  }
  if (currentPath.match("/dashboard")) {
    return samplingPerFeature.dashboard > random;
  }
  if (currentPath.match("/teacher")) {
    return samplingPerFeature.teacher > random;
  }
  if (currentPath.match("/parent")) {
    return samplingPerFeature.parent > random;
  }
  return false;
}

function shouldSampleByRoute(context: TransactionContext, random: number) {
  const shouldSampleRoute = routesConfig.some(route => {
    return matchPath(context.name, {
      path: route.path,
      exact: true
    });
  });

  return shouldSampleRoute;
}

export function customTracesSampler(context: TransactionContext) {
  const random = Math.random();

  if (random > samplingPerFeature.parent) return false;

  return (
    shouldSampleLandingPage(context, random) ||
    (shouldSampleByRoute(context, random) &&
      shouldSampleByFeature(context, random))
  );
}
