import { CrudBuilderState } from "seneca-common/utils/apiStates";

import { ClassMetaStateSlice } from "../../../models";

export function getClassesMetaSlice(
  metaSlice: ClassMetaStateSlice
): CrudBuilderState {
  return metaSlice.get("classes") as unknown as CrudBuilderState;
}

export function getClassSkeletonsMetaSlice(
  metaSlice: ClassMetaStateSlice
): CrudBuilderState {
  return metaSlice.get("classSkeletons") as unknown as CrudBuilderState;
}

export function getManageUsersMetaSlice(
  metaSlice: ClassMetaStateSlice
): CrudBuilderState {
  return metaSlice.get("manageUsers") as unknown as CrudBuilderState;
}
