import { DefaultError, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import { getUserId } from "seneca-common/features/user/state";

import { saveSelectedSchool } from "./persistence";
import { fetchSelectedSchoolQueryOptions } from "./queries";
import { SelectedSchool } from "./types";

export function useSelectedSchool<DataT = SelectedSchool>(
  opts: Pick<
    UseQueryOptions<SelectedSchool | null, DefaultError, DataT>,
    "enabled" | "select"
  > & { includeParentInfo?: boolean } = { includeParentInfo: false }
) {
  return useQuery(fetchSelectedSchoolQueryOptions<DataT>(opts));
}

export function useSaveSelectedSchool() {
  const userId = useSelector(getUserId);

  return async (schoolId: string) => {
    if (userId) {
      await saveSelectedSchool({ userId, schoolId });
      // TODO: evaluate if we need to reload the page here or if clearing the query cache and redux is enough
      window.location.reload();
    }
  };
}
