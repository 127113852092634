import { Map } from "immutable";

import { Action } from "common/types/redux";

import { CourseStatsFromServerType } from "seneca-common/features/stats-review/service";
import StatsGroupedByDateRangesModel, {
  StatsGroupedByDateRangesType
} from "seneca-common/features/stats-review/state/models/StatsGroupedByDateRanges";

import actionTypes from "../../../action-types";
import UserCourseStat from "../../../models/UserCourseStat";
import { makeId } from "../../../utils";

export default function statsReducer(
  stats = Map<string, UserCourseStat>(),
  action: Action<any>
) {
  if (action.type === actionTypes.RECEIVE_USER_COURSE_STATS) {
    return receiveUserCourseStats(stats, action.payload.userCourseStats);
  } else {
    return stats;
  }
}

function receiveUserCourseStats(
  stats: Map<string, UserCourseStat>,
  receivedStats: CourseStatsFromServerType
) {
  const newStats: Map<string, UserCourseStat> = receivedStats.reduce(
    (newStats: any, stat: any) => {
      const { userId, courseId, startTime, endTime } = stat;
      const statId = makeId(userId, courseId, startTime, endTime);

      return newStats.set(
        statId,
        new UserCourseStat({
          ...stat,
          id: statId,
          userId,
          courseId,
          startTime,
          endTime
        })
      );
    },
    Map<string, UserCourseStat>()
  );

  return stats.merge(newStats);
}

export function statsGroupedByDateRangesReducer(
  stats = Map<string, StatsGroupedByDateRangesType>(),
  action: Action<any>
) {
  if (action.type === actionTypes.RECEIVE_USER_COURSE_STATS) {
    return receiveUserCourseStatsByDateRanges(
      stats,
      action.payload.groupedStatsByDateRanges,
      action.payload.userId
    );
  } else {
    return stats;
  }
}

function receiveUserCourseStatsByDateRanges(
  stats: Map<string, StatsGroupedByDateRangesType>,
  receivedStats: StatsGroupedByDateRangesType,
  userId: string
) {
  return stats.set(userId, new StatsGroupedByDateRangesModel(receivedStats));
}
