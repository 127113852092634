import { List, Map } from "immutable";

import sortDateDescending from "seneca-common/utils/functions/sorting/sortDateDescending";
import sortStringAscendingOrder from "seneca-common/utils/functions/sorting/sortStringAscendingOrder";
import { composeSelector } from "seneca-common/utils/selectors/compose-selectors";

import { Classes, ClassRecord, ClassType } from "../../../models";
import * as classSelectors from "./class";

export function getClassDetails(classes: Classes, classId: string) {
  return classes.get(classId);
}

export const getClassName: (
  arg0: Classes,
  arg1: string
) => string | null | undefined = composeSelector(
  // @ts-ignore
  getClassDetails,
  classSelectors.getName
);

export const getClassManagedBy: (
  arg0: Classes,
  arg1: string
) => string | null | undefined = composeSelector(
  // @ts-ignore
  getClassDetails,
  classSelectors.getClassManagedBy
);

export const getIsClassHidden: (state: Classes, classId: string) => boolean =
  // @ts-ignore
  composeSelector(getClassDetails, classSelectors.isClassHidden);

export const getSchoolSyncExpiryDate: (
  arg0: Classes,
  arg1: string
) => string | undefined = composeSelector(
  // @ts-ignore
  getClassDetails,
  classSelectors.getSchoolSyncExpiryDate
);

export const getSchoolAccessRevoked: (
  arg0: Classes,
  arg1: string
) => boolean | undefined = composeSelector(
  // @ts-ignore
  getClassDetails,
  classSelectors.getSchoolAccessRevoked
);

export const getYearGroup: (
  classes: Classes,
  classId: string
) => number | undefined = composeSelector(
  getClassDetails,
  classSelectors.getYearGroup
);

export function doesClassExist(classes: Classes, classId: string): boolean {
  return !!getClassDetails(classes, classId);
}

export function getAllClassIds(classes: Classes) {
  return classes.toList().map(classSelectors.getClassId);
}

export function getAllClassesSortedAlphabeticallyByName(
  classes: Classes
): List<ClassType> {
  return classes
    .toList()
    .sortBy(classSelectors.getName, sortStringAscendingOrder);
}

export function getAllClassesSortedAlphabeticallyByNameAndIsManaged(
  classes: Classes
): List<ClassType> {
  return classes
    .toList()
    .sortBy(classSelectors.getName, sortStringAscendingOrder)
    .sortBy(
      classSelectors.classIsManaged,
      // @ts-ignore
      (isManagedA, isManagedB) => !isManagedA && isManagedB
    );
}

export function getAllClassesSortedByCreationDate(
  classes: Classes
): List<ClassType> {
  return classes
    .toList()
    .sortBy(classSelectors.getMyTeacherAccessorTimeCreated, sortDateDescending);
}

export function getAllClassesSortedByLastViewed(
  classes: Classes
): List<ClassType> {
  return classes
    .toList()
    .sortBy(classSelectors.getMyTeacherAccessorLastViewed, sortDateDescending);
}

export function getAllClassIdsSortedAlphabeticallyByName(classes: Classes) {
  return getAllClassesSortedAlphabeticallyByName(classes).map(
    classSelectors.getClassId
  );
}

export function getActiveClassIdsContainingCourse(
  classes: Classes,
  courseId: string
) {
  return getActiveClasses(classes)
    .filter(classRecord => classSelectors.classHasCourse(classRecord, courseId))
    .map(classSelectors.getClassId) as List<string>;
}

export function getSimilarFilteredActiveClassIds(
  classes: Classes,
  courseId: string | undefined,
  yearGroups: (number | undefined)[]
) {
  return getActiveClasses(classes).reduce(
    (
      acc: {
        yearGroupAndCourse: string[];
        yearGroupOnly: string[];
        courseOnly: string[];
        rest: string[];
      },
      classRecord
    ) => {
      const classId = classSelectors.getClassId(classRecord);
      if (!classId) return acc;

      const classYearGroup = classSelectors.getYearGroup(classRecord);
      const isInYearGroup =
        classYearGroup && yearGroups.includes(classYearGroup);
      const hasCourse =
        !!courseId && classSelectors.classHasCourse(classRecord, courseId);

      if (isInYearGroup && hasCourse) {
        acc.yearGroupAndCourse.push(classId);
        return acc;
      } else if (isInYearGroup) {
        acc.yearGroupOnly.push(classId);
        return acc;
      } else if (hasCourse) {
        acc.courseOnly.push(classId);
        return acc;
      }

      acc.rest.push(classId);
      return acc;
    },
    {
      yearGroupAndCourse: [],
      yearGroupOnly: [],
      courseOnly: [],
      rest: []
    }
  );
}
export function getClassNamesForClassIds(classes: Classes, classIds: string[]) {
  return classes
    .toList()
    .filter(classRecord =>
      classIds.includes(classSelectors.getClassId(classRecord)!)
    )
    .reduce(
      (idNameMap, classRecord) =>
        idNameMap.set(classRecord.id!, classSelectors.getName(classRecord)),
      Map<string, string | null | undefined>()
    );
}

export const getIsClassArchived: (arg0: Classes, arg1: string) => boolean =
  // @ts-ignore
  composeSelector(getClassDetails, classSelectors.getIsClassArchived);

export function getArchivedClasses(classes: Classes): List<ClassRecord> {
  return classes.toList().filter(classSelectors.getIsClassArchived);
}

export function getActiveClasses(classes: Classes): List<ClassRecord> {
  return classes
    .toList()
    .filter(classRecord => !classSelectors.getIsClassArchived(classRecord));
}

export function getActiveClassIdsSortedAlphabeticallyByName(classes: Classes) {
  // @ts-ignore
  return getAllClassesSortedAlphabeticallyByName(getActiveClasses(classes)).map(
    classSelectors.getClassId
  ) as List<string>;
}

export const getUserIsClassOwner: (arg0: Classes, arg1: string) => boolean =
  // @ts-ignore
  composeSelector(getClassDetails, classSelectors.getUserIsClassOwner);
