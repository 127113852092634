import ReducerRegistry from "seneca-common/utils/state/reducer-registry";

import { APP_STATE_PATH } from "./consts";
import appReducer from "./reducers";

const reducerRegistry = ReducerRegistry.getInstance();

reducerRegistry.initialise(APP_STATE_PATH, builder => {
  builder.register(APP_STATE_PATH, appReducer);
});

export {
  newAppVersionIsAvailable,
  appVersionIsUpdating,
  newPWAConfigReceived,
  setUpdateToastHidden,
  dismissPrompts,
  showPrompt,
  showWarning,
  setLastSeenPrompt
} from "./actions/pwa";

export {
  getPWAStateSlice as getPWAState,
  newVersionOfTheAppIsAvailable as getNewVersionOfTheAppIsAvailable,
  appVersionIsUpdating as getAppVersionIsUpdating,
  getLastSeenPrompt,
  shouldShowPrompt,
  shouldShowWarning
} from "./selectors";
