import { Record } from "immutable";

import "seneca-common/features/stats-review/state";

import { Timestamp } from "seneca-common/features/stats-review/state";

export type TimestampPairType = {
  startTime?: Timestamp | null;
  endTime: Timestamp | null;
};

const defaultTimestampPair: TimestampPairType = {
  startTime: null,
  endTime: null
};

export default class TimestampPair extends Record(defaultTimestampPair) {}
