import { List } from "immutable";

import { BasicAction as Action } from "common/types/redux";

import actionTypes from "../../../../action-types";
import { clearAll, selectAllClasses } from "../../../../actions";

type Payload = string | List<string>;

export default function selectedClassIdsReducer(
  selectedClassIds: List<string> = List(),
  action: Action<Payload>
): List<string> {
  if (action.type === actionTypes.TOGGLE_CLASS_SELECTED) {
    const classId = action.payload as string;
    const indexToRemove = selectedClassIds.indexOf(classId);

    if (indexToRemove > -1) {
      return selectedClassIds.remove(indexToRemove);
    } else {
      return selectedClassIds.push(classId);
    }
  }

  if (action.type === actionTypes.TOGGLE_ALL_CLASSES) {
    const classIds = List(action.payload as string);
    const allClassesSelected = classIds.every(classId =>
      selectedClassIds.includes(classId)
    );

    if (allClassesSelected) {
      return selectedClassIdsReducer(selectedClassIds, clearAll());
    } else {
      return selectedClassIdsReducer(
        selectedClassIds,
        selectAllClasses(classIds)
      );
    }
  }

  if (action.type === actionTypes.SELECT_MANY) {
    return List(action.payload as string);
  }

  if (action.type === actionTypes.CLEAR_ALL) {
    return List();
  }

  return selectedClassIds;
}
