const actionTypes = {
  NEW_VERSION_IS_AVAILABLE: "seneca/app/pwa/NEW_VERSION_IS_AVAILABLE",
  APP_VERSION_IS_UPDATING: "seneca/app/pwa/APP_VERSION_IS_UPDATING",
  NEW_PWA_CONFIG_RECEIVED: "seneca/app/pwa/NEW_PWA_CONFIG_RECEIVED",
  SET_UPDATE_PROMPT_VISIBILITY: "seneca/app/pwa/SET_UPDATE_PROMPT_VISIBILITY",
  DISMISS_PROMPTS: "seneca/app/pwa/DISMISS_PROMPTS",
  SHOW_PROMPT: "seneca/app/pwa/SHOW_PROMPT",
  SHOW_WARNING: "seneca/app/pwa/SHOW_WARNING",
  SET_LAST_SEEN_PROMPT: "seneca/app/pwa/SET_LAST_SEEN_PROMPT"
};

export default actionTypes;
