import { FirebaseApp, initializeApp } from "firebase/app";
import {
  Auth,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  fetchSignInMethodsForEmail as fetchSignInMethodsForEmailFirebase,
  getAuth,
  initializeAuth,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendPasswordResetEmail as sendPasswordResetEmailFirebase,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signOut as signOutFirebase,
  updatePassword,
  useDeviceLanguage,
  User
} from "firebase/auth";

import { isPlurallApp } from "features/plurall";
import { linkToSignIn } from "features/sign-up/links";

import getConfig from "../../../config";
import {
  logAnalyticsAuthError,
  logAnalyticsAuthStart
} from "./analytics/events";
import { SSO_PROMPT_SELECT_ACCOUNT } from "./consts";
import {
  AccountExistsWithDifferentCredential,
  AuthDomainConfigRequiredError,
  EmailAlreadyExistsError,
  IncorrectPasswordError,
  InvalidPasswordError,
  InvalidSignInMethod,
  LimitExceededError,
  NetworkRequestFailedError,
  NoUserSessionError,
  OperationNotSupportedInThisEnvironmentError,
  PlurallAccountExistsWithDifferentCredential,
  PopupBlocked,
  PopupClosedByUser,
  PopupRequestCancelled,
  UnauthorizedDomainError,
  UserDeniedPermission,
  UserNotFoundError
} from "./errors";
import { reauthenticateWithPopup, signInWithPopup } from "./firebase";
import {
  getFirstSSOProvider,
  getOAuthProvider,
  getOAuthProviderInstance
} from "./ssoProvider";
import { CustomClaims, ProviderSSO } from "./types";

let app: FirebaseApp;

export const getFirebaseAuth = () => {
  if (!app) {
    app = initializeApp(getConfig().firebase);
    let auth: Auth;
    if (window.Cypress) {
      // By default firebase uses indexedDB for persistence.
      // Cypress does not include clearing indexedDB in "Test Isolation" mode, this ensures auth state is cleared between tests.
      auth = initializeAuth(app, {
        persistence: browserLocalPersistence
      });
    } else {
      auth = getAuth(app);
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useDeviceLanguage(auth);
  }
  return getAuth(app);
};

export async function signUp(email: string, password: string) {
  try {
    return await createUserWithEmailAndPassword(
      getFirebaseAuth(),
      email,
      password
    );
  } catch (err: any) {
    if (err.code === "auth/email-already-in-use") {
      throw new EmailAlreadyExistsError(email);
    }

    if (err.code === "auth/network-request-failed") {
      throw new NetworkRequestFailedError();
    }

    if (err.code === "auth/password-does-not-meet-requirements") {
      throw new InvalidPasswordError();
    }

    throw err;
  }
}

export async function signOut() {
  return signOutFirebase(getFirebaseAuth());
}

export async function getCredentials() {
  const { currentUser } = getFirebaseAuth();

  if (!currentUser) {
    return {};
  }

  const accessToken = await currentUser.getIdToken();

  return {
    accessToken
  };
}

export async function resumeSession() {
  return new Promise<User | null>((resolve, reject) => {
    onAuthStateChanged(getFirebaseAuth(), user => {
      if (user) {
        resolve(user);
      } else {
        reject(new NoUserSessionError());
      }
    });
  });
}

export async function sendPasswordResetEmail(email: string) {
  try {
    return await sendPasswordResetEmailFirebase(getFirebaseAuth(), email, {
      url: `${window.location.origin}${linkToSignIn()}${window.location.search}`
    });
  } catch (err: any) {
    if (err.code === "auth/user-not-found") {
      throw new UserNotFoundError(email);
    }

    if (err.code === "auth/network-request-failed") {
      throw new NetworkRequestFailedError();
    }

    throw err;
  }
}

export async function login(email: string, password: string) {
  try {
    logAnalyticsAuthStart({ providerId: "password" });

    return await signInWithEmailAndPassword(getFirebaseAuth(), email, password);
  } catch (err: any) {
    logAnalyticsAuthError({ error: err.code });

    if (err.code === "auth/user-not-found") {
      throw new UserNotFoundError(email);
    }

    if (err.code === "auth/wrong-password") {
      throw new IncorrectPasswordError(email);
    }

    if (err.code === "auth/too-many-requests") {
      throw new LimitExceededError();
    }

    if (err.code === "auth/network-request-failed") {
      throw new NetworkRequestFailedError();
    }

    throw err;
  }
}

export async function signInWithProvider(
  providerName: ProviderSSO,
  isSignInWithRedirect?: boolean
) {
  try {
    logAnalyticsAuthStart({ providerId: providerName });

    const provider = getOAuthProviderInstance(providerName);
    provider.setCustomParameters({
      prompt: SSO_PROMPT_SELECT_ACCOUNT
    });

    return isSignInWithRedirect
      ? await signInWithRedirect(getFirebaseAuth(), provider)
      : await signInWithPopup(getFirebaseAuth(), provider);
  } catch (err: any) {
    logAnalyticsAuthError({ error: err.code });

    if (err.code === "auth/popup-closed-by-user") {
      throw new PopupClosedByUser();
    }

    if (err.code === "auth/popup-blocked") {
      throw new PopupBlocked();
    }

    if (err.code === "auth/cancelled-popup-request") {
      throw new PopupRequestCancelled();
    }

    if (err.code === "auth/user-cancelled") {
      throw new UserDeniedPermission();
    }

    if (err.code === "auth/account-exists-with-different-credential") {
      if (isPlurallApp()) {
        throw new PlurallAccountExistsWithDifferentCredential();
      }

      const { email } = err.customData;

      const hasPassword = await hasPasswordProvider(email);

      const credential =
        getOAuthProvider(providerName).credentialFromError(err);

      if (hasPassword) {
        throw new AccountExistsWithDifferentCredential({
          credential: credential!,
          email: email
        });
      } else {
        const signInMethods = await fetchSignInMethodsForEmail(email);
        const signInProvider = getFirstSSOProvider(signInMethods);
        throw new InvalidSignInMethod(signInProvider);
      }
    }

    if (err.code === "auth/auth-domain-config-required") {
      throw new AuthDomainConfigRequiredError();
    }

    if (err.code === "auth/operation-not-supported-in-this-environment") {
      throw new OperationNotSupportedInThisEnvironmentError();
    }

    if (err.code === "auth/unauthorized-domain") {
      throw new UnauthorizedDomainError();
    }

    if (err.code === "auth/network-request-failed") {
      throw new NetworkRequestFailedError();
    }

    throw err;
  }
}

export async function reauthenticateWithPassword(
  email: string,
  password: string
) {
  const credential = EmailAuthProvider.credential(email, password);

  try {
    return await reauthenticateWithCredential(
      getFirebaseAuth().currentUser!,
      credential
    );
  } catch (err: any) {
    if (err.code === "auth/wrong-password") {
      throw new IncorrectPasswordError(email);
    }

    if (err.code === "auth/network-request-failed") {
      throw new NetworkRequestFailedError();
    }

    throw err;
  }
}

export async function reauthenticateWithPopUp(providerName: ProviderSSO) {
  try {
    const provider = getOAuthProviderInstance(providerName);

    return await reauthenticateWithPopup(
      getFirebaseAuth().currentUser!,
      provider
    );
  } catch (err: any) {
    await signOut();
  }
}

export async function changePassword(
  currentPassword: string,
  newPassword: string
) {
  const currentUser = getFirebaseAuth().currentUser!;
  await reauthenticateWithPassword(currentUser.email!, currentPassword);
  await updatePassword(currentUser, newPassword);
}

export async function fetchSignInMethodsForEmail(email: string) {
  return await fetchSignInMethodsForEmailFirebase(getFirebaseAuth(), email);
}

export async function isUserContentCreator() {
  const { currentUser } = getFirebaseAuth();

  if (!currentUser) {
    return false;
  }

  const token = await currentUser.getIdTokenResult();
  const claims = token.claims as CustomClaims;

  return (
    claims.contentCreator || claims.contentAdmin || claims.somosContentCreator
  );
}

export async function isUserSomosContentCreator() {
  const { currentUser } = getFirebaseAuth();

  if (!currentUser) {
    return false;
  }

  const token = await currentUser.getIdTokenResult();
  const claims = token.claims as CustomClaims;

  return claims.somosContentCreator;
}

export const hasPasswordProvider = async (email: string) => {
  const signInMethods = await fetchSignInMethodsForEmail(email);
  return signInMethods.some(method => method === "password");
};

export function parseFirebaseUserDisplayName(displayName?: string | null) {
  const userDetails: { givenName?: string; familyName?: string } = {};

  if (displayName) {
    const [givenName, ...familyNameParts] = displayName.split(" ");
    const familyName = familyNameParts.join(" ");
    userDetails.givenName = givenName;

    if (familyName) {
      userDetails.familyName = familyName;
    }
  }

  return userDetails;
}
