import { TransactionContext } from "@sentry/types";
import { matchPath } from "react-router-dom";

const topLandingRoutes = [
  "/dashboard",
  "/dashboard/class/:classId/assignments/assignment/:assignmentId",
  "/dashboard/courses/add",
  "/dashboard/join-class/:classId",
  "/parent/monitoring/overview",
  "/parent/child/:childId/overview",
  "/parent/child/:userId/assignment/:assignmentId",
  "/parent/child/:userId/report"
];

const sampleRateClassroom = 0.003;
const sampleRateForParents = 0.01;
const sampleRateDashboard = 0.01;

export function shouldSampleLandingPage(
  context: TransactionContext,
  random: number
) {
  const currentPath = context.name;

  if (currentPath.match("/parent") && random > sampleRateForParents) {
    return false;
  }

  if (currentPath.match("/classroom") && random > sampleRateClassroom) {
    return false;
  }

  if (currentPath.match("/dashboard") && random > sampleRateDashboard) {
    return false;
  }

  const currentPathIsTopLandingRoute = topLandingRoutes.some(topLandingPath => {
    return matchPath(currentPath, {
      path: topLandingPath,
      exact: true
    });
  });

  return currentPathIsTopLandingRoute;
}
