import { List } from "immutable";

import ManageClasses from "../../../models/manageClasses";

export function getSelectedClassIds(state: ManageClasses): List<string> {
  return state.selectedClassIds;
}

export function classIdIsSelected(
  state: ManageClasses,
  classId: string
): boolean {
  return getSelectedClassIds(state).includes(classId);
}
