import { List, Record } from "immutable";

export type ChartDataPointType = {
  x?: number | string;
  y?: number;
};

const defaultChartDataPoint: ChartDataPointType = {
  x: undefined,
  y: undefined
};

export class ChartDataPoint extends Record(defaultChartDataPoint) {}

export type ChartData = List<ChartDataPoint>;
