import { List, Map } from "immutable";

import { Timestamp } from "seneca-common/features/stats-review/state";
import {
  ApiState,
  didError,
  didSucceed,
  getErrorMessage,
  getLastFetchedTime,
  hasFinished,
  hasStarted,
  notSucceededOrStarted
} from "seneca-common/utils/apiStates";

import { makeId, makeIds } from "../../../utils";

type State = Map<string, ApiState>;

// Fetch states for individual user stat
export const hasStatFetchStarted = makeAllTimeIdAndApplySelector(hasStarted);
export const didStatFetchSucceed = makeAllTimeIdAndApplySelector(didSucceed);
export const didStatFetchError = makeAllTimeIdAndApplySelector(didError);
export const getStatFetchErrorMessage =
  makeAllTimeIdAndApplySelector(getErrorMessage);
export const getStatFetchLastFetchedTime =
  makeAllTimeIdAndApplySelector(getLastFetchedTime);

export const hasIntervalStatFetchStarted =
  makeIntervalIdAndApplySelector(hasStarted);
export const didIntervalStatFetchSucceed =
  makeIntervalIdAndApplySelector(didSucceed);
export const didIntervalStatFetchError =
  makeIntervalIdAndApplySelector(didError);
export const hasIntervalStatFetchFinished =
  makeIntervalIdAndApplySelector(hasFinished);
export const getIntervalStatFetchErrorMessage =
  makeIntervalIdAndApplySelector(getErrorMessage);
export const getIntervalStatFetchLastFetchedTime =
  makeIntervalIdAndApplySelector(getLastFetchedTime);
export const doesIntervalStatNeedFetching = makeIntervalIdAndApplySelector(
  notSucceededOrStarted
);

// Fetch states for many users' stats
export const haveStatsFetchStarted =
  makeAllTimeIdsAndApplyEverySelector(hasStarted);
export const didStatsFetchSucceed =
  makeAllTimeIdsAndApplyEverySelector(didSucceed);
export const didStatsFetchError = makeAllTimeIdsAndApplySomeSelector(didError);

export const haveIntervalStatsFetchStarted =
  makeIntervalIdsAndApplyEverySelector(hasStarted);
export const didIntervalStatsFetchSucceed =
  makeIntervalIdsAndApplyEverySelector(didSucceed);
export const didIntervalStatsFetchError =
  makeIntervalIdsAndApplySomeSelector(didError);

function makeAllTimeIdAndApplySelector<T>(
  selector: (arg0: State, arg1: string) => T
) {
  return (state: State, userId: string, courseId: string, endTime: Timestamp) =>
    selector(state, makeId(userId, courseId, undefined, endTime));
}

function makeIntervalIdAndApplySelector<T>(
  selector: (arg0: State, arg1: string) => T
) {
  return (
    state: State,
    userId: string,
    courseId: string,
    startTime: Timestamp,
    endTime: Timestamp
  ) => selector(state, makeId(userId, courseId, startTime, endTime));
}

function makeAllTimeIdsAndApplyEverySelector(
  selector: (arg0: State, arg1: string) => boolean
) {
  return (
    state: State,
    userIds: List<string>,
    courseId: string,
    endTime: Timestamp
  ) => {
    const statIds = makeIds(userIds, courseId, undefined, endTime);
    return statIds.every(statId => selector(state, statId));
  };
}

function makeAllTimeIdsAndApplySomeSelector(
  selector: (arg0: State, arg1: string) => boolean
) {
  return (
    state: State,
    userIds: List<string>,
    courseId: string,
    endTime: Timestamp
  ) => {
    const statIds = makeIds(userIds, courseId, undefined, endTime);
    return statIds.some(statId => selector(state, statId));
  };
}

function makeIntervalIdsAndApplyEverySelector(
  selector: (arg0: State, arg1: string) => boolean
) {
  return (
    state: State,
    userIds: List<string>,
    courseId: string,
    startTime: Timestamp,
    endTime: Timestamp
  ) => {
    const statIds = makeIds(userIds, courseId, startTime, endTime);
    return statIds.every(statId => selector(state, statId));
  };
}

function makeIntervalIdsAndApplySomeSelector(
  selector: (arg0: State, arg1: string) => boolean
) {
  return (
    state: State,
    userIds: List<string>,
    courseId: string,
    startTime: Timestamp,
    endTime: Timestamp
  ) => {
    const statIds = makeIds(userIds, courseId, startTime, endTime);
    return statIds.some(statId => selector(state, statId));
  };
}
