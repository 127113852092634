import { useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom-v5-compat";

import {
  getUserAccountType,
  isSignedIn
} from "seneca-common/features/user/state";
import useRedirectOnCondition from "seneca-common/utils/hooks/routing/useRedirectOnCondition";

import { linkToDashboard } from "features/dashboard/links";
import { linkToParentDashboard } from "features/parents/links";
import {
  isSchoolParent as getIsSchoolParent,
  isSchoolTeacher as getIsSchoolTeacher
} from "features/user/selectors";
import { MODAL_ROUTES, ROUTES } from "routing/routes";

export default function useRedirectToAppropriateHomepage() {
  const accountType = useSelector(getUserAccountType);
  const signedIn = useSelector(isSignedIn);
  const location = useLocation();
  const initiallySignedInRef = useRef(signedIn);
  const initiallySignedIn = initiallySignedInRef.current;

  const shouldRedirect =
    accountType &&
    initiallySignedIn &&
    signedIn &&
    !Object.values(MODAL_ROUTES).some(route =>
      location.pathname.includes(route.path)
    );

  const appropriateLink = useLinkToAppropriateHomepage();

  useRedirectOnCondition(shouldRedirect, appropriateLink);
}

export function useLinkToAppropriateHomepage() {
  const isSchoolTeacher = useSelector(getIsSchoolTeacher);
  const isSchoolParent = useSelector(getIsSchoolParent);

  if (isSchoolTeacher) {
    return ROUTES.TEACHER.HOME.buildPath({});
  }

  if (isSchoolParent) {
    return linkToParentDashboard();
  }

  return linkToDashboard();
}
