import { Box, Stack } from "@seneca/senekit";
import { ResponsiveDimension } from "@seneca/senekit/out/types";

import TextPlaceholder, {
  Props as TextPlaceholderProps
} from "../../TextPlaceholder";

type Props = TextPlaceholderProps & {
  numberOfLines: number;
  placeholderWidths?: string[];
  space?: ResponsiveDimension;
  fillContainer?: boolean;
  marginY?: ResponsiveDimension;
  marginX?: ResponsiveDimension;
};

const MultiLineTextPlaceholder = ({
  numberOfLines,
  children,
  loading,
  placeholderWidths = [],
  space = "large",
  fillContainer,
  marginY,
  marginX,
  ...props
}: Props) => {
  if (loading) {
    const rows = Array(numberOfLines)
      .fill(undefined)
      .map((_, i) => (
        <TextPlaceholder
          key={`text-placeholder-loading-${i}`}
          loading
          placeholderWidth={placeholderWidths[i]}
          {...props}
        />
      ));

    if (fillContainer) {
      return (
        <Box
          flex="1"
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          marginY={marginY}
          marginX={marginX}
        >
          {rows.map((row, i) => (
            <Box marginY="medium" key={i}>
              {row}
            </Box>
          ))}
        </Box>
      );
    }

    return (
      <Box marginY={marginY} marginX={marginX}>
        <Stack space={space}>{rows}</Stack>
      </Box>
    );
  }

  return <>{children}</>;
};

export default MultiLineTextPlaceholder;
