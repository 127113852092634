import {
  analyticsReduxRegistry,
  DATA_LAYER_SCOPES
} from "seneca-common/features/analytics";

import { getNumberOfClasses } from "../selectors";
import withAllClassesFetch from "../selectors/withAllClassesFetch";

export default function registerDataLayerSelectors() {
  analyticsReduxRegistry.registerGlobalSelectorsToDataLayerScope(
    DATA_LAYER_SCOPES.TEACHER,
    {
      numClasses: withAllClassesFetch(getNumberOfClasses)
    }
  );
}
