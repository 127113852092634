import { lazy, ReactNode, Suspense } from "react";
import { createPortal } from "react-dom";
import { Link, NavLink } from "react-router-dom-v5-compat";

import { makeSeneKitProvider, ThemeProvider } from "@seneca/senekit";

import loaderWithRetry from "seneca-common/utils/loaderWithRetry";

import AppBackground from "features/app-background/AppBackground";
import { CroppedImageBackgroundDefs } from "features/common/components/CroppedImageBackground";
import ModalBodyScrollLocker from "features/common/containers/ModalBodyScrollLocker";
import { ModalNodeProvider } from "features/common/modals/components/ModalNodeContext";
import GlobalCSS from "features/common/themes/GlobalCSS";
import useGetThemeInMode from "features/common/themes/hooks/useGetThemeInMode";
import TopBarProvider from "features/common/top-bars/components/TopBarProvider";

import AppHeadProvider from "./AppHeadProvider";

const SeneKitProvider = makeSeneKitProvider({ createPortal, Link, NavLink });

const ToastProvider = lazy(() =>
  loaderWithRetry(
    () =>
      import(
        /* webpackChunkName: "toast-provider" */ "features/common/toasts/ToastProvider"
      )
  )
);

export default function LayoutProviders({ children }: { children: ReactNode }) {
  return (
    // @ts-ignore fix-in-senekit */
    <SeneKitProvider value={{ createPortal, Link, NavLink }}>
      <SenekitThemeProvider>
        <AppHeadProvider>
          <GlobalCSS />
          <ModalProviders>
            <TopBarProvider>
              <AppBackground>{children}</AppBackground>
            </TopBarProvider>
            <Suspense>
              <ToastProvider />
            </Suspense>
          </ModalProviders>
          <CroppedImageBackgroundDefs />
        </AppHeadProvider>
      </SenekitThemeProvider>
    </SeneKitProvider>
  );
}

function SenekitThemeProvider({ children }: { children: ReactNode }) {
  const themeInMode = useGetThemeInMode();

  return <ThemeProvider themeInMode={themeInMode}>{children}</ThemeProvider>;
}

function ModalProviders({ children }: { children: ReactNode }) {
  return (
    // @ts-ignore
    <ModalBodyScrollLocker>
      <ModalNodeProvider>{children}</ModalNodeProvider>
    </ModalBodyScrollLocker>
  );
}
