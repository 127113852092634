import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  Link,
  Text,
  useBreakpoint,
  useTheme
} from "@seneca/senekit";

import { fetchAllClassSkeletonsIfNotFetched } from "seneca-common/features/class/service";
import { useSelectedSchool } from "seneca-common/features/schools/school-selection/hooks";
import { isSignedIn } from "seneca-common/features/user/state";
import useLocationOnMount from "seneca-common/utils/hooks/routing/useLocationOnMount";
import useEffectOnMount from "seneca-common/utils/hooks/useEffectOnMount";
import useEffectWhenPropBecomesTrue from "seneca-common/utils/hooks/useEffectWhenPropBecomesTrue";

import { teacherPreviewKey } from "features/classroom/routes/classroom/routes/course/routes/section/routes/session/links";
import TextPlaceholder from "features/common/components/TextPlaceholder";
import { BooksEmoji } from "features/common/emoji";
import { LastLocation } from "features/common/routing/with-last-location";
import useIsGoStudentLearningApp from "features/goStudentLearning/hooks/useIsGoStudentLearningApp";
import { isInTeacherPlatform } from "features/teachers/routes/links";
import { isSchoolTeacher } from "features/user/selectors";
import { ROUTES } from "routing/routes";

import useGetTeacherTopbarProps from "../../hooks/useGetTeacherTopbarProps";
import Topbar from "../Topbar";
import styles from "./TeacherTopbar.module.scss";

type Props = {
  courseId?: string;
  backToTeacherPlatform?: LastLocation;
};

export default function TeacherTopbar({
  courseId,
  backToTeacherPlatform
}: Props) {
  const theme = useTheme();
  const { isMobileOnly } = useBreakpoint();
  const {
    loading: classSkeletonsLoading,
    hasNoClasses,
    creatingClass,
    createMyFirstClass
  } = useGetTeacherTopbarProps(courseId);
  const {
    data: selectedSchool,
    isFetched: selectedSchoolFetched,
    isLoading: selectedSchoolLoading
  } = useSelectedSchool();

  const dispatch = useDispatch();
  const display = useSelector(isSchoolTeacher);
  const signedIn = useSelector(isSignedIn);

  const loading = classSkeletonsLoading || selectedSchoolLoading;

  useEffectOnMount(() => {
    display &&
      selectedSchoolFetched &&
      dispatch(
        fetchAllClassSkeletonsIfNotFetched({
          schoolId: selectedSchool?.schoolId
        })
      );
  });
  useEffectWhenPropBecomesTrue(
    () =>
      dispatch(
        fetchAllClassSkeletonsIfNotFetched({
          schoolId: selectedSchool?.schoolId
        })
      ),
    display && selectedSchoolFetched
  );
  useEffectWhenPropBecomesTrue(
    () =>
      dispatch(
        fetchAllClassSkeletonsIfNotFetched({
          schoolId: selectedSchool?.schoolId
        })
      ),
    signedIn && selectedSchoolFetched
  );
  const location = useLocationOnMount();
  const isGoStudentLearningApp = useIsGoStudentLearningApp();

  if (!display) {
    return null;
  }

  if (loading) {
    return (
      <Topbar>
        <TextPlaceholder
          loading={loading}
          placeholderWidth="30%"
          loadingStateDelay={0}
        />
      </Topbar>
    );
  }

  if (hasNoClasses && courseId && !isGoStudentLearningApp) {
    return (
      <Topbar>
        <Box display={["none", "block"]}>
          <Text weight="bold">
            <Box color={theme.white}>
              Create a class for this course to monitor your student's progress
            </Box>
          </Text>
        </Box>

        <Box marginLeft={["none", "xlarge"]}>
          <Button
            onClick={() => createMyFirstClass && createMyFirstClass()}
            loading={creatingClass}
          >
            Create a class {isMobileOnly && " for this course"}
          </Button>
        </Box>
      </Topbar>
    );
  }

  const goBackToSpecificTeacherPage =
    backToTeacherPlatform &&
    isInTeacherPlatform(backToTeacherPlatform.pathname);

  const linkProps = goBackToSpecificTeacherPage
    ? {
        to: backToTeacherPlatform.pathname,
        state: backToTeacherPlatform.state
      }
    : {
        to: ROUTES.TEACHER.buildPath({})
      };

  const isOnPreview = new URLSearchParams(location.search).get(
    teacherPreviewKey
  );

  const topBarText = isOnPreview
    ? "Back to assignment creation"
    : `Back to ${isGoStudentLearningApp ? "tutor" : "teacher"} platform`;

  return (
    <Topbar>
      <BooksEmoji
        className={classnames(styles.emoji, styles.hideEmojiMobile)}
      />
      <Link {...linkProps}>
        <Box color={theme.white}>{topBarText}</Box>
      </Link>
    </Topbar>
  );
}
