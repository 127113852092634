import { Map } from "immutable";

import {
  didIntervalStatsFetchError,
  didIntervalStatsFetchSucceed,
  didStatsFetchError,
  didStatsFetchSucceed,
  haveIntervalStatsFetchStarted,
  haveStatsFetchStarted
} from "seneca-common/features/stats-review/features/user-course-stats";

import { getClassEnrolleesUserIds } from "../state";

type State = Map<string, any>;

export function classStatsAreFetching(
  state: State,
  classId: string,
  courseId: string,
  startTime: number | null | undefined,
  endTime: number
): boolean {
  const userIds = getClassEnrolleesUserIds(state, classId);

  if (startTime) {
    return haveIntervalStatsFetchStarted(
      state,
      userIds,
      courseId,
      startTime,
      endTime
    );
  } else {
    return haveStatsFetchStarted(state, userIds, courseId, endTime);
  }
}

export function classStatsFetchSucceeded(
  state: State,
  classId: string,
  courseId: string,
  startTime: number | null | undefined,
  endTime: number
): boolean {
  const userIds = getClassEnrolleesUserIds(state, classId);

  if (startTime) {
    return didIntervalStatsFetchSucceed(
      state,
      userIds,
      courseId,
      startTime,
      endTime
    );
  } else {
    return didStatsFetchSucceed(state, userIds, courseId, endTime);
  }
}

export function classStatsFetchErrored(
  state: State,
  classId: string,
  courseId: string,
  startTime: number | null | undefined,
  endTime: number
): boolean {
  const userIds = getClassEnrolleesUserIds(state, classId);

  if (startTime) {
    return didIntervalStatsFetchError(
      state,
      userIds,
      courseId,
      startTime,
      endTime
    );
  } else {
    return didStatsFetchError(state, userIds, courseId, endTime);
  }
}

export function classStatsNeedFetching(
  state: State,
  classId: string,
  courseId: string,
  startTime: number | null | undefined,
  endTime: number
) {
  const fetchingOrSucceeded =
    classStatsAreFetching(state, classId, courseId, startTime, endTime) ||
    classStatsFetchSucceeded(state, classId, courseId, startTime, endTime);
  return !fetchingOrSucceeded;
}

export function classStatsFetchFinished(
  state: State,
  classId: string,
  courseId: string,
  startTime: number | null | undefined,
  endTime: number
) {
  return (
    classStatsFetchErrored(state, classId, courseId, startTime, endTime) ||
    classStatsFetchSucceeded(state, classId, courseId, startTime, endTime)
  );
}
