import { List, Map } from "immutable";

import {
  getStatGroup,
  makeGetManyStatGroups,
  Stat,
  STAT_GROUPS,
  Timestamp
} from "seneca-common/features/stats-review/state";

import InvalidIntervalStatTimestamps from "../../../errors/InvalidIntervalStatTimestamps";
import { makeId } from "../../../utils";

type State = Map<string, Stat>;
type UserId = string;
type StatId = string;
type StatValue = number;

// Get stat group for a user
export function getAllTimeUserCourseStatGroup(
  state: State,
  userId: UserId,
  courseId: string,
  endTime: Timestamp,
  statGroup: STAT_GROUPS
): StatValue {
  const statId = makeId(userId, courseId, undefined, endTime);
  return getStatGroup(state, statId, statGroup);
}
export function getIntervalUserCourseStatGroup(
  state: State,
  userId: UserId,
  courseId: string,
  startTime: Timestamp,
  endTime: Timestamp,
  statGroup: STAT_GROUPS
): StatValue {
  const statId = makeId(userId, courseId, startTime, endTime);
  return getStatGroup(state, statId, statGroup);
}

// Gets stat group for user for many timestamps
export const {
  allTimeSelector: makeGetManyAllTimeUserCourseStatGroups,
  intervalSelector: makeGetManyIntervalUserCourseStatGroups
} = composeManyUserSelectorCreators();

function composeManyUserSelectorCreators() {
  return {
    allTimeSelector: composeManyAllTimeUserSelectorCreator(
      // @ts-ignore
      makeGetManyStatGroups
    ),
    intervalSelector: composeManyIntervalUserSelectorCreator(
      // @ts-ignore
      makeGetManyStatGroups
    )
  };
}

function composeManyAllTimeUserSelectorCreator(
  selectorCreator: (
    arg0: Map<Timestamp, StatId>,
    arg1: STAT_GROUPS
  ) => (arg0: State) => Map<Timestamp, StatValue>
) {
  return (
    userId: UserId,
    courseId: string,
    endTimes: List<Timestamp>,
    statGroup: STAT_GROUPS
  ) => {
    const timestampsToStatIdsMap = endTimes.reduce(
      (map, endTime) =>
        map.set(endTime, makeId(userId, courseId, undefined, endTime)),
      Map<Timestamp, StatId>()
    );
    return selectorCreator(timestampsToStatIdsMap, statGroup);
  };
}

function composeManyIntervalUserSelectorCreator(
  selectorCreator: (
    arg0: Map<Timestamp, StatId>,
    arg1: STAT_GROUPS
  ) => (arg0: State) => Map<Timestamp, StatValue>
) {
  return (
    userId: UserId,
    courseId: string,
    startTimes: List<Timestamp>,
    endTimes: List<Timestamp>,
    statGroup: STAT_GROUPS
  ) => {
    if (startTimes.size !== endTimes.size) {
      throw new InvalidIntervalStatTimestamps(startTimes, endTimes);
    }

    const timestampsToStatIdsMap = endTimes.reduce(
      (map, endTime, index) =>
        map.set(
          endTime,
          makeId(userId, courseId, startTimes.get(index), endTime)
        ),
      Map<Timestamp, StatId>()
    );
    return selectorCreator(timestampsToStatIdsMap, statGroup);
  };
}
