import { CourseStatsFromServerType } from "seneca-common/features/stats-review/service";
import { StatsGroupedByDateRangesType } from "seneca-common/features/stats-review/state/models/StatsGroupedByDateRanges";

import actionTypes from "../action-types";

export function receiveUserCourseStats({
  userCourseStats,
  groupedStatsByDateRanges,
  userId
}: {
  userCourseStats: CourseStatsFromServerType;
  groupedStatsByDateRanges?: StatsGroupedByDateRangesType;
  userId?: string;
}) {
  return {
    type: actionTypes.RECEIVE_USER_COURSE_STATS,
    payload: { userCourseStats, groupedStatsByDateRanges, userId }
  };
}
