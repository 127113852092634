import { Dispatch } from "common/types/redux";

import {
  ChartOptions,
  getUniformTimestamps
} from "seneca-common/features/stats-review/features/charts";

import { fetchClassStatsIfNeeded } from "./stats";

export function fetchClassChartStatsIfNeeded(
  classId: string,
  courseId: string,
  chartOptions: ChartOptions
) {
  return function (dispatch: Dispatch) {
    const timestampPairs = getUniformTimestamps(chartOptions);

    timestampPairs.forEach(({ startTime, endTime }) =>
      dispatch(
        fetchClassStatsIfNeeded(
          classId,
          courseId,
          startTime as number,
          endTime as number
        )
      )
    );
  };
}
