import { matchPath } from "react-router-dom-v5-compat";

import { Link, PartialLocation } from "common/types/routing";

import { linkToSignUpInvite } from "features/sign-up/links";
import {
  QUERY_PARAMS,
  TEACHER_INVITE_REFERRER
} from "features/teachers/features/class-sharing/hooks/useInviteQueryParamsValid";
import { MODAL_ROUTES } from "routing/routes";

export function linkToAnonymousTeacherInvite(
  email: string,
  classId: string
): string {
  const queryParams = `?${QUERY_PARAMS.referrer}=${TEACHER_INVITE_REFERRER}&${
    QUERY_PARAMS.email
  }=${encodeURIComponent(email)}&${QUERY_PARAMS.classId}=${classId}`;
  return linkToSignUpInvite() + queryParams;
}

export function isLinkToInvite(pathname: Link | PartialLocation) {
  const stringpath =
    // @ts-ignore
    typeof pathname === "string" ? pathname : pathname.pathname;

  if (!stringpath) {
    return false;
  }

  return matchPath(MODAL_ROUTES.INVITE_TO_CLASS.path, stringpath);
}
