import { makeCrudStateActionFactories } from "seneca-common/utils/apiStates";

import {
  classesCrudStateConfig,
  classSkeletonsCrudStateConfig,
  manageClassUsersCrudStateConfig
} from "../consts";

export const {
  fetchActionFactory: fetchClassActionFactory,
  updateActionFactory: updateClassActionFactory,
  deleteActionFactory: deleteClassActionFactory
} = makeCrudStateActionFactories(classesCrudStateConfig);

export const { fetchAllActionFactory: fetchAllClassSkeletonsActionFactory } =
  makeCrudStateActionFactories(classSkeletonsCrudStateConfig);

export const { deleteActionFactory: deleteUsersFromClassActionFactory } =
  makeCrudStateActionFactories(manageClassUsersCrudStateConfig);

export const leaveClassActionFactory = deleteClassActionFactory;
