import { List, Map } from "immutable";

import {
  composeSelector,
  composeSelectorCreator
} from "seneca-common/utils/selectors/compose-selectors";

import { PATH_TO_USERS_STATE } from "../consts";
import { UsersState } from "../models/UsersState";
import * as users from "./state";

export function getUsersStateSlice(state: any): UsersState {
  return state.get(PATH_TO_USERS_STATE);
}

export const getUser = composeSelector(getUsersStateSlice, users.getUser);

export const getUserName: (state: UsersState) => string = composeSelector(
  getUsersStateSlice,
  users.getUserName
);

export const getUserEmail: (state: UsersState) => string = composeSelector(
  getUsersStateSlice,
  users.getUserEmail
);

export const getLastVisitTime: (state: UsersState) => string = composeSelector(
  getUsersStateSlice,
  users.getLastVisitTime
);

export const getUserNameOrEmail: (state: UsersState) => string =
  composeSelector(getUsersStateSlice, users.getUserNameOrEmail);

export const isUserAnonymous = composeSelector(
  getUsersStateSlice,
  users.isUserAnonymous
);

export const makeGetUsersNames: (
  arg0: List<string>
) => (state: UsersState) => Map<string, string> = composeSelectorCreator(
  getUsersStateSlice,
  users.makeGetUsersNames
);

export const makeGetUsersNamesOrEmails: (
  arg0: List<string>
) => (state: UsersState) => Map<string, users.UserName> =
  composeSelectorCreator(getUsersStateSlice, users.makeGetUsersNamesOrEmails);

export const makeGetUsersDetails: (
  arg0: List<string>
) => (state: UsersState) => Map<string, users.UserName> =
  composeSelectorCreator(getUsersStateSlice, users.makeGetUsersDetails);

export const getUserNameAsObject: (
  users: UsersState,
  userId: string
) => users.UserName = composeSelector(getUser, users.getUserNameAsObject);
