import { useRef } from "react";
import { useLocation } from "react-router-dom";

export default function useLocationOnMount() {
  const location = useLocation();

  const locationOnMountRef = useRef(location);

  return locationOnMountRef.current;
}
