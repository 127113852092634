import { List, Map } from "immutable";

import {
  getClassEnrolleesUserIds,
  getClassName
} from "seneca-common/features/class/state";
import {
  Stat,
  STAT_GROUPS,
  Timestamp
} from "seneca-common/features/stats-review/state";
import { StatsGroupedByDateRangesType } from "seneca-common/features/stats-review/state/models/StatsGroupedByDateRanges";
import { getUsers } from "seneca-common/features/users/state";
import { makeGetUsersNamesOrEmails } from "seneca-common/features/users/state/selectors/state";
import isValidFunction from "seneca-common/utils/functions/checks/isValidFunction";
import {
  composeSelector,
  composeSelectorCreator
} from "seneca-common/utils/selectors/compose-selectors";

import {
  PATH_TO_USER_COURSE_STATS_REVIEW_STATE,
  STAT_GROUPS_TO_EXPORT_TO_CSV,
  StatGroupAccessorType
} from "../consts";
import UserCourseStat from "../models/UserCourseStat";
import UserCourseStatsReviewState from "../models/UserCourseStatsReviewState";
import { getMeta, getStats, getStatsGroupedByDateRanges } from "./state";
import * as meta from "./state/meta";
import * as userCourseStatsSelectors from "./state/stats/userCourse";
import * as userCoursesStatsSelectors from "./state/stats/userCourses";
import * as userCoursesGroupedStatsSelectors from "./state/stats/userCoursesGroupedStats";
import * as usersCourseStatsSelectors from "./state/stats/usersCourse";

type UserId = string;

type UserStatsSelector<T> = (
  userIds: List<UserId>,
  courseId: string,
  startTime: Timestamp | null | undefined,
  endTime: Timestamp
) => (state: any) => T;

function getUserCourseStatsReviewStateSlice(
  state: any
): UserCourseStatsReviewState {
  return state.get(PATH_TO_USER_COURSE_STATS_REVIEW_STATE);
}

// stats
export function getUserCourseStatsReviewStats(
  state: any
): Map<string, UserCourseStat> {
  return getStats(getUserCourseStatsReviewStateSlice(state));
}

export function getUserCourseStatsReviewGroupedByDateRanges(
  state: any
): Map<string, StatsGroupedByDateRangesType> {
  return getStatsGroupedByDateRanges(getUserCourseStatsReviewStateSlice(state));
}

// Get stat group for a user
export type AllTimeStatGroupSelector = (
  state: any,
  userId: UserId,
  courseId: string,
  endTime: Timestamp,
  statGroup: STAT_GROUPS
) => number;
export type IntervalStatGroupSelector = (
  state: any,
  userId: UserId,
  courseId: string,
  startTime: Timestamp,
  endTime: Timestamp,
  statGroup: STAT_GROUPS
) => number;
export const getAllTimeUserCourseStatGroup: AllTimeStatGroupSelector =
  composeSelector(
    // @ts-ignore
    getUserCourseStatsReviewStats,
    userCourseStatsSelectors.getAllTimeUserCourseStatGroup
  );
export const getIntervalUserCourseStatGroup: IntervalStatGroupSelector =
  composeSelector(
    // @ts-ignore
    getUserCourseStatsReviewStats,
    userCourseStatsSelectors.getIntervalUserCourseStatGroup
  );

// Gets all stat groups for many users
export type AllTimeUsersStatsSelector = (
  userIds: List<UserId>,
  courseId: string,
  endTime: Timestamp
) => (state: any) => Map<UserId, UserCourseStat>;
export type IntervalUsersStatsSelector = (
  userIds: List<UserId>,
  courseId: string,
  startTime: Timestamp,
  endTime: Timestamp
) => (state: any) => Map<UserId, UserCourseStat>;

export const makeGetUsersCourseStats: UserStatsSelector<
  Map<UserId, UserCourseStat>
> = (userIds, courseId, startTime, endTime) => {
  return startTime
    ? makeGetIntervalUsersCourseStats(userIds, courseId, startTime, endTime)
    : makeGetAllTimeUsersCourseStats(userIds, courseId, endTime);
};

export const makeGetAllTimeUsersCourseStats: AllTimeUsersStatsSelector =
  composeSelectorCreator(
    getUserCourseStatsReviewStats,
    usersCourseStatsSelectors.makeGetAllTimeUsersCourseStats
  );
export const makeGetIntervalUsersCourseStats: IntervalUsersStatsSelector =
  composeSelectorCreator(
    getUserCourseStatsReviewStats,
    usersCourseStatsSelectors.makeGetIntervalUsersCourseStats
  );

export type GetCSVDataInputType = {
  state: any;
  classId: string;
  courseId: string;
  startTime: number;
  endTime: number;
};

export function getCsvData({
  state,
  classId,
  courseId,
  startTime,
  endTime
}: GetCSVDataInputType): List<List<string | number>> {
  const nameOfClass = getClassName(state, classId);
  const userIds = getClassEnrolleesUserIds(state, classId);
  const getUserNames = makeGetUsersNamesOrEmails(userIds);
  const userNames: Map<
    string,
    {
      givenName: string;
      familyName?: string | null;
    }
  > = getUserNames(getUsers(state));
  return userIds.reduce((rows, userId) => {
    const names = Object.values(userNames.get(userId)!) as string[];

    const columns = List([nameOfClass, ...names]) as List<string | number>;
    return rows.push(
      STAT_GROUPS_TO_EXPORT_TO_CSV.reduce(
        (acc, statGroupObj: StatGroupAccessorType) => {
          const { statGroupType, formatFunc } = statGroupObj;
          const statGroupValue: number = getIntervalUserCourseStatGroup(
            state,
            userId,
            courseId,
            startTime,
            endTime,
            // @ts-ignore
            statGroupType
          );

          const formattedVal = isValidFunction(formatFunc)
            ? formatFunc(statGroupValue)
            : statGroupValue;
          return acc.push(formattedVal);
        },
        columns
      )
    );
  }, List());
}

// Gets stat group for many users
export type AllTimeUsersStatGroupsSelector = (
  userIds: List<UserId>,
  courseId: string,
  endTime: Timestamp,
  statGroup: STAT_GROUPS
) => (state: any) => Map<UserId, number>;
export type IntervalUsersStatGroupsSelector = (
  userIds: List<UserId>,
  courseId: string,
  startTime: Timestamp,
  endTime: Timestamp,
  statGroup: STAT_GROUPS
) => (state: any) => Map<UserId, number>;
export const makeGetAllTimeUsersCourseStatGroups: AllTimeUsersStatGroupsSelector =
  composeSelectorCreator(
    getUserCourseStatsReviewStats,
    usersCourseStatsSelectors.makeGetAllTimeUsersCourseStatGroups
  );
export const makeGetIntervalUsersCourseStatGroups: IntervalUsersStatGroupsSelector =
  composeSelectorCreator(
    getUserCourseStatsReviewStats,
    usersCourseStatsSelectors.makeGetIntervalUsersCourseStatGroups
  );

// Gets stat group for user for many timestamps
export type ManyAllTimeStatGroupsSelectorCreator = (
  userId: UserId,
  courseId: string,
  endTimes: List<Timestamp>,
  statGroup: STAT_GROUPS
) => (state: any) => Map<Timestamp, number>;
export type ManyIntervalStatGroupsSelectorCreator = (
  userId: UserId,
  courseId: string,
  startTimes: List<Timestamp>,
  endTimes: List<Timestamp>,
  statGroup: STAT_GROUPS
) => (state: any) => Map<Timestamp, number>;
export const makeGetManyAllTimeUserCourseStatGroups: ManyAllTimeStatGroupsSelectorCreator =
  composeSelectorCreator(
    // @ts-ignore
    getUserCourseStatsReviewStats,
    userCourseStatsSelectors.makeGetManyAllTimeUserCourseStatGroups
  );
export const makeGetManyIntervalUserCourseStatGroups: ManyIntervalStatGroupsSelectorCreator =
  composeSelectorCreator(
    // @ts-ignore
    getUserCourseStatsReviewStats,
    userCourseStatsSelectors.makeGetManyIntervalUserCourseStatGroups
  );

// Gets sum/averages of stat group for many users
export type CombinedAllTimeStatGroupSelectorCreator = (
  userIds: List<UserId>,
  courseId: string,
  endTime: Timestamp,
  statGroup: STAT_GROUPS
) => (state: any) => number;
export type CombinedIntervalStatGroupSelectorCreator = (
  userIds: List<UserId>,
  courseId: string,
  startTime: Timestamp,
  endTime: Timestamp,
  statGroup: STAT_GROUPS
) => (state: any) => number;
export const makeGetAllTimeSumUsersCourseStatGroups: CombinedAllTimeStatGroupSelectorCreator =
  composeSelectorCreator(
    getUserCourseStatsReviewStats,
    usersCourseStatsSelectors.makeGetAllTimeSumUsersCourseStatGroups
  );
export const makeGetIntervalSumUsersCourseStatGroups: CombinedIntervalStatGroupSelectorCreator =
  composeSelectorCreator(
    getUserCourseStatsReviewStats,
    usersCourseStatsSelectors.makeGetIntervalSumUsersCourseStatGroups
  );
export const makeGetAllTimeAverageUsersCourseStatGroups: CombinedAllTimeStatGroupSelectorCreator =
  composeSelectorCreator(
    getUserCourseStatsReviewStats,
    usersCourseStatsSelectors.makeGetAllTimeAverageUsersCourseStatGroups
  );
export const makeGetIntervalAverageUsersCourseStatGroups: CombinedIntervalStatGroupSelectorCreator =
  composeSelectorCreator(
    getUserCourseStatsReviewStats,
    usersCourseStatsSelectors.makeGetIntervalAverageUsersCourseStatGroups
  );

// Gets sum/averages of all stat groups for many users
export type CombinedAllTimeStatsSelectorCreator = (
  userIds: List<UserId>,
  courseId: string,
  endTime: Timestamp
) => (state: any) => Stat;
export type CombinedIntervalStatsSelectorCreator = (
  userIds: List<UserId>,
  courseId: string,
  startTime: Timestamp,
  endTime: Timestamp
) => (state: any) => Stat;
export const makeGetAllTimeSumUsersCourseStats: CombinedAllTimeStatsSelectorCreator =
  composeSelectorCreator(
    getUserCourseStatsReviewStats,
    usersCourseStatsSelectors.makeGetAllTimeSumUsersCourseStats
  );
export const makeGetIntervalSumUsersCourseStats: CombinedIntervalStatsSelectorCreator =
  composeSelectorCreator(
    getUserCourseStatsReviewStats,
    usersCourseStatsSelectors.makeGetIntervalSumUsersCourseStats
  );
export const makeGetAverageUsersCourseStats: UserStatsSelector<Stat> = (
  userIds,
  courseId,
  startTime,
  endTime
) => {
  return startTime
    ? makeGetIntervalAverageUsersCourseStats(
        userIds,
        courseId,
        startTime,
        endTime
      )
    : makeGetAllTimeAverageUsersCourseStats(userIds, courseId, endTime);
};
export const makeGetAllTimeAverageUsersCourseStats: CombinedAllTimeStatsSelectorCreator =
  composeSelectorCreator(
    getUserCourseStatsReviewStats,
    usersCourseStatsSelectors.makeGetAllTimeAverageUsersCourseStats
  );
export const makeGetIntervalAverageUsersCourseStats: CombinedIntervalStatsSelectorCreator =
  composeSelectorCreator(
    getUserCourseStatsReviewStats,
    usersCourseStatsSelectors.makeGetIntervalAverageUsersCourseStats
  );

// Gets sum/averages of stat group for many users and many timestamps
export type ManyCombinedAllTimeStatGroupsSelectorCreator = (
  userIds: List<UserId>,
  courseId: string,
  endTimes: List<Timestamp>,
  statGroup: STAT_GROUPS
) => (state: any) => Map<Timestamp, number>;
export type ManyCombinedIntervalStatGroupsSelectorCreator = (
  userIds: List<UserId>,
  courseId: string,
  startTimes: List<Timestamp>,
  endTimes: List<Timestamp>,
  statGroup: STAT_GROUPS
) => (state: any) => Map<Timestamp, number>;
export const makeGetManyAllTimeSumUsersCourseStatGroups: ManyCombinedAllTimeStatGroupsSelectorCreator =
  composeSelectorCreator(
    getUserCourseStatsReviewStats,
    usersCourseStatsSelectors.makeGetManyAllTimeSumUsersCourseStatGroups
  );
export const makeGetManyIntervalSumUsersCourseStatGroups: ManyCombinedIntervalStatGroupsSelectorCreator =
  composeSelectorCreator(
    getUserCourseStatsReviewStats,
    usersCourseStatsSelectors.makeGetManyIntervalSumUsersCourseStatGroups
  );
export const makeGetManyAllTimeAverageUsersCourseStatGroups: ManyCombinedAllTimeStatGroupsSelectorCreator =
  composeSelectorCreator(
    getUserCourseStatsReviewStats,
    usersCourseStatsSelectors.makeGetManyAllTimeAverageUsersCourseStatGroups
  );
export const makeGetManyIntervalAverageUsersCourseStatGroups: ManyCombinedIntervalStatGroupsSelectorCreator =
  composeSelectorCreator(
    getUserCourseStatsReviewStats,
    usersCourseStatsSelectors.makeGetManyIntervalAverageUsersCourseStatGroups
  );

/* USER COURSES */

type MakeGetUserCoursesStats = (
  userId: string,
  courseIds: List<string>,
  startTime: Timestamp | null | undefined,
  endTime: Timestamp
) => (state: any) => Map<string, UserCourseStat>;

// @ts-ignore
export const makeGetUserCoursesStats: MakeGetUserCoursesStats =
  composeSelectorCreator(
    // @ts-ignore
    getUserCourseStatsReviewStats,
    userCoursesStatsSelectors.makeGetUserCoursesStats
  );

export const makeGetUserCoursesGroupedStats: any = composeSelectorCreator(
  // @ts-ignore
  getUserCourseStatsReviewGroupedByDateRanges,
  userCoursesGroupedStatsSelectors.makeGetUserCoursesStatsGrouped
);

// meta
function getUserCourseStatsReviewMeta(state: any): Map<string, UserCourseStat> {
  return getMeta(getUserCourseStatsReviewStateSlice(state));
}

export const hasStatFetchStarted = composeSelector(
  // @ts-ignore
  getUserCourseStatsReviewMeta,
  meta.hasStatFetchStarted
);
export const didStatFetchSucceed = composeSelector(
  // @ts-ignore
  getUserCourseStatsReviewMeta,
  meta.didStatFetchSucceed
);
export const didStatFetchError = composeSelector(
  // @ts-ignore
  getUserCourseStatsReviewMeta,
  meta.didStatFetchError
);
export const getStatFetchErrorMessage = composeSelector(
  // @ts-ignore
  getUserCourseStatsReviewMeta,
  meta.getStatFetchErrorMessage
);
export const getStatFetchLastFetchedTime = composeSelector(
  // @ts-ignore
  getUserCourseStatsReviewMeta,
  meta.getStatFetchLastFetchedTime
);
export const hasIntervalStatFetchStarted = composeSelector(
  // @ts-ignore
  getUserCourseStatsReviewMeta,
  meta.hasIntervalStatFetchStarted
);
export const didIntervalStatFetchSucceed = composeSelector(
  // @ts-ignore
  getUserCourseStatsReviewMeta,
  meta.didIntervalStatFetchSucceed
);
export const didIntervalStatFetchError = composeSelector(
  // @ts-ignore
  getUserCourseStatsReviewMeta,
  meta.didIntervalStatFetchError
);
export const hasIntervalStatFetchFinished = composeSelector(
  // @ts-ignore
  getUserCourseStatsReviewMeta,
  meta.hasIntervalStatFetchFinished
);
export const getIntervalStatFetchErrorMessage = composeSelector(
  // @ts-ignore
  getUserCourseStatsReviewMeta,
  meta.getIntervalStatFetchErrorMessage
);
export const getIntervalStatFetchLastFetchedTime = composeSelector(
  // @ts-ignore
  getUserCourseStatsReviewMeta,
  meta.getIntervalStatFetchLastFetchedTime
);
export const doesIntervalStatNeedFetching = composeSelector(
  // @ts-ignore
  getUserCourseStatsReviewMeta,
  meta.doesIntervalStatNeedFetching
);
export const haveStatsFetchStarted = composeSelector(
  // @ts-ignore
  getUserCourseStatsReviewMeta,
  meta.haveStatsFetchStarted
);
export const didStatsFetchSucceed = composeSelector(
  // @ts-ignore
  getUserCourseStatsReviewMeta,
  meta.didStatsFetchSucceed
);
export const didStatsFetchError = composeSelector(
  // @ts-ignore
  getUserCourseStatsReviewMeta,
  meta.didStatsFetchError
);
export const haveIntervalStatsFetchStarted = composeSelector(
  // @ts-ignore
  getUserCourseStatsReviewMeta,
  meta.haveIntervalStatsFetchStarted
);
export const didIntervalStatsFetchSucceed = composeSelector(
  // @ts-ignore
  getUserCourseStatsReviewMeta,
  meta.didIntervalStatsFetchSucceed
);
export const didIntervalStatsFetchError = composeSelector(
  // @ts-ignore
  getUserCourseStatsReviewMeta,
  meta.didIntervalStatsFetchError
);
