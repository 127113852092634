import { List } from "immutable";

import { Timestamp } from "seneca-common/features/stats-review/state";

export function makeId(
  userId: string,
  courseId: string | null | undefined,
  startTime: Timestamp | null | undefined,
  endTime: Timestamp
): string {
  let id = `${userId}`;
  if (courseId) id += `-${courseId}`;
  if (startTime) id += `-${startTime}`;
  id += `-${endTime}`;
  return id;
}

export function makeIds(
  userIds: List<string>,
  courseId: string,
  startTime: Timestamp | null | undefined,
  endTime: Timestamp
): List<string> {
  return userIds.map((userId: string) =>
    makeId(userId, courseId, startTime, endTime)
  );
}
