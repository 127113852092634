import {
  linkToRetryPayment,
  linkToUpgrade
} from "features/subscriptions/features/upgrades/routes/links";

import { linkToParentWhatIsPremiumPage } from "../links";

export function linkToParentUpgradeSubscription() {
  return linkToUpgrade(linkToParentWhatIsPremiumPage());
}

export function linkToRetryUpgradePayment(
  subscriptionId: string = ":subscriptionId"
) {
  return linkToRetryPayment(linkToParentWhatIsPremiumPage(), subscriptionId);
}

export { linkToParentWhatIsPremiumPage };
