import { AllowedCallTypes, CrudConfig } from "seneca-common/utils/apiStates";

export const PATH_TO_CLASS_STATE = "classes";

export const classSkeletonsCrudStateConfig: CrudConfig = {
  name: "classSkeletons",
  path: "seneca/classSkeletons/meta",
  idFieldName: "classId",
  callTypes: [AllowedCallTypes.FETCH_ALL]
};

export const classesCrudStateConfig: CrudConfig = {
  name: "classes",
  path: "seneca/classes/meta",
  idFieldName: "classId",
  callTypes: [
    AllowedCallTypes.FETCH,
    AllowedCallTypes.UPDATE,
    AllowedCallTypes.DELETE
  ]
};

export const manageClassUsersCrudStateConfig: CrudConfig = {
  name: "manageClassUsers",
  path: "seneca/classes/manage-users/meta",
  idFieldName: "classId",
  callTypes: [AllowedCallTypes.DELETE]
};
