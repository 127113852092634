import {
  ClassDoesNotExistError,
  ClassEnrollmentBodyNotValidError,
  ClassSizeLimitExceeded,
  ForbiddenForManagedClass,
  UserHasReachedMaximumNumberOfActiveClasses,
  UserHasReachedMaximumNumberOfEnroledClasses
} from "@seneca/class-service-client";

import { FORBIDDEN_FOR_MANAGED_CLASS_ERROR } from "./errorConsts";

export default function errorTranslator(error: Error): string {
  try {
    if (typeof error === "string") return error;

    if (
      error instanceof ClassDoesNotExistError ||
      error instanceof ClassEnrollmentBodyNotValidError
    )
      return "Sorry, that class doesn't exist. Make sure you have the correct code from your teacher.";

    if (error instanceof UserHasReachedMaximumNumberOfEnroledClasses)
      return "Sorry, you've reached the maximum number of classes. Leave an old class to join this one.";

    if (error instanceof ClassSizeLimitExceeded)
      return "Sorry, this class has reached the maximum size of 200 students.";

    if (error instanceof ForbiddenForManagedClass)
      return FORBIDDEN_FOR_MANAGED_CLASS_ERROR;

    if (error instanceof UserHasReachedMaximumNumberOfActiveClasses)
      return "Sorry, you've reached the maximum number of active classes. Delete or archive an active class to create another.";

    return "An unknown error has occurred.";
  } catch (err) {
    return "An unknown error has occurred.";
  }
}
