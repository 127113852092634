import { List } from "immutable";

import {
  didIntervalStatsFetchError,
  didIntervalStatsFetchSucceed,
  didStatsFetchError,
  didStatsFetchSucceed,
  haveIntervalStatsFetchStarted,
  haveStatsFetchStarted
} from "seneca-common/features/stats-review/features/user-course-stats";
import { STAT_GROUPS } from "seneca-common/features/stats-review/state";

import { ChartOptions } from "../models/ChartOptions";
import { getUniformTimestamps } from "../utils/chartOptions";

export function userCourseSumLineChartDataIsFetching(
  state: any,
  userIds: List<string>,
  courseId: string,
  statGroup: STAT_GROUPS,
  chartOptions: ChartOptions
): boolean {
  const timestampPairs = getUniformTimestamps(chartOptions);
  return timestampPairs.every(({ startTime, endTime }) =>
    startTime
      ? haveIntervalStatsFetchStarted(userIds, courseId, startTime, endTime)
      : haveStatsFetchStarted(userIds, courseId, endTime)
  );
}

export function userCourseSumLineChartDataFetchSucceeded(
  state: any,
  userIds: List<string>,
  courseId: string,
  statGroup: STAT_GROUPS,
  chartOptions: ChartOptions
): boolean {
  const timestampPairs = getUniformTimestamps(chartOptions);
  return timestampPairs.every(({ startTime, endTime }) =>
    startTime
      ? didIntervalStatsFetchSucceed(
          state,
          userIds,
          courseId,
          startTime,
          endTime
        )
      : didStatsFetchSucceed(state, userIds, courseId, endTime)
  );
}

export function userCourseSumLineChartDataErrored(
  state: any,
  userIds: List<string>,
  courseId: string,
  statGroup: STAT_GROUPS,
  chartOptions: ChartOptions
): boolean {
  const timestampPairs = getUniformTimestamps(chartOptions);
  return timestampPairs.some(({ startTime, endTime }) =>
    startTime
      ? didIntervalStatsFetchError(userIds, courseId, startTime, endTime)
      : didStatsFetchError(userIds, courseId, endTime)
  );
}
