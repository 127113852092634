import classnames from "classnames";
import * as React from "react";

import TextPlaceholder, {
  Props as TextPlaceholderProps
} from "../../TextPlaceholder";

import "./TextPlaceholderWhite.scss";

const TextPlaceholderWhite = ({
  loadingClassName,
  ...props
}: TextPlaceholderProps) => {
  return (
    <TextPlaceholder
      loadingClassName={classnames(
        "TextPlaceholderWhite--loading",
        loadingClassName
      )}
      {...props}
    />
  );
};

export default TextPlaceholderWhite;
