import {
  ClassDoesNotExistError,
  ClassEnrollmentBodyNotValidError,
  ClassSizeLimitExceeded,
  ForbiddenForManagedClass,
  UserHasReachedMaximumNumberOfActiveClasses,
  UserHasReachedMaximumNumberOfEnroledClasses
} from "@seneca/class-service-client";

import { ErrorHandler, throttledLogError } from "seneca-common/utils/apiStates";

import classServiceErrorTranslator from "./errorTranslator";

const classServiceErrorHandler: ErrorHandler = (error, { errorAction }) => {
  errorAction(classServiceErrorTranslator(error));

  if (
    !(
      error instanceof ClassDoesNotExistError ||
      error instanceof ClassEnrollmentBodyNotValidError ||
      error instanceof ForbiddenForManagedClass ||
      error instanceof UserHasReachedMaximumNumberOfEnroledClasses ||
      error instanceof ClassSizeLimitExceeded ||
      error instanceof UserHasReachedMaximumNumberOfActiveClasses
    )
  ) {
    throttledLogError(error);
  }
};

export default classServiceErrorHandler;
