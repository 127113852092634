// routesConfig with parametrized routes is needed to remove dynamic <id> from tracing logs
export const routesConfig = [
  { path: "/parent/monitoring/overview" },
  { path: "/parent/child/:childId/overview" },
  { path: "/parent/child/:userId/assignments" },
  { path: "/parent/child/:userId/assignment/:assignmentId" },
  { path: "/parent/child/:userId/report" },
  { path: "/parent/tutoring" },
  { path: "/parent/what-is-premium" },
  { path: "/dashboard/class/:classId/assignments/assignment/:assignmentId" },
  { path: "/dashboard/assignments/todo" },
  { path: "/dashboard/class/:classId/assignments/missed" },
  { path: "/dashboard/class/:classId/assignments/todo" },
  { path: "/dashboard/class/:classId/assignments/done" },
  { path: "/dashboard/join-class/:classId" },
  { path: "/dashboard/class/:classId" },
  { path: "/dashboard/courses/add" },
  { path: "/dashboard" },
  { path: "/dashboard/what-is-premium" },
  {
    path: "/classroom/course/:courseId/section/:sectionId/session/end-session"
  },
  {
    path: "/classroom/course/:courseId/section/:sectionId/session/start"
  },
  {
    path: "/classroom/course/:courseId/section/:sectionId/exam-questions/session"
  },
  {
    path: "/classroom/course/:courseId/section/:sectionId"
  },
  { path: "/classroom/course/:courseId/assignments/upcoming" },
  { path: "/classroom/course/:courseId/assignments/past" },
  { path: "/classroom/course/:courseId/exam-questions/session" },
  { path: "/classroom/course/:courseId" },
  { path: "/teacher/class/:classId/courses" },
  { path: "/teacher/class/:classId/overview" },
  { path: "/teacher/class/:classId/overview/invite-parents" },
  { path: "/teacher/class/:classId/overview/manage-teachers" },
  { path: "/teacher/class/:classId/students/manage/user/:userId" },
  { path: "/teacher/class/:classId/students/manage" },
  { path: "/teacher/class/:classId/students" },
  { path: "/teacher/class/:classId/add-courses" },
  { path: "/teacher/class/:classId/invite-students" },
  {
    path: "/teacher/class/:classId/create-assignment/course/:courseId/sections"
  },
  {
    path: "/teacher/class/:classId/create-assignment/course/:courseId/students"
  },
  {
    path: "/teacher/class/:classId/create-assignment/course/:courseId/due"
  },
  { path: "/teacher/class/:classId/create-assignment" },
  {
    path: "/teacher/class/:classId/assignments/assignment/:assignmentId/share"
  },
  {
    path: "/teacher/class/:classId/assignments/assignment/:assignmentId"
  },
  { path: "/teacher/class/:classId/assignments" },
  { path: "/teacher/class/:classId/insights/sectionsSelector" },
  { path: "/teacher/class/:classId/insights" },
  { path: "/teacher/class/:classId/settings/archive" },
  { path: "/teacher/class/:classId/settings/leave" },
  { path: "/teacher/class/:classId/settings/delete" },
  { path: "/teacher/class/:classId/settings" },
  { path: "/teacher/class/:classId" },
  { path: "/teacher/class/:classId/grades" },
  { path: "/teacher/assignments" },
  { path: "/teacher/assignments/:assignmentId/classes" },
  { path: "/teacher/assignments/:assignmentId/students" },
  { path: "/teacher/quizzes" },
  { path: "/teacher/quizzes/school" },
  { path: "/teacher/quizzes/:quizId" },
  { path: "/teacher/quizzes/create/details" },
  { path: "/teacher/quizzes/create/input" },
  { path: "/teacher/quizzes/create/generating" }
];
