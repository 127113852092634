import { combineReducers } from "redux-immutable";

import ManageClasses from "seneca-common/features/class/state/models/manageClasses";

import selectedClassIdsReducer from "./selectedClassIds";

export default combineReducers(
  {
    selectedClassIds: selectedClassIdsReducer
  },
  () => new ManageClasses() as any
);
