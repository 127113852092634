import { Dispatch, GetState } from "common/types/redux";

import { analyticsLogCreateClass } from "seneca-common/features/class/analytics";
import { createAndReceiveClassWithCourses } from "seneca-common/features/class/service";

export function createClassWithCourses(className: string, courseIds: string[]) {
  return async function (dispatch: Dispatch, getState: GetState) {
    const newClass = await dispatch(
      createAndReceiveClassWithCourses(className, courseIds)
    );
    dispatch(analyticsLogCreateClass(newClass.classId));
    return newClass;
  };
}
