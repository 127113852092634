import SignInState from "../models/SignInState";
import { getUserSlice } from "./userInfo";

export function getUserSignInState(state: any): SignInState {
  return getUserSlice(state).signInState;
}

export function isSignedIn(state: any): boolean {
  return getUserSignInState(state).signedIn;
}

export function isSigningIn(state: any): boolean {
  return getUserSignInState(state).signingIn;
}

export function isSigningOut(state: any): boolean {
  return getUserSignInState(state).signingOut;
}

export function isSignedInAsGuest(state: any): boolean {
  return getUserSignInState(state).signedInAsGuest;
}

export function userHasSignedInBefore(state: any): boolean {
  return getUserSignInState(state).hasSignedInBefore;
}

export function isFirstUserSession(state: any): boolean {
  return getUserSignInState(state).isFirstSession;
}

export function credentialsRefreshHasErrored(state: any): boolean {
  return getUserSignInState(state).refreshCredentialsError;
}

export function getSignInTime(state: any): string | null | undefined {
  return getUserSignInState(state).signInTime;
}
