import { Record } from "immutable";

import { Timestamp } from "./Timestamp";

export type StatType = {
  courseId: string | null;
  startTime: Timestamp | null | undefined;
  endTime: Timestamp | null;
  sessionsCompleted: number;
  studyDuration: number;
  averageScore: number;
  sessionModulesCorrect: number;
  sessionModulesStudied: number;
  sessionModulesIncorrect: number;
};

export const defaultStat: StatType = {
  courseId: null,
  startTime: null,
  endTime: null,
  sessionsCompleted: 0,
  studyDuration: 0,
  averageScore: 0,
  sessionModulesCorrect: 0,
  sessionModulesStudied: 0,
  sessionModulesIncorrect: 0
};

export default class Stat extends Record(defaultStat) {}
