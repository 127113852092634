import { useCallback, useEffect, useRef } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom-v5-compat";

import { PartialLocation } from "common/types/routing";

/**
 Makes a callback to redirect to a link, where you bake in any parameters
 i.e.
 const linkToCourse = (courseId) => `/some/path/${courseId}`

 const redirect = useSimpleRedirectCallback(linkToCourse('someCourseId'))

 // No params needed
 navigateToCourse()

 */

type SimpleLink = () => void;

export default function useSimpleRedirectCallback(
  link: string | PartialLocation,
  navigationOptions?: NavigateOptions
): SimpleLink {
  const navigate = useNavigate();

  const linkRef = useRef(link);

  useEffect(() => {
    linkRef.current = link;
  }, [link]);

  return useCallback(() => {
    if (typeof link === "string") {
      navigate(link, navigationOptions);
    } else {
      const { state, ...path } = link;
      navigate(path, { state, ...navigationOptions });
    }
  }, [navigate, link, navigationOptions]);
}
