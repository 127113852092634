import {
  createContext,
  ElementRef,
  ReactComponentElement,
  ReactNode,
  useRef
} from "react";
import { createPortal } from "react-dom";

import { useHideUpdateToastOnMobile } from "features/app/hooks/useHideUpdateToastWhileMounted";

const ModalNodeContext = createContext<HTMLDivElement | null>(null);

type ChildrenProps = {
  children: ReactNode;
};

function ModalNodeProvider({ children }: ChildrenProps) {
  const modalNode = useRef<HTMLDivElement>(null);

  return (
    <>
      <ModalNodeContext.Provider value={modalNode.current}>
        {children}
      </ModalNodeContext.Provider>
      <div ref={modalNode} />
    </>
  );
}

type ConsumerProps = {
  children: (arg0: ElementRef<any>) => ReactComponentElement<any, any> | null;
};

function ModalNodeConsumer({ children }: ConsumerProps) {
  return (
    <ModalNodeContext.Consumer>
      {modalNode => children(modalNode)}
    </ModalNodeContext.Consumer>
  );
}

function ModalPortal({ children }: ChildrenProps) {
  useHideUpdateToastOnMobile();
  return (
    <ModalNodeConsumer>
      {modalNode =>
        modalNode ? createPortal(children, modalNode as any) : null
      }
    </ModalNodeConsumer>
  );
}

export { ModalNodeProvider, ModalNodeConsumer, ModalPortal };
