import { Dispatch } from "common/types";

import { throttle } from "seneca-common/utils/functions";
import getMessageFromError from "seneca-common/utils/functions/services/getMessageFromError";
import logError, { logMessage } from "seneca-common/utils/sentry/logError";

import {
  AllowedActionStatus,
  AllowedCallTypes,
  CrudConfig
} from "../../common/const";
import { generateActionName, processCallType } from "../../common/utils";
import makeCreateStateActionsAndActionCreators from "../../createStates/actions/createState";
import makeDeleteStateActionsAndActionCreators from "../../deleteStates/actions/deleteState";
import makeFetchAllStateActionsAndActionCreators from "../../fetchAllStates/actions/fetchAllState";
import makeFetchStateActionsAndActionCreators from "../../fetchStates/actions/fetchState";
import makeUpdateStateActionsAndActionCreators from "../../updateStates/actions/updateState";
import { callTypeExistsInList } from "../utils";

const makeAllCallTypeStateActionCreators = {
  makeFetchAllStateActionsAndActionCreators,
  makeFetchStateActionsAndActionCreators,
  makeCreateStateActionsAndActionCreators,
  makeUpdateStateActionsAndActionCreators,
  makeDeleteStateActionsAndActionCreators
};

type RequestFunction = (...args: any) => any | null | undefined;
type StoreAction = (arg0: any, arg1?: any) => Record<string, any>;

export type ErrorHandler = (
  error: Error,
  actions: {
    successAction: () => void;
    errorAction: (errorMessage?: Error | string) => void;
  }
) => void;

type CrudActionConfig = {
  requestFunction: RequestFunction;
  storeAction?: StoreAction;
  errorHandler?: ErrorHandler;
};

type SendToClient = any | null | undefined;
type Id = (string | string[]) | null | undefined;
type CrudAction = (
  arg0?: SendToClient | null | undefined,
  arg1?: Id | null | undefined
) => any; // TODO: this one really needs fixing

type CrudActionBuilder = (arg0: CrudActionConfig) => CrudAction;

type makeCrudStateActionFactoriesReturnType = Record<string, CrudActionBuilder>;

export default function makeCrudStateActionFactories({
  name,
  path,
  idFieldName,
  callTypes
}: CrudConfig): makeCrudStateActionFactoriesReturnType {
  if (callTypes && callTypes.length === 0) {
    throw new Error("Empty array is not a valid option for call types");
  }

  let returnObject = {};
  const sendAll = !callTypes;

  // Fetch all never takes an id - useful for the cases when create, update and delete do.
  // Allows the crud factory to be called once with an id
  if (sendAll || callTypeExistsInList(AllowedCallTypes.FETCH_ALL, callTypes)) {
    const callTypeOutputs = buildCallType(
      AllowedCallTypes.FETCH_ALL,
      name,
      path,
      undefined
    );
    returnObject = { ...returnObject, ...callTypeOutputs };
  }

  if (sendAll || callTypeExistsInList(AllowedCallTypes.FETCH, callTypes)) {
    const callTypeOutputs = buildCallType(
      AllowedCallTypes.FETCH,
      name,
      path,
      idFieldName
    );
    returnObject = { ...returnObject, ...callTypeOutputs };
  }

  if (sendAll || callTypeExistsInList(AllowedCallTypes.CREATE, callTypes)) {
    const callTypeOutputs = buildCallType(
      AllowedCallTypes.CREATE,
      name,
      path,
      idFieldName
    );
    returnObject = { ...returnObject, ...callTypeOutputs };
  }

  if (sendAll || callTypeExistsInList(AllowedCallTypes.UPDATE, callTypes)) {
    const callTypeOutputs = buildCallType(
      AllowedCallTypes.UPDATE,
      name,
      path,
      idFieldName
    );
    returnObject = { ...returnObject, ...callTypeOutputs };
  }

  if (sendAll || callTypeExistsInList(AllowedCallTypes.DELETE, callTypes)) {
    const callTypeOutputs = buildCallType(
      AllowedCallTypes.DELETE,
      name,
      path,
      idFieldName
    );
    returnObject = { ...returnObject, ...callTypeOutputs };
  }

  return returnObject;
}
// Builds action types, creators and action factory for a given call type

function buildCallType(
  callType: AllowedCallTypes,
  name: string,
  path: string = "seneca/",
  idFieldName?: string
) {
  const { callTypeCaps, callTypesLowerCase, callTypeCapitalised } =
    processCallType(callType);

  const makeCallTypeStateActionsAndActionCreators = (
    makeAllCallTypeStateActionCreators as any
  )[`make${callTypeCapitalised}StateActionsAndActionCreators`];

  const callTypeStateActionsAndActionCreators =
    makeCallTypeStateActionsAndActionCreators({
      name,
      path,
      idFieldName
    });

  const actionTypes =
    callTypeStateActionsAndActionCreators[`${callTypesLowerCase}ActionTypes`];
  const actionCreators =
    callTypeStateActionsAndActionCreators[
      `${callTypesLowerCase}ActionCreators`
    ];

  const actionFactory = makeCrudActionFactory(
    actionCreators,
    AllowedCallTypes[callTypeCaps],
    name,
    !!idFieldName
  );

  const resetActionCreator =
    actionCreators[
      generateActionName(callType, name, AllowedActionStatus.RESET)
    ];

  return {
    [`${callTypesLowerCase}ActionTypes`]: actionTypes,
    [`${callTypesLowerCase}ActionCreators`]: actionCreators,
    [`${callTypesLowerCase}ActionFactory`]: actionFactory,
    [`reset${callTypeCapitalised}Action`]: resetActionCreator
  };
}

function makeCrudActionFactory(
  actionCreators: any,
  callType: AllowedCallTypes,
  name: string,
  idFieldPresent: boolean
) {
  const startActionCreator =
    actionCreators[
      generateActionName(callType, name, AllowedActionStatus.START)
    ];
  const successActionCreator =
    actionCreators[
      generateActionName(callType, name, AllowedActionStatus.SUCCESS)
    ];
  const errorActionCreator =
    actionCreators[
      generateActionName(callType, name, AllowedActionStatus.ERROR)
    ];

  /**
   * If you provide an errorHandler you are responible for:
   * - Logging the result to the store (using successAction or errorAction)
   * - Any logging to sentry
   */
  return function makeCrudAction({
    requestFunction,
    storeAction,
    errorHandler
  }: CrudActionConfig): CrudAction {
    return function crudAction(
      requestFunctionArgs: any = [],
      ids?: (string | null | undefined) | (string[] | null | undefined)
    ) {
      const requestFunctionArgsArray = Array.isArray(requestFunctionArgs)
        ? requestFunctionArgs
        : [requestFunctionArgs];

      const makeRequest = () => {
        // This is a developer helper, i.e. if we pass the wrong client action name
        if (!requestFunction) {
          logError(
            new Error(
              `Client method supplied to ${name} ${callType} crud action factory is not a function`
            )
          );
        }

        // @ts-ignore
        return requestFunction(...requestFunctionArgsArray);
      };

      return async function (dispatch: Dispatch) {
        try {
          dispatch(startActionCreator(ids));

          const output = await makeRequest();

          // @ts-ignore
          storeAction && dispatch(storeAction(output, requestFunctionArgs));
          dispatch(successActionCreator(ids));

          return output;
        } catch (error: any) {
          // @ts-ignore
          function errorAction(errorMessage) {
            return dispatch(
              idFieldPresent
                ? errorActionCreator(ids, errorMessage)
                : errorActionCreator(errorMessage)
            );
          }

          if (errorHandler) {
            let resultLoggedToRedux = false;
            errorHandler(error, {
              successAction: () => {
                resultLoggedToRedux = true;
                return dispatch(successActionCreator(ids));
              },
              errorAction: (
                errorMessage = getMessageFromError(error) as unknown as Error
              ) => {
                resultLoggedToRedux = true;
                return errorAction(errorMessage);
              }
            });

            // This is to catch the case where we incorrectly implement an errorHandler
            // and do not log a fetch result to the store (either success or error).
            if (!resultLoggedToRedux) {
              logMessage(
                `No result was logged to redux for ${callType} - ${name}. Please check the appropriate errorHandler calls either successAction or errorAction.`
              );
              errorAction({
                errorMessage: getMessageFromError(error)
              });
            }

            return error;
          }

          // Throttled to avoid a spike of errors from individual users if
          // something goes wrong that affects every network request,
          // eg. auth errors, network errors
          throttledLogError(error);
          errorAction({
            errorMessage: getMessageFromError(error)
          });
          return error;
        }
      };
    };
  };
}

// Exported so custom errorHandlers can use same throttled function
export const throttledLogError = throttle(logError, 3000);
