import { Location } from "history";
import { matchPath } from "react-router-dom";

import { makeLinkStateGetterAndSetter } from "seneca-common/utils/routing";

import { linkToStartSession } from "features/classroom/routes/links";
import { LearningMode } from "features/classroom/state";
import { withLearningModeQueryParams } from "features/classroom/utils/withLearningModeQueryParams";

import { linkToClassroomCourse } from "../../links";
import { linkToSectionHomepage } from "./routes/section-homepage/links";
import { linkToStartSessionWithLearningMode } from "./routes/session/links";

export function linkToClassroomSection(
  courseId?: string,
  sectionId: string = ":sectionId"
) {
  return `${linkToClassroomCourse(courseId)}/section/${sectionId}`;
}

export function getSectionIdIfInSectionRoute(location: Location) {
  const match = matchPath(location.pathname, {
    path: linkToClassroomSection()
  }) as any;

  return match ? match.params.sectionId : undefined;
}

export const {
  set: addRedirectedFromInvalidSubscriptionForSectionState,
  get: getSectionIdFromInvalidSubscriptionForSectionState,
  has: redirectedFromInvalidSubscriptionForSection
} = makeLinkStateGetterAndSetter<string>("invalidSubscriptionForSection");

export const {
  set: addRedirectedFromMissingSectionState,
  has: redirectedFromMissingSection
} = makeLinkStateGetterAndSetter<string>("missingSection");

export const {
  set: addRedirectedFromInvalidStudySectionState,
  has: redirectedFromInvalidStudySection
} = makeLinkStateGetterAndSetter<string>("invalidStudySection");

export const linkToClassroomSectionWithLearningMode = (
  courseId: string,
  sectionId: string,
  mode: LearningMode | string
) =>
  withLearningModeQueryParams(
    linkToClassroomSection(courseId, sectionId),
    mode as LearningMode
  );

export const linkToDefaultLearningModeSection = (
  courseId: string,
  sectionId: string
) =>
  linkToClassroomSectionWithLearningMode(
    courseId,
    sectionId,
    LearningMode.default
  );

export const linkToAssignmentStartSession = (
  courseId: string,
  sectionId: string,
  state: {
    locationFrom: string;
    assignmentId: string;
  }
) => {
  const link = linkToStartSession(courseId, sectionId);

  return {
    pathname: link,
    search: getQueryStringToStartAssignmentSession({
      locationFrom: state.locationFrom,
      assignmentId: state.assignmentId,
      mode: LearningMode.assignments
    })
  } as Location;
};

const getQueryStringToStartAssignmentSession = ({
  locationFrom,
  assignmentId,
  mode
}: {
  assignmentId: string;
  locationFrom: string;
  mode: string;
}) => {
  const params = new URLSearchParams();
  params.append("locationFrom", locationFrom);
  params.append("assignmentId", assignmentId);
  params.append("mode", mode);
  return params.toString();
};

export const linkToSmartLearningSection = (
  courseId: string,
  sectionId: string
) =>
  linkToClassroomSectionWithLearningMode(
    courseId,
    sectionId,
    LearningMode.smartLearning
  );

export const linkToWrongAnswersSection = (
  courseId: string,
  sectionId: string
) =>
  linkToStartSessionWithLearningMode(
    courseId,
    sectionId,
    LearningMode.wrongAnswers
  );

const sectionHomePagePaths = [linkToSectionHomepage()];

export const atSectionHomePage = (pathname: string): boolean =>
  sectionHomePagePaths.some(path =>
    matchPath(pathname, {
      path,
      exact: true
    })
  );
